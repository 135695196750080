/**
 * styled-component global style
 */
const theme = {
  normal: '400',
  thin: '100',
  bold: '600',
  demiLite: '300',
  medium: '500',
  textGray: '#747474',
  yellow: '#ffc00f',
  red: '#f7322f',
  lightRed: '#f9eeee',
  textRed: '#f04d41',
  orange: '#f68c43',
  white: '#fff',
  black: '#0f0f0f',
  darkGray: '#2d2d2d',
  gray: '#8a8a8a',
  lightGray: '#f5f5f5',
  backgroundGray: '#f8f8f8',
  slide1: '#eee6fc',
  slide2: '#ffebdb',
  slide3: '#f1f1f1',
};

module.exports = theme;
