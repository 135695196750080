import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/mediaQueries';

const StyledButton = styled.button`
  cursor: pointer;
  font-size: 0.7rem;
  border-radius: ${({ square }) => (square ? '0' : '7px')};
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.red};
  a {
    display: block;
    height: 100%;
  }
  @media ${device.toTablet} {
    line-height: 2.5;
    padding: 0 20px;
    a {
      color: ${({ theme }) => theme.white};
    }
  }
  @media ${device.desktop} {
    height: 2.64375rem;
    font-size: 13px;
    border: solid 1px ${({ theme }) => theme.black};
    border-radius: ${({ square }) => (square ? '0' : '0.625rem')};
    color: ${({ theme }) => theme.black};
    background-color: transparent;
    a {
      line-height: 2.64375rem;
      padding: 0 48px;
    }
    :hover {
      &,
      * {
        color: ${({ theme }) => theme.white};
      }
      background-color: ${({ color, theme }) =>
        color ? theme[color] : theme.red};
      border: 0;
    }
  }
`;

const ButtonWithBackground = ({ text, color, square, path }) => {
  return (
    <StyledButton color={color} square={square}>
      <a href={path} target='_blank' rel='noopener noreferrer'>
        {text}
      </a>
    </StyledButton>
  );
};

export default ButtonWithBackground;
