import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/mediaQueries';
import ScrollAnimation from 'react-animate-on-scroll';

const Box = styled.div`
  position: absolute;
  top: ${({ bottom }) => (bottom ? bottom : 0)}%;
  ${({ reverse }) =>
    reverse
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
  width: ${({ expand, width }) => (expand ? width ?? 55 : 0)}%;
  height: 150px;
  background-color: ${({ theme }) => theme.red};
  transition: width 0.5s ease;
  z-index: -1;
  @media ${device.tablet} {
    width: ${({ expand, width }) => (expand ? width ?? 55 : 0)}%;
  }
  @media ${device.desktop} {
    top: ${({ bottom }) => (bottom ? bottom : 15)}%;
    height: 300px;
    width: ${({ expand }) => (expand ? 42 : 0)}%;
  }
`;

const BackgroundBox = ({ reverse, bottom, width }) => {
  const [expand, setExpand] = useState(false);
  return (
    <ScrollAnimation
      style={{ zIndex: 1 }}
      duration={0.5}
      animateIn='fadeIn'
      animateOnce
      afterAnimatedIn={(v) => {
        setExpand(v.onScreen);
      }}
    >
      <Box reverse={reverse} bottom={bottom} expand={expand} width={width} />
    </ScrollAnimation>
  );
};

export default BackgroundBox;
