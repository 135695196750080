import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../styles/mediaQueries';

const Number = styled.p`
  font-weight: ${({ theme }) => theme.bold};
  font-size: 1.25rem;
  line-height: 2;
  color: ${({ theme }) => theme.red};
  @media ${device.desktop} {
    font-size: 1.75rem;
    line-height: 1;
  }
`;

const MainText = styled.p`
  position: relative;
  font-weight: ${({ theme }) => theme.medium};
  font-size: 1.25rem;
  line-height: 1;
  padding-bottom: 19px;
  margin-bottom: 19px;
  @media ${device.toTablet} {
    &:before {
      content: '';
      position: absolute;
      ${({ right }) =>
        right
          ? css`
              right: 0;
            `
          : css`
              left: 0;
            `};
      bottom: 0;
      height: 1px;
      width: 80%;
      border-bottom: 1px solid #cbcbcb;
    }
  }
  @media ${device.desktop} {
    width: 300px;
    font-size: 1.75rem;
    font-size: 1.75rem;
    margin-bottom: 0;
  }
`;
const SubText = styled(MainText).attrs({ as: 'span' })`
  display: inline-block;
  font-size: 1rem;
  padding-bottom: 0;
  margin-bottom: 0;
  ::before {
    display: none;
  }
  @media ${device.desktop} {
    font-size: 1.125rem;
  }
`;

const Divider = styled.div`
  @media ${device.desktop} {
    height: 1px;
    width: 21vw;
    background-color: #cbcbcb;
    margin-bottom: 10px;
  }
`;

const TextHeading = styled.p`
  width: 300px;
  font-size: 1rem;
  margin-bottom: 5px;
  font-weight: ${({ theme }) => theme.medium};
  @media ${device.desktop} {
    width: unset;
    font-size: 1.125rem;
    line-height: 2.5;
  }
`;

const Text = styled.p`
  width: 300px;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.demiLite};
  line-height: 1.5;
  color: ${({ theme }) => theme.textGray};
  white-space: pre-wrap;
  @media ${device.desktop} {
    width: 23.3vw;
  }
`;

const CompanyContentText = ({
  number,
  mainText,
  subText,
  textHeading,
  text,
  right,
}) => {
  return (
    <div>
      <Number>{number}</Number>
      <MainText right={right}>
        {mainText}
        <SubText>{subText}</SubText>
      </MainText>
      <Divider />
      <TextHeading>{textHeading}</TextHeading>
      <Text>{text}</Text>
    </div>
  );
};

export default CompanyContentText;
