import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Divider, Row } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import Text from '../texts/Text';

import { device } from '../../styles/mediaQueries';

import mainCi from '../../assets/company/ci/main-ci.png';
import pandaCi from '../../assets/company/ci/ci-panda.png';
import ci from '../../assets/company/ci/ci.png';
import outlineCi from '../../assets/company/ci/ci-grid.png';
import downloadIcon from '../../assets/company/ci/download-black-icon.png';
import { WrapperWidth1240 } from '../../styles/commonStyle';

import multiLanguage from '../../assets/multiLanguage.json';
import { URL } from '../../data/constants';

const H1 = styled.h1`
  position: relative;
  font-size: 0.96875rem;
  line-height: 0.96875rem;
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ color }) => color};
  @media ${device.toTablet} {
    ::before {
      display: inline-block;
      content: '';
      height: 0.96875rem;
      width: 0.96875rem;
      border: solid 4px ${({ color }) => color};
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  @media ${device.desktop} {
    font-size: 1.5rem;
    line-height: 1.5rem;
    ::after {
      display: inline-block;
      content: '';
      height: 1.2rem;
      width: 1.2rem;
      border: solid 5px ${({ color }) => color};
      border-radius: 50%;
      margin-left: 15px;
    }
  }
`;

const CiTextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    letter-spacing: -0.68px;
    line-height: 1.75;
  }

  aside {
    padding: 20px 47px;
    color: #ffffff;
    background-color: ${({ color }) => color};
  }
  @media ${device.toTablet} {
    margin-bottom: 50px;

    p {
      margin: 20px 0;
      width: 73vw;
      font-size: 0.96875rem;
      color: ${({ theme }) => theme.textGray};
    }
  }
  @media ${device.desktop} {
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end;
    aside {
      margin-top: 24px;
    }
    &.reverse {
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;

      ${H1} {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        ::after {
          margin-right: 15px;
          margin-left: 0;
        }
      }
    }
  }
`;

const CiBox = styled.div`
  ${WrapperWidth1240}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.desktop} {
    flex-direction: row;
    height: 330px;
    padding: 50px 100px 0;
    border: solid 1px #dadada;
    margin-bottom: 100px;
  }
`;

const MainCi = styled.div`
  display: flex;
  flex-direction: column;
  img {
    align-self: center;
    width: 240px;
    height: auto;
  }
  padding: 0 0 50px;
  @media ${device.desktop} {
    flex: 2;
    align-self: flex-end;
    padding: 0 100px;
    img {
      width: 320px;
    }
    a:last-child {
      border-left: 0;
    }
    a {
      display: block;
      height: 49px;
      flex: 1;
      margin-top: 57.8px;
      font-size: 0.8125rem;
      text-align: center;
      line-height: 49px;
      background-color: transparent;
      border: solid 1px #dadada;
      border-bottom: 0;
      &::before {
        display: inline-block;
        width: 25px;
        height: 40px;
        vertical-align: middle;
        content: url(${downloadIcon});
        margin-right: 10px;
      }
    }
  }
`;

const CiWrapper = styled.div`
  margin-top: 31px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;

  div {
    width: 100%;
    height: 100%;
    border: solid 1px #e7e7e7;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }

  @media ${device.toTablet} {
    padding: 0 4.2vw;
    gap: 5px 3px;
    img {
      width: 23.7vw;
    }
    div:nth-child(3) {
      grid-row: 1 / 3;
      grid-column: 2 / 3;
    }
  }
  @media ${device.desktop} {
    width: 587px;
    margin-top: 31px;
    grid-template-columns: 1fr 1fr;
    gap: 15px 10px;
    justify-items: center;
    align-items: center;

    div {
      width: 100%;
      height: 100%;
      border: solid 1px #e7e7e7;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
    }
    div:last-child {
      grid-row: 1 / 3;
      grid-column: 2 / 3;
    }
  }
`;

const SubContent = styled.div`
  ${WrapperWidth1240}
  ul {
    display: flex;
    flex-direction: column;
    li {
      border-top: solid 2px #e7e7e7;
    }
  }
  @media ${device.toTablet} {
    * {
      font-size: 1rem;
      text-align: center;
    }
    ul {
      li:first-child {
        padding: 10px 0;
        span {
          max-width: 100%;
          display: flex;
          justify-content: center;
          h2 {
            font-size: 1.25rem;
            font-weight: 400;
          }
          span {
            margin-left: 3px;
            font-size: 1.25rem;
            font-weight: 600;
          }
        }
      }
      li:last-child {
        padding: 20px 4.2vw 50px;
        sub {
          margin: 0 auto 20px;
          display: block;
          width: 70vw;
        }
        p > span {
          display: block;
          margin: 0 auto;
          width: 66vw;
          white-space: pre-line;
        }
      }
    }
  }
  @media ${device.desktop} {
    ul {
      flex-direction: row;
      margin-bottom: 100px;
      li {
        flex: 3;
      }
      li:first-child {
        flex: 1;
        justify-items: center;
        margin-right: 5.2vw;
        padding-left: 3.1vw;
      }
    }
  }
`;
const HeaderTextWrapper = styled.div`
  @media ${device.toTablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    p:first-child {
      font-size: 1.25rem;
    }
    p:last-child {
      width: 71vw;
    }
  }
`;

const BoxColorDivider = styled(Divider)`
  display: none;
  @media ${device.desktop} {
    width: 30px;
    min-width: 30px;
    background-color: '#868686';
  }
`;
const Ci = ({ isDesktop }) => {
  const { currentLanguage } = useSelector((state) => state.global);

  return (
    <div>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: multiLanguage['CI 소개 TITLE'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
        <HeaderTextWrapper>
          <Text strong center fontSize={'1.375rem'}>
            {
              multiLanguage['중국의 한국 직구 시장을 개척하는 리더'][
                currentLanguage
              ]
            }
          </Text>
          <Text
            center
            style={{ marginTop: '1rem', marginBottom: '4.36875rem' }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html:
                  multiLanguage['아무도 가지 않은 길을 가는'][currentLanguage],
              }}
            />
          </Text>
        </HeaderTextWrapper>
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
        <Row justify='center'>
          <CiBox>
            {!isDesktop && (
              <MainCi>
                <img src={mainCi} alt='ci' />
              </MainCi>
            )}
            <ScrollAnimation
              animateIn='fadeInLeft'
              duration={1}
              delay={500}
              animateOnce={true}
            >
              <CiTextWrapper color='#d71920'>
                <H1 color='#d71920'>RED</H1>
                <BoxColorDivider />
                <p>
                  {
                    multiLanguage[
                      '역동적인 강렬함, 중국, 혁명, 선두, 기쁨 등의 에너지 넘치는 이미지를 지니고 있습니다.'
                    ][currentLanguage]
                  }
                </p>
                <aside>#d71920</aside>
              </CiTextWrapper>
            </ScrollAnimation>
            {isDesktop && (
              <MainCi>
                <img src={mainCi} alt='ci' />
                <div className='pd-flex-row'>
                  <a href={URL.CI_AI} download>
                    Download AI
                  </a>
                  <a href={mainCi} download>
                    Download Jpg
                  </a>
                </div>
              </MainCi>
            )}
            <ScrollAnimation
              animateIn='fadeInRight'
              duration={1}
              delay={500}
              animateOnce={true}
            >
              <CiTextWrapper className='reverse' color='#1a1617'>
                <H1 color='#1a1617'>BLACK</H1>
                <BoxColorDivider />
                <p>
                  {
                    multiLanguage[
                      '힘과 고급스러움, 단결력, 격조, 품위 등의 긍정적인 이미지를 지니고 있습니다.'
                    ][currentLanguage]
                  }
                </p>
                <aside>#1a1617</aside>
              </CiTextWrapper>
            </ScrollAnimation>
          </CiBox>
        </Row>
      </ScrollAnimation>
      <Row justify='center'>
        <SubContent>
          <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
            <ul>
              <li>
                <SubHeading fontSize={'1.5rem'}>
                  <h2>{multiLanguage['판다코리아닷컴'][currentLanguage]}</h2>
                  <SubHeading fontSize={'1.5rem'} strong>
                    {multiLanguage['슬로건'][currentLanguage]}
                  </SubHeading>
                </SubHeading>
              </li>
              <li>
                <SubHeading strong fontSize={'1.4375rem'}>
                  WE ARE MAKING HISTORY!
                </SubHeading>
                <sub>
                  <Text
                    strong
                    fontSize={'1.0625rem'}
                    style={{ marginTop: '1rem', marginBottom: '10px' }}
                  >
                    {
                      multiLanguage[
                        '중국의 한국 직구 시장을 개척하는 리더. 새 역사를 만들어나갈 기업. 선두/도전/역동/중화권시장/친근함'
                      ][currentLanguage]
                    }
                  </Text>
                </sub>
                <Text fontSize={'0.9375rem'} color='textGray'>
                  {
                    multiLanguage['중화인민공화국의 국기는 오성홍기'][
                      currentLanguage
                    ]
                  }
                </Text>
              </li>
            </ul>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
            <ul>
              <li>
                <SubHeading fontSize={'1.5rem'}>
                  <h2>CI</h2>
                  <SubHeading fontSize={'1.5rem'} strong>
                    {multiLanguage['그리드 시스템'][currentLanguage]}
                  </SubHeading>
                </SubHeading>
              </li>
              <li>
                <Text fontSize={'1.0625rem'} color='textGray'>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        multiLanguage['가로와 세로 비율로 축소 확대'][
                          currentLanguage
                        ],
                    }}
                  />
                </Text>
                <CiWrapper>
                  <div>
                    <img src={outlineCi} alt='outine ci' />
                  </div>
                  <div>
                    <img src={ci} alt='ci' />
                  </div>
                  <div>
                    <img src={pandaCi} alt='panda ci' />
                  </div>
                  {!isDesktop && (
                    <>
                      <p>{multiLanguage['기본형'][currentLanguage]}</p>
                      <p>{multiLanguage['확장형/심볼추가'][currentLanguage]}</p>
                    </>
                  )}
                </CiWrapper>
              </li>
            </ul>
          </ScrollAnimation>
        </SubContent>
      </Row>
    </div>
  );
};

export default Ci;
