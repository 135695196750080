import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';
import MainTitle from '../texts/MainTitle';
import Text from '../texts/Text';
import { WrapperWidth1240 } from '../../styles/commonStyle';
import { device } from '../../styles/mediaQueries';
import worldMap from '../../assets/main/world-map.png';
import placeIcon from '../../assets/main/place-icon.png';

import multiLanguage from '../../assets/multiLanguage.json';
import { mainGlobalPandaAddress } from '../../data/constants';

const Wrapper = styled.div`
  position: relative;
  @media ${device.toTablet} {
    width: 100vw;
  }
  @media ${device.desktop} {
    height: 640px;
  }
`;
const LeftContent = styled.div`
  @media ${device.toTablet} {
    display: none;
  }
  @media ${device.desktop} {
    width: 53%;
    position: absolute;
    left: 0;
    top: 0;
    height: 600px;
    z-index: 10;
    background-color: ${({ theme }) => theme.red};
  }
`;
const RightContent = styled.div`
  background-color: ${({ theme }) => theme.backgroundGray};
  background-image: url(${worldMap});
  background-size: contain;
  background-repeat: no-repeat;
  @media ${device.toTablet} {
    height: 62vw;
    background-position: bottom;
  }
  @media ${device.desktop} {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 62vw;
    height: 604px;
    z-index: 1;
    background-position: 16vw;
    background-size: 45vw;
    display: flex;
    justify-content: center;
  }
`;
const ContentWrapper = styled.div`
  @media ${device.toTablet} {
    background-color: ${({ theme }) => theme.red};
  }
  @media ${device.desktop} {
    position: absolute;
    margin: 0 auto;
    top: 10%;
    left: 0;
    width: 100%;
    z-index: 100;
  }
`;

const BranchWrapper = styled.div`
  ${WrapperWidth1240}
  @media ${device.toTablet} {
    padding: 10.6vw 4.2vw;
    h1 {
      justify-content: flex-start;
      margin-bottom: 10px;
    }
    li:last-of-type {
      border-bottom: 0;
    }
  }

  @media ${device.desktop} {
    ul {
      width: 520px;
    }
  }
`;
const Branch = styled.li`
  display: flex;
  align-items: center;
  border-bottom: solid 1px #ef7276;
  padding: 20px 0;

  div {
    display: flex;
    align-items: center;
    span {
      display: inline-block;
    }
  }
  .address {
    width: 225px;
    overflow: auto;
    p {
      word-break: break-all;
    }
  }
  @media ${device.mobile} {
    img {
      height: 0.9rem;
    }
  }
  @media ${device.desktop} {
    .branch-name {
      p {
        width: max-content;
      }
    }
    .address {
      width: 430px;
    }
  }
`;

const BranchDivider = styled(Divider)`
  background-color: #ef7276;
  height: 2.9rem;
  margin: 0 12px;
`;

const GlobalPanda = ({ isDesktop, currentLanguage }) => {
  const fontTitle = isDesktop ? '1.125rem' : '0.9rem';
  return (
    <Wrapper>
      <ContentWrapper>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <BranchWrapper>
            <MainTitle text={'Global Panda'} noIcon color='white' />
            <Text color='white' fontSize={isDesktop ? '1.125rem' : '0.9rem'}>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    multiLanguage[
                      isDesktop
                        ? '판다코리아는 중국 시장에서'
                        : '판다코리아는 중국 시장에서 Mobile'
                    ][currentLanguage],
                }}
              />
            </Text>
            <ul>
              {mainGlobalPandaAddress.map((address) => (
                <Fragment key={address}>
                  <Branch>
                    <div className='branch-name'>
                      <img src={placeIcon} alt='place icon' />
                      <Text
                        color='white'
                        strong
                        style={{ marginLeft: isDesktop ? 10 : 5 }}
                        fontSize={fontTitle}
                      >
                        {multiLanguage[address][currentLanguage]}
                      </Text>
                    </div>
                    <BranchDivider type='vertical' />
                    <div className='address'>
                      <Text
                        color='white'
                        fontSize={isDesktop ? '1rem' : '0.8rem'}
                      >
                        {multiLanguage[`${address} 주소`][currentLanguage]}
                      </Text>
                    </div>
                  </Branch>
                </Fragment>
              ))}
            </ul>
          </BranchWrapper>
        </div>
      </ContentWrapper>
      <RightContent />
      <LeftContent />
    </Wrapper>
  );
};

export default GlobalPanda;
