import { useLayoutEffect, useCallback, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import useWindowSize from './useWindowSize';
/**
 * 스크롤 위치 이동 포인트
 * 1. 주소에 #tab(서브페이지에서 탭이동할때 위치조정)
 * 2. 헤더에 메뉴클릭시 스트롤 탑으로 이동
 */
export default function useWindowPosition() {
  const { pathname, hash } = useLocation();
  const { isDesktop } = useWindowSize();

  const moveYScroll = useCallback(() => {
    if (hash) {
      return scroll.scrollTo(isDesktop ? 490 : 200);
    } else if (
      pathname.includes('/company/news') &&
      pathname !== '/company/news'
    ) {
      return scroll.scrollTo(isDesktop ? 490 : 200, { isDynamic: true });
    }

    return scroll.scrollTo(0);
  }, [pathname, hash, isDesktop]);

  return { moveYScroll };
}
