import icon2021 from '../assets/company/history/2021.png';
import image2021 from '../assets/company/history/2021-image.png';
import icon2020 from '../assets/company/history/2020.png';
import image2020 from '../assets/company/history/2020-image.png';
import icon2019 from '../assets/company/history/2019.png';
import image2019 from '../assets/company/history/2019-image.png';
import icon2018 from '../assets/company/history/2018.png';
import image2018 from '../assets/company/history/2018-image.png';
import icon2017 from '../assets/company/history/2017.png';
import image2017 from '../assets/company/history/2017-image.png';
import icon2016 from '../assets/company/history/2016.png';
import image2016 from '../assets/company/history/2016-image.png';
import icon2015 from '../assets/company/history/2015.png';
import image2015 from '../assets/company/history/2015-image.png';
import icon2014 from '../assets/company/history/2014.png';
import image2014 from '../assets/company/history/2014-image.png';

export const timeline = [
  {
    year: '2021',
    title: '판다코리아닷컴의 성장과 확장',
    subTitle: 'WEMAKEPRICE TMALL OPEN',
    image: image2021,
    icon: icon2021,
  },
  {
    year: '2020',
    title: '판다코리아닷컴의 새로운 도약',
    subTitle: 'TMALL GLOBAL OPEN',
    image: image2020,
    icon: icon2020,
  },
  {
    year: '2019',
    title: '마케팅부터 판매 통관 배송까지',
    subTitle: 'ONE STOP 토털서비스',
    image: image2019,
    icon: icon2019,
  },
  {
    year: '2018',
    title: '판다코리아닷컴 연속 흑자 기록 달성',
    subTitle: '지속 가능한 건강한 기업으로 우뚝',
    image: image2018,
    icon: icon2018,
  },
  {
    year: '2017',
    title: '20개국 온라인 수출의 길을 뚫다',
    subTitle: '‘사드한파’ 극복',
    image: image2017,
    icon: icon2017,
  },
  {
    year: '2016',
    title: '‘진짜 한국’을 만나는 지름길',
    subTitle: '판다코리아닷컴 문화를 만들다',
    image: image2016,
    icon: icon2016,
  },
  {
    year: '2015',
    title: '판다코리아닷컴의 혁신적인 프로세스',
    subTitle: '해외 직판 토털 서비스 그룹으로 거듭나다',
    image: image2015,
    icon: icon2015,
  },
  {
    year: '2014',
    title: '판다코리아닷컴의 시작',
    subTitle: '‘역직구’라는 최초의 기록을 쓰다',
    image: image2014,
    icon: icon2014,
  },
];
