import React from 'react';
import styled from 'styled-components';
import alibaba from '../../../../assets/main/logos/alibaba.png';
import taobao from '../../../../assets/main/logos/taobao.png';
import tmall from '../../../../assets/main/logos/tmall.png';
import lazada from '../../../../assets/main/logos/lazada.png';
import shopee from '../../../../assets/main/logos/shopee.png';
import { device } from '../../../../styles/mediaQueries';
import multiLanguage from '../../../../assets/multiLanguage.json';
import {
  FontWeightDemiLite,
  FontWeightNormal,
  ColorTextGray,
  FontWeightThin,
  ColorRed,
} from '../../../../styles/commonStyle';

const Border = 'solid 3px #d7d7d7';

const ProductItemStyle = styled.section`
  p {
    ${FontWeightDemiLite}
    ${ColorTextGray}
  }
  @media ${device.toTablet} {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    border-top: solid 1px #d8d8d8;
    text-align: center;
    h6 {
      margin-bottom: 24px;
      ${FontWeightDemiLite}
    }
    p {
      font-size: 0.75rem;
      ${FontWeightThin}
      line-height: 2;
      word-break: ${({ currentLanguage }) =>
        currentLanguage === 'CN' ? 'break-all' : 'keep-all'};
    }
    ul {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        p {
          font-size: 0.78125rem;
          ${ColorRed}
          ${FontWeightDemiLite}
          word-break: keep-all;
        }
        padding: 0 15px;
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(
            transparent 30%,
            ${({ theme }) => theme.lightGray} 30% 70%,
            transparent 70%
          )
          0 0 0 1 / 1px;
      }
      li:first-child {
        border: 0;
        padding-left: 0;
      }

      img {
        display: block;
        object-fit: scale-down;
        width: 55px;
      }
    }
  }
  @media ${device.desktop} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:first-child {
      margin-bottom: 30px;
    }
    &:nth-child(3),
    &:nth-child(4) {
      border-top: ${Border};
    }
    &:nth-child(even) {
      border-left: ${Border};
      justify-content: flex-start;
      article {
        padding-left: 100px;
      }
    }
    &:nth-child(odd) {
      text-align: right;
      article {
        padding-right: 100px;
      }
    }
    article {
      height: 185px;
    }
    p {
      width: 270px;
    }
    h6 {
      font-size: 20px;
      ${FontWeightNormal}
      margin-bottom: 20px;
    }
  }
`;

const mobileItem = (index, currentLanguage) => {
  const list = [
    <ul>
      <li>
        <p>
          {multiLanguage['스마트'][currentLanguage]}
          {multiLanguage['스토어'][currentLanguage]}
        </p>
      </li>
      <li>
        <p>{multiLanguage['오픈마켓'][currentLanguage]}</p>
      </li>
      <li>
        <p>{multiLanguage['복지몰'][currentLanguage]}</p>
      </li>
    </ul>,
    <ul>
      <li>
        <img src={taobao} alt='taobao' />
      </li>
      <li>
        <img src={tmall} alt='tmall' />
      </li>
    </ul>,
    <ul>
      <li>
        <img src={shopee} alt='shopee' />
      </li>
      <li>
        <img src={lazada} alt='lazada' />
      </li>
    </ul>,
    <ul>
      <li>
        <img src={alibaba} alt='alibaba' />
      </li>
    </ul>,
  ];
  return list[index];
};
const ProductItem = ({ title, text, index, isDesktop, currentLanguage }) => {
  return (
    <ProductItemStyle currentLanguage={currentLanguage}>
      <article>
        <h6>{title}</h6>
        <p>{text}</p>
      </article>
      {!isDesktop && mobileItem(index, currentLanguage)}
    </ProductItemStyle>
  );
};

export default ProductItem;
