import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/mediaQueries';

const H1 = styled.h1`
  font-weight: ${({ theme }) => theme.bold};
  font-size: 1rem;
  line-height: 1;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  color: ${({ theme, color }) => theme[color]};

  @media ${device.desktop} {
    font-size: 2.5rem;
  }
`;

const HeadingText = ({ text, color, center, style }) => {
  return (
    <H1 color={color} center={center} style={style}>
      {text}
    </H1>
  );
};

export default HeadingText;
