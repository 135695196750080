import timall from '../assets/main/tmall-guide-background.png';
import taobao from '../assets/main/taobao-guide-background.png';
import alibaba from '../assets/main/alibaba-guide-background.png';

const contents = [
  {
    number: '01',
    text: 'Tmall Global',
    subText: 'Guide',
    contentTitle: '티몰 개설 시',
    content: '까다로운 티몰 개설 과정을 한 눈에 볼 수 있는 티몰 개설 가이드',
    background: timall,
    path: '/information/tmall',
  },
  {
    number: '02',
    text: 'Taobao',
    subText: 'Guide',
    contentTitle: '타오바오 개설 시',
    content:
      '중국 최대 오픈마켓 타오바오 개설 과정을 한 눈에 볼 수 있는 타오바오 개설 가이드',
    background: taobao,
    path: '/information/taobao',
  },
  {
    number: '03',
    text: 'Global Alibaba',
    subText: 'Guide',
    contentTitle: '알리바바 입점 시',
    content: 'B2B 알리바바 입점 과정을 한 눈에 볼 수 있는 알리바바 개설 가이드',
    background: alibaba,
    path: '/information/alibaba',
  },
];

export default contents;
