import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { menus } from '../../data/menus';
import Text from '../../components/texts/Text';
import { device } from '../../styles/mediaQueries';
import multiLanguage from '../../assets/multiLanguage.json';

import companyBackground from '../../assets/sub/company-background.png';
import recruitBackground from '../../assets/sub/recruit-background.png';
import contactBackground from '../../assets/sub/contact-background.png';
import informationBackground from '../../assets/sub/information-background.png';
import businessBackground from '../../assets/sub/business-background.png';

const Wrapper = styled.div`
  height: 310px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media ${device.desktop} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 544px;
  }

  ${({ label }) => {
    switch (label) {
      case 'company':
        return `background-image: url(${companyBackground})`;
      case 'recruit':
        return `background-image : url(${recruitBackground})`;
      case 'contact':
        return `background-image : url(${contactBackground})`;
      case 'information':
        return `background-image : url(${informationBackground})`;
      case 'business':
        return `background-image : url(${businessBackground})`;
      default:
        return `background-image : url(${businessBackground})`;
    }
  }}
`;

const Title = styled.p`
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: ${({ theme }) => theme.thin};
  letter-spacing: 0.5px;
`;

const ContentRow = styled(Row)`
  height: 260px;
  @media ${device.desktop} {
    height: calc(100% - 49px);
  }
`;

const Tab = styled.div`
  position: relative;
  float: left;
  width: 125.87px;
  height: 50px;
  margin-left: 4px;
  line-height: 49px;
  text-align: center;
  z-index: 3;
  background-color: ${({ active, theme }) =>
    active ? theme.white : 'rgba(254, 73, 78, 0.84)'};
  a {
    display: block;
    height: 50px;
    color: ${({ active, theme }) => (active ? theme.black : theme.white)};
  }
  @media ${device.desktop} {
    width: 236px;
  }
`;
const MobileMenuTitle = styled.h1`
  line-height: 2;
  font-size: 1.46875rem;
  text-align: center;
  color: #ffffff;
`;
const MobiieWrapper = styled.section`
  position: relative;
  width: 100%;
  background-color: #ffffff;
`;
const MobileTab = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-bottom: solid 1px #d3d3d3;
`;

const MobileIconCaretDown = styled(CaretDownOutlined)`
  display: block;
  position: absolute;
  top: 20px;
  right: 4.2vw;
`;

const MobileSubMeueList = styled.div`
  position: absolute;
  top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 20px;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
  .active a {
    color: ${({ theme }) => theme.red};
  }
  a {
    line-height: 3;
  }
  div {
    padding: 5px 0;
  }
  div:nth-child(odd) {
    padding-left: 4.2vw;
  }
`;
const SubHeaderTab = ({
  pathname,
  currentLanguage,
  isDesktop,
  showBlackBackground,
  onShowBlackBackground,
}) => {
  const [subMenu, setSubMenu] = useState('');
  const [currentMenu, setCurrentMenu] = useState('');
  const [activeSubmenu, setActiveSubmenu] = useState({ name: '' });
  const history = useHistory();
  useEffect(() => {
    if (!pathname) return;

    const pathnameSplit = pathname.split('/');
    if (pathnameSplit.length > 1) {
      const subMenu = menus.find((item) => item.label === pathnameSplit[1]);
      if (subMenu) {
        setCurrentMenu(pathnameSplit[2]);
        setSubMenu(subMenu);
        setActiveSubmenu(
          subMenu.sub.find((item) => item.label === pathnameSplit[2])
        );
        return;
      }
    }

    return history.push('/');
  }, [pathname]);
  return (
    <Wrapper label={subMenu.label}>
      {isDesktop ? (
        <>
          <ContentRow justify='center' align='middle'>
            <Col>
              <Title>RIGHT WAY, WIN-WIN, BUILDING TRUST</Title>
              <Text
                color='white'
                center
                style={{ fontSize: 20, letterSpacing: -0.4 }}
              >
                {
                  multiLanguage[
                    '판다코리아닷컴의 성공은 대한민국 중소기업의 성공이자 중화권 소비자들의 기쁨입니다.'
                  ][currentLanguage]
                }
              </Text>
            </Col>
          </ContentRow>
          <Row justify='center' align='bottom'>
            {subMenu &&
              subMenu.sub.map((item) => (
                <Tab key={item.name} active={currentMenu === item.label}>
                  <Link
                    to={`${subMenu.path}${item.path}${
                      item.sub ? item.sub[0].path : ''
                    }#tab`}
                  >
                    {multiLanguage[item.name][currentLanguage]}
                  </Link>
                </Tab>
              ))}
          </Row>
        </>
      ) : (
        <>
          <ContentRow justify='center' align='middle'>
            <div>
              <MobileMenuTitle>{subMenu.name}</MobileMenuTitle>
              {activeSubmenu.name && (
                <MobileMenuTitle>
                  {multiLanguage[activeSubmenu.name][currentLanguage]}
                </MobileMenuTitle>
              )}
            </div>
          </ContentRow>
          <MobiieWrapper>
            {activeSubmenu.name && (
              <MobileTab
                onClick={() => onShowBlackBackground(!showBlackBackground)}
              >
                {multiLanguage[activeSubmenu.name][currentLanguage]}
                <MobileIconCaretDown />
              </MobileTab>
            )}
            {showBlackBackground && (
              <MobileSubMeueList>
                {subMenu &&
                  subMenu.sub.map((item) => (
                    <div
                      key={item.name}
                      className={currentMenu === item.label ? 'active' : ''}
                      onClick={() =>
                        onShowBlackBackground(!showBlackBackground)
                      }
                    >
                      <Link
                        to={`${subMenu.path}${item.path}${
                          item.sub ? item.sub[0].path : ''
                        }`}
                      >
                        {multiLanguage[item.name][currentLanguage]}
                      </Link>
                    </div>
                  ))}
              </MobileSubMeueList>
            )}
          </MobiieWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default SubHeaderTab;
