import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row } from 'antd';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import BoardDetail from '../common/BoardDetail';

import multiLanguage from '../../assets/multiLanguage.json';
import { useSelector } from 'react-redux';

const ApplyDetail = ({ currentLanguage }) => {
  const [content, setContent] = useState({
    current: null,
    next: null,
    prev: null,
  });
  const history = useHistory();
  const { contentId } = useParams();
  const { recruitList } = useSelector((state) => state.global);

  const setData = useCallback(() => {
    const itemIndex = recruitList.findIndex(
      (item) => item.SK === Number(contentId)
    );
    if (recruitList[itemIndex]) {
      return setContent({
        current: recruitList[itemIndex],
        next: recruitList[Number(itemIndex) + 1]
          ? recruitList[Number(itemIndex) + 1]
          : null,
        prev: recruitList[Number(itemIndex) - 1]
          ? recruitList[Number(itemIndex) - 1]
          : null,
      });
    }
    return history.push('/');
  }, [contentId, recruitList]);

  useEffect(() => {
    if (!recruitList.length) return;
    setData();
  }, [setData, contentId, recruitList]);

  return (
    <div className='pd-flex-center'>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  multiLanguage['판다코리아닷컴 입사지원'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      {content.current && (
        <BoardDetail
          currentLanguage={currentLanguage}
          data={content}
          path={'/recruit/apply'}
        />
      )}
    </div>
  );
};

export default ApplyDetail;
