import React from 'react';
import styled from 'styled-components';

import Button from '../../buttons/Button';

import { AppleFilled } from '@ant-design/icons';
import google from '../../../assets/business/battery/logo-google.png';
import multiLanguage from '../../../assets/multiLanguage.json';
import { device } from '../../../styles/mediaQueries';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 14px;
  @media ${device.toTablet} {
    margin-top: ${({ MobileMarginTop }) => MobileMarginTop ?? 45}px;
    flex-direction: column;
    row-gap: 20px;
  }
`;

const IconStyle = (isDesktop) =>
  isDesktop
    ? { fontSize: 25, width: 25, marginRight: 5 }
    : { fontSize: 15, width: 15, marginRight: 5 };
const GooglePlayButton = ({ isDesktop }) => (
  <>
    <img src={google} style={IconStyle(isDesktop)} alt='google play' />
    Google Play
  </>
);

const AppButtonGroup = ({
  isDesktop,
  currentLanguage,
  googleUrl,
  appleUrl,
  homeUrl,
  homeRedButton,
  homeText,
  MobileMarginTop,
}) => {
  return (
    <ButtonContainer MobileMarginTop={MobileMarginTop}>
      <Button
        small
        buttonText={<GooglePlayButton isDesktop={isDesktop} />}
        path={googleUrl}
      />
      <Button
        small
        buttonText={
          <>
            <AppleFilled style={IconStyle(isDesktop)} />
            App Store
          </>
        }
        path={appleUrl}
      />
      <Button
        small
        redBorder={!homeRedButton}
        redButton={homeRedButton}
        buttonText={multiLanguage[homeText][currentLanguage]}
        path={homeUrl}
      />
    </ButtonContainer>
  );
};

export default AppButtonGroup;
