export const URL = {
  CEO: 'https://www.huodongjia.com/guest-077084065120078122081053.html',
  PIGGY_GOOGLE:
    'https://play.google.com/store/apps/details?id=com.panda.charge',
  PIGGY_APPLE: 'https://apps.apple.com/kr/app/apple-store/id1493471671',
  PIGGY: 'https://piggycell.com/',
  BIKE_GOOGLE: 'https://play.google.com/store/apps/details?id=com.kakao.taxi',
  BIKE_APPLE:
    'https://apps.apple.com/kr/app/%EC%B9%B4%EC%B9%B4%EC%98%A4-t/id981110422',
  BIKE: 'https://www.kakaocorp.com/page/service/service/KakaoT',
  ALIBABA:
    'https://pandakorea.trustpass.alibaba.com/?spm=a2700.8304367.0.0.LGNWpq',
  PANDAHAO: 'https://smartstore.naver.com/panda_korea',
  TAOBAO_KOREA_FOOD:
    'https://shop362812590.world.taobao.com/shop/view_shop.htm?spm=a1z10.3-c-s.w5001-18952719859.2.42d759b6PzhCWG&mytmenu=mdianpu&user_number_id=3684962563&scene=taobao_shop',
  TAOBAO_KOREA_2ND_FOOD:
    'https://shop463914117.world.taobao.com/index.htm?spm=a312a.7700824.w5002-18370785983.2.13ee2188Xrng1i',
  LAZADA: 'https://www.lazada.sg/shop/pandapick/',
  TMALL_BEAUTY: 'https://pandahzp.tmall.hk/shop/view_shop.htm',
  WEIBO: 'https://www.weibo.com/pandakorea?is_hot=1',
  BUSINESS_PLAN: `https://${process.env.REACT_APP_API_ENDPOINT}.company-pandakorea.com/cdn/files/판다코리아닷컴_사업소개서.pdf`,
  CI_AI: `https://${process.env.REACT_APP_API_ENDPOINT}.company-pandakorea.com/cdn/files/판다코리아닷컴_CI.ai`,
  API: `https://${process.env.REACT_APP_API_ENDPOINT}.company-pandakorea.com/`,
};

export const CATEGORY = {
  news: {
    KR: '판다뉴스',
    EN: 'NEWS',
    CN: '攀达新闻',
  },
  notice: {
    KR: '공지사항',
    EN: 'NOTICE',
    CN: '公告事项',
  },
  publishing: {
    KR: '보도자료',
    EN: 'PRESS',
    CN: '报道资料',
  },
  hiring: {
    KR: '채용중',
    EN: 'RECRUITING',
    CN: '招聘中',
  },
  close: {
    KR: '마감',
    EN: 'FINISHED',
    CN: '结束',
  },
};

export const PROVIDE_LIST = [
  { step1: '소비자 분석' },
  { step2: '전략 수립' },
  { step3: '입점 개설' },
  { step4: '웹 디자인' },
  { step5: '상품업로드' },
  { step6: '고객 CS' },
  { step7: '클레임 처리' },
  { step8: '이벤트 기획' },
  { step9: '왕홍 마케팅' },
  { step10: '상세페이지' },
  { step11: '고객관리' },
  { step12: '상품기획' },
  { step13: '티몰 마케팅' },
  { step14: '상표권 대행' },
  { step15: '데이터 분석' },
  { step16: '가품신고' },
  { step17: '시장조사' },
  { step18: '상세 번역' },
  { step19: '마케팅기획' },
  { step20: '물류관리' },
];

export const mainGlobalPandaAddress = [
  '상해지사',
  '연변지사',
  '서울본사',
  '인천물류',
];
