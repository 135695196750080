import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetData } from '../action/api';

const LIMIT = 6; // 로딩시 보여지는 뉴스 아이템 갯수
const useNewsData = (currentLanguage, currentTab) => {
  const [showLoadMore, setShowLoadMore] = useState(LIMIT);
  const [list, setList] = useState([]);
  const newsList = useSelector((state) => state.global.newsList);
  const dispatch = useDispatch();
  const handleShowMore = () => {
    setShowLoadMore((prevNumber) => prevNumber + LIMIT);
  };

  React.useEffect(() => {
    const getData = async (currentLanguage) => {
      try {
        const news = await handleGetData('getPostList', {
          params: { PK: 'board', language: currentLanguage },
        });
        if (news.Items)
          dispatch({
            type: 'SET_NEWSLIST',
            payload: news.Items,
          });
      } catch (error) {
        console.error(error);
      }
    };
    getData(currentLanguage);
  }, [newsList.length, currentLanguage, dispatch]);
  //tab label: news, notice, publishing, all
  React.useEffect(() => {
    if (currentTab.label !== 'all') {
      const filteredList = newsList.filter(
        (item) => item.type === currentTab.label
      );
      setList(filteredList);
    } else {
      setList(newsList);
    }
    setShowLoadMore(LIMIT);
  }, [currentTab, newsList]);

  const showButton = list.length > showLoadMore;

  return { list, showLoadMore, handleShowMore, showButton };
};

export default useNewsData;
