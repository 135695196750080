import React from 'react';
import styled from 'styled-components';
import { Row } from 'antd';

import ContentBox from './ContentBox';
import BackgroundBox from './BackgroundBox';
import Button from '../../buttons/Button';

import {
  ColorTextGray,
  FontWeightNormal,
  WrapperWidth,
} from '../../../styles/commonStyle';
import { device } from '../../../styles/mediaQueries';

const Container = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 340px;
  @media ${device.toTablet} {
    margin-bottom: 66px;
  }
`;

const SubContentWrapper = styled.div`
  ${WrapperWidth}
  display: flex;
  justify-content: space-between;
  @media ${device.toTablet} {
    padding: 0 10vw;
  }
`;

const SubContentContainer = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    position: relative;
    font-size: 1.5625rem;
    margin-bottom: 34px;
    line-height: 1.5;
    span,
    abbr {
      ${FontWeightNormal}
    }
  }
  p {
    margin-bottom: 54px;
    ${ColorTextGray}
    font-size: 1.125rem;
    line-height: 2;
    max-width: 570px;
    word-break: ${({ currentLanguage }) =>
      currentLanguage === 'CN' ? 'break-all' : 'keep-all'};
  }
  @media ${device.toTablet} {
    margin-top: 58px;
    text-align: center;
    h1 {
      font-size: 0.9375rem;
      margin-bottom: 12px;
    }
    p {
      font-size: 0.75rem;
      margin-bottom: 30px;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${device.toTablet} {
    justify-content: space-evenly;
  }
`;

const SubContent = ({
  title,
  content,
  path,
  buttonText,
  redButton,
  path1,
  buttonText1,
  currentLanguage,
}) => (
  <SubContentContainer currentLanguage={currentLanguage}>
    <h1
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    />
    <p
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
    <ButtonWrapper>
      <Button redButton={redButton} path={path} buttonText={buttonText} />
      {!!buttonText1 && <Button path={path1} buttonText={buttonText1} />}
    </ButtonWrapper>
  </SubContentContainer>
);

const ChannelContent = ({
  isDesktop,
  multiLanguage,
  currentLanguage,
  mainTitle,
  text,
  image,
  reverse,
  subContent,
}) => {
  return (
    <Container>
      <Row style={{ position: 'relative', width: '100%' }} justify='center'>
        <ContentBox
          reverse={reverse}
          titleBold
          margin={{
            marginBottom: isDesktop ? 234 : 58,
          }}
          text={text}
          bigImg={image}
          mobileCenter
          mobileTextCenter
        >
          {mainTitle}
        </ContentBox>
        <BackgroundBox reverse={reverse} />
      </Row>
      <SubContentWrapper>
        {subContent.map(
          ({ redButton, title, content, buttonText, path }, index) =>
            isDesktop ? (
              <SubContent
                key={title}
                redButton={redButton}
                title={multiLanguage[title][currentLanguage]}
                content={multiLanguage[content][currentLanguage]}
                buttonText={multiLanguage[buttonText][currentLanguage]}
                path={path}
                currentLanguage={currentLanguage}
              />
            ) : (
              !index && (
                <SubContent
                  key={title}
                  redButton={redButton}
                  title={multiLanguage[title][currentLanguage]}
                  content={multiLanguage[content][currentLanguage]}
                  buttonText={multiLanguage[buttonText][currentLanguage]}
                  path={path}
                  buttonText1={
                    multiLanguage[subContent[1].buttonText][currentLanguage]
                  }
                  path1={subContent[1].path}
                  currentLanguage={currentLanguage}
                />
              )
            )
        )}
      </SubContentWrapper>
    </Container>
  );
};

export default ChannelContent;
