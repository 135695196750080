import styled, { css } from 'styled-components';
import { device } from './mediaQueries';
import { motion } from 'framer-motion';

export const Span = styled.span`
  font-weight: ${({ theme }) => theme.thin};
`;

export const H3 = styled.strong`
  display: block;
  font-weight: ${({ theme }) => theme.thin};
  font-size: 1.5625rem;
`;

export const Background = styled.div`
  width: 100%;
  background: linear-gradient(#f5f5f5 0%, #f5f5f5 100%) no-repeat;
  background-size: calc(100%)
    calc(${({ mobileFull }) => (mobileFull ? '100%' : '85%')});
  background-position: bottom;
  @media ${device.desktop} {
    background-size: calc(100%)
      calc(${({ height }) => (height ? height : '75%')});
  }
`;

export const MarginTop = css`
  margin-top: 3rem;
  @media ${device.desktop} {
    margin-top: 7.36875rem;
  }
`;

export const FullBackground = styled(Background)`
  background: unset;
  background-image: linear-gradient(#f5f5f5, #f5f5f5);
`;

export const FlexColCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FontWeightThin = css`
  font-weight: ${({ theme }) => theme.thin};
`;

export const FontWeightNormal = css`
  font-weight: ${({ theme }) => theme.normal};
`;

export const FontWeightMedium = css`
  font-weight: ${({ theme }) => theme.medium};
`;

export const FontWeightBold = css`
  font-weight: ${({ theme }) => theme.bold};
`;

export const FontWeightDemiLite = css`
  font-weight: ${({ theme }) => theme.demiLite};
`;

export const ColorRed = css`
  color: ${({ theme }) => theme.red};
`;
export const ColorTextGray = css`
  color: ${({ theme }) => theme.textGray};
`;
export const ColorTextRed = css`
  color: ${({ theme }) => theme.textRed};
`;
export const ColorOrange = css`
  color: ${({ theme }) => theme.orange};
`;

export const BackgroundRed = css`
  background-color: ${({ theme }) => theme.red};
`;

export const BackgroundLightRed = css`
  background-color: ${({ theme }) => theme.lightRed};
`;
export const BackgroundLightGray = css`
  background-color: ${({ theme }) => theme.lightGray};
`;

export const WrapperWidth = css`
  width: 100vw;
  @media ${device.tablet} {
    width: 100vw;
  }
  @media ${device.desktop} {
    width: 100%;
    max-width: 1470px;
  }
`;

export const WrapperWidth1240 = css`
  width: 100vw;
  @media ${device.tablet} {
    width: 100vw;
  }
  @media ${device.desktop} {
    width: 100%;
    max-width: 1240px;
  }
`;

export const Subtitle = styled.p`
  text-align: center;
  max-width: 221px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: -0.48px;
  @media ${device.toTablet} {
    color: ${({ theme }) => theme.textGray};
  }
  @media ${device.desktop} {
    max-width: 532px;
    font-size: 1.125rem;
  }
`;

export const LeftDivider = css`
  background-image: linear-gradient(
    ${({ theme }) => theme.gray},
    ${({ theme }) => theme.gray}
  );
  background-repeat: no-repeat;
  background-size: 1px 7px;
  background-position: left center;
  @media ${device.desktop} {
    background-size: 1px 13px;
  }
`;

export const HambugerWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background: ${({ theme }) => theme.red};
  z-index: 200;
  overflow-y: auto;
`;
