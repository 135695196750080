import slide1 from '../assets/business/battery/logo/logo-slide-01.png';
import slide2 from '../assets/business/battery/logo/logo-slide-02.png';
import slide3 from '../assets/business/battery/logo/logo-slide-03.png';
import slide4 from '../assets/business/battery/logo/logo-slide-04.png';
import slide5 from '../assets/business/battery/logo/logo-slide-05.png';
import slide11 from '../assets/business/battery/logo/logo-slide-11.png';
import slide12 from '../assets/business/battery/logo/logo-slide-12.png';
import slide13 from '../assets/business/battery/logo/logo-slide-13.png';
import slide14 from '../assets/business/battery/logo/logo-slide-14.png';
import slide15 from '../assets/business/battery/logo/logo-slide-15.png';
import slide16 from '../assets/business/battery/logo/logo-slide-16.png';
import slide17 from '../assets/business/battery/logo/logo-slide-17.png';
import slide18 from '../assets/business/battery/logo/logo-slide-18.png';
import slide19 from '../assets/business/battery/logo/logo-slide-19.png';
import slide20 from '../assets/business/battery/logo/logo-slide-20.png';
import slide21 from '../assets/business/battery/logo/logo-slide-21.png';
import slide22 from '../assets/business/battery/logo/logo-slide-22.png';
import slide23 from '../assets/business/battery/logo/logo-slide-23.png';
import slide24 from '../assets/business/battery/logo/logo-slide-24.png';
import slide25 from '../assets/business/battery/logo/logo-slide-25.png';
import slide27 from '../assets/business/battery/logo/logo-slide-27.png';
import slide28 from '../assets/business/battery/logo/logo-slide-28.png';

import photo1 from '../assets/business/battery/photo/photo-01-cgv.png';
import photo2 from '../assets/business/battery/photo/photo-02-cgv.png';
import photo3 from '../assets/business/battery/photo/photo-03-cgv.png';
import photo4 from '../assets/business/battery/photo/photo-04-cgv.png';
import photo5 from '../assets/business/battery/photo/photo-05-cgv.png';
import photo6 from '../assets/business/battery/photo/photo-06-cgv.png';
import photo7 from '../assets/business/battery/photo/photo-07.png';
import photo8 from '../assets/business/battery/photo/photo-08.png';
import photo9 from '../assets/business/battery/photo/photo-09.png';
import photo10 from '../assets/business/battery/photo/photo-10.png';
import photo11 from '../assets/business/battery/photo/photo-11.png';
import photo12 from '../assets/business/battery/photo/photo-12.png';
import photo13 from '../assets/business/battery/photo/photo-13.png';
import photo14 from '../assets/business/battery/photo/photo-14.png';
import photo15 from '../assets/business/battery/photo/photo-15.png';
import photo16 from '../assets/business/battery/photo/photo-16.png';

export const logos = [
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide11,
  slide12,
  slide13,
  slide14,
  slide15,
  slide16,
  slide17,
  slide18,
  slide19,
  slide20,
  slide21,
  slide22,
  slide23,
  slide24,
  slide25,
  slide27,
  slide28,
];

export const photos = [
  { name: 'CGV 수원', image: photo1 },
  { name: 'CGV 신촌', image: photo2 },
  { name: 'CGV 영등포', image: photo3 },
  { name: 'CGV 왕십리', image: photo4 },
  { name: 'CGV 용산', image: photo5 },
  { name: 'CGV 인천', image: photo6 },
  { name: '롯데시네마 건대', image: photo7 },
  { name: '롯데시네마 월드타워', image: photo8 },
  { name: '매드포갈릭 월드타워', image: photo9 },
  { name: '푸른솔 골프장', image: photo10 },
  { name: '매드포갈릭 합정', image: photo11 },
  { name: '세브란스 병원', image: photo13 },
  { name: '메가박스 상암', image: photo12 },
  { name: '2', image: null },
  { name: '엘리웨이 광교', image: photo14 },
  { name: '1', image: null },
  { name: '인사동 쌈지길 지하', image: photo15 },
  { name: '3', image: null },
  { name: '잭슨나인스 호텔', image: photo16 },
  { name: '4', image: null },
];
