import React from 'react';
import styled, { css } from 'styled-components';
import {
  FontWeightBold,
  FontWeightMedium,
  FlexColCenter,
} from '../../../styles/commonStyle';
import { device } from '../../../styles/mediaQueries';
const PathItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  white-space: pre-line;
  counter-increment: section;
  h3 {
    ::before {
      content: '0' counter(section);
      display: inline-block;
      margin-right: 5px;
      font-size: 0.9rem;
      ${FontWeightBold}
      background-color: transparent;
      color: black;
    }
  }
  @media ${device.toTablet} {
    ${({ mobileCol }) =>
      mobileCol
        ? css`
            align-self: flex-start;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 30px;
            img {
              max-height: 30px;
            }
            aside {
              h3 {
                margin-top: 15px;
                ${FlexColCenter}
                font-size: 0.9375rem;
                ${FontWeightMedium}
                ::before {
                  content: '0' counter(section);
                  position: absolute;
                  top: 0;
                  left: 44%;
                  font-size: 0.9375rem;
                  ${FontWeightMedium}
                  background-color: transparent;
                  color: black;
                }
              }
              p {
                max-width: 170px;
                margin-top: 10px;
                display: inline-block;
                font-size: 0.8rem;
                letter-spacing: -0.48px;
                line-height: 1.58;
                word-break: keep-all;
                color: ${({ theme }) => theme.textGray};
              }
            }
          `
        : css`
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            img {
              max-width: 50px;
              height: auto;
            }
            aside {
              position: relative;
              text-align: left;
              margin-left: 35px;

              h3 {
                font-size: 0.9375rem;
                ${FontWeightMedium}
                ::before {
                  content: '0' counter(section);
                  display: inline-block;
                  margin-right: 5px;
                  font-size: 0.9375rem;
                  ${FontWeightMedium}
                  background-color: transparent;
                  color: black;
                }
              }
              p {
                max-width: 170px;
                margin-top: 10px;
                display: inline-block;
                font-size: 0.8rem;
                letter-spacing: -0.48px;
                line-height: 1.58;
                word-break: keep-all;
                color: ${({ theme }) => theme.textGray};
              }
            }
          `}
  }
  @media ${device.desktop} {
    width: 230px;
    height: 270px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: pre-line;
    aside {
      margin-top: 24px;
      width: 212px;
      word-break: keep-all;
      word-wrap: break-word;
    }
    h3 {
      font-size: 1.125rem;
      ::before {
        position: absolute;
        top: 0;
        left: 47%;
        font-size: 1.25rem;
      }
    }
    p {
      font-size: 1rem;
      margin-top: 18px;
      color: ${({ theme }) => theme.textGray};
    }
  }
`;

const PathDotItem = ({ mobileCol, title, text, icon }) => {
  return (
    <PathItem mobileCol={mobileCol}>
      <img src={icon} alt='icon' />
      <aside>
        <h3>{title}</h3>
        <p>{text}</p>
      </aside>
    </PathItem>
  );
};

export default PathDotItem;
