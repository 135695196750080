import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Row } from 'antd';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import BoardCard from '../common/BoardCard';
import LoadItemButton from '../buttons/LoadItemButton';

import { WrapperWidth1240 } from '../../styles/commonStyle';
import multiLanguage from '../../assets/multiLanguage.json';
import { device } from '../../styles/mediaQueries';
import useNewsData from '../../hooks/useNewsData';

const BoardWrapper = styled.div`
  ${WrapperWidth1240}
  margin-top: 50px;
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill, 386px);
    grid-gap: 1rem;
  }
  ${({ showLoadButton }) =>
    !showLoadButton &&
    css`
      @media ${device.toTablet} {
        margin-bottom: 50px;
      }
      margin-bottom: 185px;
    `};
  @media ${device.toTablet} {
    padding: 0 4.2vw;
    grid-template-rows: repeat(auto-fill, 316px);
    align-items: center;
    grid-gap: 2rem;
  }
`;

const ListItem = styled.li`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  float: left;
  font-weight: ${({ isActive, theme }) => isActive && theme.bold};

  span {
    padding: 0.6rem 0;
    ${({ isActive, theme }) =>
      isActive && `border-bottom: solid 2px ${theme.red}`};
  }

  &:nth-child(even) {
    ::before,
    ::after {
      display: inline-block;
      content: '';
      margin: 0 10px;
      line-height: 1rem;
      height: 1rem;
      width: 1px;
      background-color: #cccccc;
    }
  }
  &:last-child {
    ::after {
      display: none;
    }
  }
  @media ${device.desktop} {
    font-size: 1.1875rem;
    &:nth-child(even) {
      ::before,
      ::after {
        display: inline-block;
        content: '';
        margin: 0 10px;
        line-height: 1.1875rem;
        height: 1rem;
        width: 1px;
        background-color: #cccccc;
      }
    }
  }
`;

const tabNames = [
  { name: '전체', label: 'all' },
  { name: '판다 뉴스', label: 'news' },
  { name: '공지사항', label: 'notice' },
  { name: '보도자료', label: 'publishing' },
];

const News = ({ currentLanguage }) => {
  const [currentTab, setCurrentTab] = useState(tabNames[0]);
  const { list, showLoadMore, showButton, handleShowMore } = useNewsData(
    currentLanguage,
    currentTab
  );

  return (
    <div className='pd-flex-center'>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <SubHeading span strong>
              {multiLanguage['판다'][currentLanguage] + ' '}
            </SubHeading>
            NEWS
          </SubHeading>
        </WithTopDivider>
      </Row>
      <Row justify='center'>
        <ul>
          {tabNames.map((item) => (
            <ListItem
              key={item.name}
              isActive={currentTab.label === item.label}
              onClick={() => setCurrentTab(item)}
            >
              <span>{multiLanguage[item.name][currentLanguage]}</span>
            </ListItem>
          ))}
        </ul>
      </Row>
      <BoardWrapper showLoadButton={showButton}>
        {list.length > 0 &&
          list
            .slice(0, showLoadMore)
            .map((item, index) => (
              <BoardCard
                index={index}
                key={item.key}
                data={item}
                currentLanguage={currentLanguage}
                path='/company/news'
              />
            ))}
      </BoardWrapper>
      {showButton && <LoadItemButton onClick={handleShowMore} />}
    </div>
  );
};

export default News;
