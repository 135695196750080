import React from 'react';
import styled from 'styled-components';

import background from '../../../assets/business/bike/background-kakao-panda.png';
import backgroundM from '../../../assets/business/bike/background-kakao-panda-m.png';
import logoPanda from '../../../assets/business/bike/logo-panda.png';
import logoKaKao from '../../../assets/business/bike/logo-kakao.png';
import icon1 from '../../../assets/business/bike/bike-icon-02-01.png';
import icon2 from '../../../assets/business/bike/bike-icon-02-02.png';
import icon3 from '../../../assets/business/bike/bike-icon-02-03.png';
import icon4 from '../../../assets/business/bike/bike-icon-02-04.png';

import multiLanguage from '../../../assets/multiLanguage.json';
import {
  FlexColCenter,
  FontWeightDemiLite,
  FontWeightThin,
} from '../../../styles/commonStyle';
import { device } from '../../../styles/mediaQueries';

const Container = styled.section`
  position: relative;
  margin: 221px 0 746px;
  width: 1423px;
  height: 428px;
  background: url(${background}) no-repeat center;
  article,
  figure {
    position: absolute;
  }
  article {
    width: 285px;
  }
  article:first-child {
    top: ${({ currentLanguage }) => (currentLanguage === 'EN' ? -30 : -26)}%;
    left: 3%;
  }
  article:nth-child(2) {
    top: ${({ currentLanguage }) => (currentLanguage === 'EN' ? -30 : -26)}%;
    left: 76%;
  }
  article:nth-child(3) {
    top: 62%;
    left: 2%;
  }
  article:nth-child(4) {
    top: ${({ currentLanguage }) => (currentLanguage === 'EN' ? 58 : 62)}%;
    left: 78%;
  }
  figure:nth-of-type(1) {
    top: 47%;
    left: 27%;
  }
  figure:nth-of-type(2) {
    top: 44%;
    left: 55%;
  }
  @media ${device.toTablet} {
    margin: 80px 0 288px;
    width: 306px;
    height: 310px;
    background: url(${backgroundM}) no-repeat center;
    background-size: contain;
    img {
      max-width: 113px;
    }
    article {
      width: 80px;
    }
    article:first-child {
      top: -12%;
      left: 77%;
    }
    article:nth-child(2) {
      top: 88%;
      left: 2%;
    }
    article:nth-child(3) {
      top: -12%;
      left: 1%;
    }
    article:nth-child(4) {
      top: 87%;
      left: 74%;
    }
    figure:nth-of-type(1) {
      top: 28%;
      left: 32%;
    }
    figure:nth-of-type(2) {
      img {
        width: 76px;
      }
      top: 68%;
      left: 38.5%;
    }
  }
`;
const ItemWrapper = styled.article`
  ${FlexColCenter}
  * {
    text-align: center;
  }
  h6 {
    margin: 14px 0 30px;
    font-size: ${({ currentLanguage }) =>
      currentLanguage === 'EN' ? 20 : 24}px;
    ${FontWeightDemiLite}
  }
  p {
    font-size: ${({ currentLanguage }) =>
      currentLanguage === 'EN' ? 16 : 20}px;
    ${FontWeightThin}
  }
  @media ${device.toTablet} {
    h6 {
      width: 75px;
      margin: 14px 0 0;
      font-size: 12px;
      line-height: 1.5;
    }
    img {
      max-width: 33px;
    }
  }
`;

const items = [
  {
    icon: icon1,
    text: '사용자 서비스 개발 및 운영 DESC',
    title: '결제,관제 시스템 운영',
  },
  {
    icon: icon2,
    text: '품질 관리 및 부품 조달 관리 DESC',
    title: '자전거 표준 검품 시스템 제공',
  },
  {
    icon: icon3,
    text: '상품 기획 운영 서비스 홍보, 마케팅',
    title: '서비스 운영 기획',
  },
  {
    icon: icon4,
    text: '판다코리아닷컴 수입 DESC',
    title: '전문 물류 통관 솔루션 제공',
  },
];
const Item = ({ icon, text, title, currentLanguage }) => (
  <ItemWrapper currentLanguage={currentLanguage}>
    <img src={icon} alt='icon' />
    <h6>{title}</h6>
    <p>{text}</p>
  </ItemWrapper>
);

const KaKaoPanda = ({ isDesktop, currentLanguage }) => {
  return (
    <Container currentLanguage={currentLanguage}>
      {items.map(({ icon, text, title }) => (
        <Item
          currentLanguage={currentLanguage}
          key={title}
          icon={icon}
          text={isDesktop ? multiLanguage[text][currentLanguage] : ''}
          title={multiLanguage[title][currentLanguage]}
        />
      ))}
      <figure>
        <img src={logoKaKao} alt='kakao logo' />
      </figure>
      <figure>
        <img src={logoPanda} alt='panda logo' />
      </figure>
    </Container>
  );
};

export default KaKaoPanda;
