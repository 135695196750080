import React from 'react';
import { Row } from 'antd';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import ContactUs from '../main/ContactUs';

import multiLanguage from '../../assets/multiLanguage.json';

const Inquiry = ({ currentLanguage, isDesktop }) => {
  return (
    <div className='pd-flex-center' style={{ marginBottom: 88 }}>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  multiLanguage['판다코리아닷컴 문의하기'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <div>
        <ContactUs
          black
          currentLanguage={currentLanguage}
          isDesktop={isDesktop}
        />
      </div>
    </div>
  );
};

export default Inquiry;
