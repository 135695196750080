import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/mediaQueries';

const ArrowWrapper = styled.aside`
  width: 25px;
  height: 25px;
  border-top: 3px solid;
  border-right: 3px solid;
  border-image: linear-gradient(45deg, #f6d173 50%, #eca343 95%);
  border-image-slice: 1;
  display: inline-block;
  transform: rotate(${({ degree }) => degree ?? 45}deg);
  @media ${device.toTablet} {
    width: 13px;
    height: 13px;
    border-top: 1.5px solid;
    border-right: 1.5px solid;
    transform: rotate(${({ degree }) => degree ?? 135}deg);
  }
`;

const Arrow = ({ degree }) => {
  return <ArrowWrapper degree={degree} />;
};

export default Arrow;
