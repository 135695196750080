import alibaba from '../assets/business/online/slide/alibaba.png';
import cledbel from '../assets/business/online/slide/cledbel.png';
import howpapa from '../assets/business/online/slide/howpapa.png';
import lazada from '../assets/business/online/slide/lazada.png';
import megaten from '../assets/business/online/slide/megaten.png';
import needly from '../assets/business/online/slide/needly.png';
import pandahao from '../assets/business/online/slide/pandahao.png';
import taobao from '../assets/business/online/slide/taobao.png';
import tmall from '../assets/business/online/slide/tmall.png';
import wemake from '../assets/business/online/slide/wemake.png';
import sculptor from '../assets/business/online/slide/sculptor.png';
import ovaco from '../assets/business/online/slide/ovaco.png';

const CATEGORY = {
  KIDS: 'KIDS',
  BEAUTY: 'BEAUTY',
  MULTI: 'MULTI SHOP',
  FASHION: 'FASHION',
};
export const stores = [
  {
    name: 'GLOBAL ALIBABA',
    url: 'http://pandakorea.trustpass.alibaba.com',
    category: CATEGORY.BEAUTY,
    image: alibaba,
  },
  {
    name: { kr: '위메프', en: 'WEMAKEPRICE' },
    url: 'http://weimeipu.tmall.hk',
    category: CATEGORY.MULTI,
    image: wemake,
  },
  {
    name: 'TMALL GLOBAL',
    url: 'http://pandahzp.tmall.hk',
    category: CATEGORY.BEAUTY,
    image: tmall,
  },
  {
    name: 'HOWPAPA',
    url: 'http://howpapa.tmall.hk',
    category: CATEGORY.KIDS,
    image: howpapa,
  },
  {
    name: 'MEGATEN',
    url: 'http://megatenglobal.tmall.hk',
    category: CATEGORY.KIDS,
    image: megaten,
  },
  {
    name: 'NEEDLY',
    url: 'https://needly.tmall.hk/',
    category: CATEGORY.BEAUTY,
    image: needly,
  },
  {
    name: 'CLEDBEL',
    url: 'https://cledbel.tmall.hk/',
    category: CATEGORY.BEAUTY,
    image: cledbel,
  },
  {
    name: 'LAZADA',
    url: 'http://lazada.sg/shop/pandapick',
    category: CATEGORY.KIDS,
    image: lazada,
  },
  {
    name: 'TAOBAO',
    url: 'http://pandachina.taobao.com',
    category: CATEGORY.KIDS,
    image: taobao,
  },
  {
    name: { kr: '중국식품몰', en: 'PANDAHAO' },
    url: 'https://smartstore.naver.com/panda_korea',
    category: CATEGORY.MULTI,
    image: pandahao,
  },
  {
    name: 'SCULPTOR',
    url: 'https://sculptorglobal.tmall.hk/',
    category: CATEGORY.MULTI,
    image: sculptor,
  },
  {
    name: 'OVACO',
    url: 'https://ovaco.tmall.hk',
    category: CATEGORY.BEAUTY,
    image: ovaco,
  },
];
