import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Table } from 'antd';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import tableColums from '../../data/tableColums';
import { WrapperWidth1240 } from '../../styles/commonStyle';
import { device } from '../../styles/mediaQueries';
import multiLanguage from '../../assets/multiLanguage.json';
import { useSelector } from 'react-redux';

const TableWrapper = styled.div`
  ${WrapperWidth1240}
  font-size: 1.0625rem;
  .ant-table-wrapper {
    border-top: solid 2px #0f0f0f;
  }
  .ant-table-cell {
    cursor: pointer;
    :hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  @media ${device.toTablet} {
    margin-top: 0;
    padding: 0 4.2vw;
  }
`;
const Alibaba = ({ currentLanguage }) => {
  const history = useHistory();
  const { alibabaList } = useSelector((state) => state.global);
  return (
    <div className='pd-flex-center'>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: multiLanguage['알리바바 입점 가이드'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      {/* <Row justify="center">
        <Search
          placeholder="input search text"
          onSearch={(value) => console.log(value)}
          enterButton
          style={{ width: 510 }}
        />
      </Row> */}
      <TableWrapper>
        <Table
          onRow={(record) => {
            return {
              onClick: () => history.push(`/information/alibaba/${record.SK}`),
            };
          }}
          showHeader={false}
          pagination={{ position: ['none', 'bottomCenter'] }}
          columns={tableColums}
          dataSource={alibabaList}
        />
      </TableWrapper>
    </div>
  );
};

export default Alibaba;
