import React from 'react';
import styled from 'styled-components';
import { Row, Divider } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import SubIconWithText from '../texts/SubIconWithText';
import VerticalDivider from '../common/VerticalDivider';
import Text from '../texts/Text';
import talentBackground from '../../assets/recuruit/talent-background.png';
import professionalism from '../../assets/recuruit/professionalism.png';
import Innovation from '../../assets/recuruit/innovation.png';
import diversity from '../../assets/recuruit/diversity.png';
import bottom from '../../assets/recuruit/bottom-background.png';
import personnel1 from '../../assets/recuruit/personnel-1.png';
import personnel2 from '../../assets/recuruit/personnel-2.png';
import personnel3 from '../../assets/recuruit/personnel-3.png';
import personnel4 from '../../assets/recuruit/personnel-4.png';
import personnel5 from '../../assets/recuruit/personnel-5.png';
import { device } from '../../styles/mediaQueries';

import multiLanguage from '../../assets/multiLanguage.json';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.toTablet} {
    & p {
      padding: 0 4.2vw;
      font-size: 0.9rem;
    }
  }
`;
const TalrentContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.9625rem;
  section {
    position: relative;
    div {
      position: absolute;
      color: #ffffff;
      text-align: center;
      figure {
        font-weight: ${({ theme }) => theme.bold};
        font-size: 1.5rem;
        line-height: 2;
      }
      figcaption {
        font-weight: ${({ theme }) => theme.medium};
        font-size: 1.25rem;
      }
    }
  }

  div.first {
    top: 20%;
    left: 36%;
  }

  div.second {
    top: 65%;
    left: 14%;
  }
  div.third {
    top: 65%;
    right: 17%;
  }
  @media ${device.toTablet} {
    section div figure {
      font-size: 1rem;
      line-height: 2;
    }
    section div figcaption {
      font-size: 0.9rem;
    }
    img {
      width: 300px;
    }
    div.first {
      top: 20%;
      left: 31%;
    }

    div.second {
      top: 65%;
      left: 10%;
    }
    div.third {
      top: 65%;
      right: 15%;
    }
  }
`;

const PrincipleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${bottom});
  p.title {
    line-height: 1.5;
    font-size: 1.375rem;
    color: #ffffff;
    span {
      font-weight: ${({ theme }) => theme.bold};
    }
  }
  sub {
    display: block;
    font-size: 0.9rem;
    line-height: 1.5;
    color: #ffffff;
  }
  section,
  section > div {
    color: #ffffff;
  }
  @media ${device.toTablet} {
    padding: 0 4.2vw;
    p.title {
      margin-bottom: 10px;
    }
    sub {
      text-align: center;
      width: 220px;
    }
    section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      justify-items: center;
      padding-bottom: 50px;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: 20px;
        img {
          width: 92px;
        }
        p {
          margin-top: 10px;
          font-size: 0.9rem;
        }
      }
      div:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
  @media ${device.desktop} {
    height: 511.5px;
    section > div > img {
      width: 140px;
    }
    p.title {
      font-size: 1.75rem;
    }
    sub {
      font-size: 1.25rem;
    }
    section,
    section > div {
      display: flex;
      color: #ffffff;
      justify-content: center;
      align-items: center;
    }
    section {
      margin-top: 47.4px;
      & > div {
        flex-direction: column;
        margin-left: 32px;
        img {
          width: 120px;
        }
        p {
          margin-top: 14.6px;
        }
      }
      & > div:first-child {
        margin-left: 0;
      }
    }
  }
`;

const DividerStyle = styled(Divider)`
  background-color: #d8d8d8;
  min-width: 100vw;
  width: 100vw;
  @media ${device.desktop} {
    margin-top: 97.3px;
    min-width: 61.4vw;
    width: 61.4vw;
  }
`;

const SecondSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px;
  @media ${device.toTablet} {
    div {
      width: 80%;
    }
  }
  @media ${device.desktop} {
    flex-direction: row;
    margin: 68px 0 136px;
  }
`;

const Career = ({ currentLanguage, isDesktop }) => {
  return (
    <Container>
      <WithTopDivider marginBottom={'1.0625rem'}>
        <SubHeading>
          <span
            dangerouslySetInnerHTML={{
              __html: multiLanguage['판다코리아닷컴 인재상'][currentLanguage],
            }}
          />
        </SubHeading>
      </WithTopDivider>
      <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
        <Text center fontSize={'1.25rem'}>
          {
            multiLanguage[
              '판다코리아닷컴의 성장은 글로벌 인재를 통해 이루어집니다.'
            ][currentLanguage]
          }
        </Text>
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
        <TalrentContainer>
          <section>
            <img src={talentBackground} alt='talent' />
            <div className='first'>
              <figure>Professionalism</figure>
              <figcaption>
                {multiLanguage['프로의식'][currentLanguage]}
              </figcaption>
            </div>
            <div className='second'>
              <figure>Innovation</figure>
              <figcaption>{multiLanguage['혁신'][currentLanguage]}</figcaption>
            </div>
            <div className='third'>
              <figure>Diversity</figure>
              <figcaption>
                {multiLanguage['다양성'][currentLanguage]}
              </figcaption>
            </div>
          </section>
        </TalrentContainer>
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
        <Row justify='center'>
          <DividerStyle />
        </Row>
        <SecondSectionWrapper>
          <SubIconWithText
            title={'Professionalism'}
            subTitle={multiLanguage['프로의식'][currentLanguage]}
            text={
              multiLanguage[
                '자신의 영역에서 필요한 전문 지식을 보유하고 효과적으로 활용하며 전문성을 발휘하는 인재'
              ][currentLanguage]
            }
            icon={professionalism}
          />
          {isDesktop ? (
            <VerticalDivider style={{ marginRight: 67, marginLeft: 67 }} />
          ) : (
            <DividerStyle />
          )}
          <SubIconWithText
            title={'Innovation'}
            subTitle={multiLanguage['혁신'][currentLanguage]}
            text={
              multiLanguage[
                '창의적이고 융합적인 사고로 문제를 해결하고 혁신을 이뤄내는 인재'
              ][currentLanguage]
            }
            icon={Innovation}
          />
          {isDesktop ? (
            <VerticalDivider style={{ marginRight: 67, marginLeft: 67 }} />
          ) : (
            <DividerStyle />
          )}
          <SubIconWithText
            title={'Diversity'}
            subTitle={multiLanguage['다양성'][currentLanguage]}
            text={
              multiLanguage[
                '상호간의 다양성을 인정하고 존중하며 공동의 목표를 위해 노력하는 인재'
              ][currentLanguage]
            }
            icon={diversity}
          />
        </SecondSectionWrapper>
      </ScrollAnimation>

      <PrincipleContainer>
        <WithTopDivider center marginBottom={'1.0625rem'} color='white'>
          <p
            className='title'
            dangerouslySetInnerHTML={{
              __html:
                multiLanguage['판다코리아닷컴의 인사원칙'][currentLanguage],
            }}
          />
          <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
            <sub>
              {
                multiLanguage[
                  '판다코리아닷컴은 다음의 원칙을 인사와 조직운영의 기본으로 삼고있습니다.'
                ][currentLanguage]
              }
            </sub>
          </ScrollAnimation>
        </WithTopDivider>
        <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
          <section>
            <div>
              <img src={personnel1} alt='personnel1' />
              <p>{multiLanguage['개인존중'][currentLanguage]}</p>
            </div>
            <div>
              <img src={personnel2} alt='personnel2' />
              <p>{multiLanguage['기회제공'][currentLanguage]}</p>
            </div>
            <div>
              <img src={personnel3} alt='personnel3' />
              <p>{multiLanguage['인재육성'][currentLanguage]}</p>
            </div>
            <div>
              <img src={personnel4} alt='personnel4' />
              <p>{multiLanguage['보상실시'][currentLanguage]}</p>
            </div>
            <div>
              <img src={personnel5} alt='personnel5' />
              <p>{multiLanguage['공정한 평가'][currentLanguage]}</p>
            </div>
          </section>
        </ScrollAnimation>
      </PrincipleContainer>
    </Container>
  );
};

export default Career;
