import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/mediaQueries';

export const P = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '0.9rem')};
  font-weight: ${({ strong, theme }) => (strong ? theme.bold : theme.normal)};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  color: ${({ theme, color }) => theme[color]};
  white-space: pre-wrap;
  @media ${device.desktop} {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.25rem')};
  }
  ${(style) => style}
`;

const Text = ({ children, strong, color, center, span, style, fontSize }) => {
  return (
    <>
      <P
        as={span ? 'span' : 'p'}
        strong={strong}
        color={color}
        center={center}
        fontSize={fontSize}
        style={style}
      >
        {children}
      </P>
    </>
  );
};

export default Text;
