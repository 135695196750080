import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Row } from 'antd';

import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import multiLanguage from '../../assets/multiLanguage.json';
import TotalSolution from './TotalSolution';
import B2c from './B2c';
import B2b from './B2b';
import Online from './Online';

import { device } from '../../styles/mediaQueries';
import {
  FontWeightDemiLite,
  FontWeightMedium,
  LeftDivider,
} from '../../styles/commonStyle';

const TabContainer = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 156px;
  @media ${device.toTablet} {
    margin-bottom: 40px;
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
const ListItem = styled.li`
  cursor: pointer;
  float: left;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${FontWeightDemiLite}
  span {
    position: relative;
    display: inline-block;
    padding: 0.6rem 10px;
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      ${FontWeightMedium}
      span {
        color: ${theme.black};
        ::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -5px;
          margin: auto;
          height: 1px;
          width: 140px;
          border-bottom: 1px solid ${theme.red};
          @media ${device.toTablet} {
            width: 69px;
          }
        }
      }
    `};
  @media ${device.toTablet} {
    margin-bottom: 15px;
    &:nth-child(even) {
      ${LeftDivider}
    }
  }
  @media ${device.desktop} {
    font-size: 1.1875rem;
    padding: 0.6rem 0;
    span {
      color: ${({ theme }) => theme.black};
      margin: 0 10px;
      padding: 10px;
      line-height: 1;
    }
    ${LeftDivider}

    &:first-child {
      background: transparent;
    }
  }
`;

const tabNames = [
  {
    name: '이커머스 토탈솔루션',
    label: 'total-solution',
    path: '/business/e-commerce/total-solution',
  },
  { name: 'B2C채널서비스', label: 'b2c', path: '/business/e-commerce/b2c' },
  {
    name: 'B2B알리바바 서비스',
    label: 'b2b',
    path: '/business/e-commerce/b2b',
  },
  {
    name: '중국 온라인 운영대행',
    label: 'online',
    path: '/business/e-commerce/online',
  },
];

const ECommerce = ({ pathname, currentLanguage, isDesktop, isMobile }) => {
  const [currentTab, setCurrentTab] = useState(tabNames[0]);
  const history = useHistory();
  const getTabItem = useCallback(
    (pathname) => {
      const item = tabNames.find((item) => item.path === pathname);
      if (item) return item;

      history.push(tabNames[0].path);
      return tabNames[0];
    },
    [pathname]
  );

  useEffect(() => {
    setCurrentTab(getTabItem(pathname));
  }, [getTabItem, pathname]);

  const renderComponent = (label) => {
    switch (label) {
      case 'total-solution':
        return (
          <TotalSolution
            multiLanguage={multiLanguage}
            currentLanguage={currentLanguage}
            isDesktop={isDesktop}
          />
        );
      case 'b2c':
        return (
          <B2c
            multiLanguage={multiLanguage}
            currentLanguage={currentLanguage}
            isDesktop={isDesktop}
          />
        );
      case 'b2b':
        return (
          <B2b
            isDesktop={isDesktop}
            multiLanguage={multiLanguage}
            currentLanguage={currentLanguage}
          />
        );
      case 'online':
        return (
          <Online
            isDesktop={isDesktop}
            multiLanguage={multiLanguage}
            currentLanguage={currentLanguage}
          />
        );
      default:
        return (
          <TotalSolution
            multiLanguage={multiLanguage}
            currentLanguage={currentLanguage}
            isDesktop={isDesktop}
          />
        );
    }
  };
  return (
    <div>
      {isDesktop && (
        <Row justify='center'>
          <WithTopDivider>
            <SubHeading span strong>
              E-commerce
            </SubHeading>
          </WithTopDivider>
        </Row>
      )}
      <TabContainer>
        {tabNames.map((item) => (
          <ListItem key={item.name} isActive={currentTab.label === item.label}>
            <Link to={`${item.path}#tab`}>
              <span>{multiLanguage[item.name][currentLanguage]}</span>
            </Link>
          </ListItem>
        ))}
      </TabContainer>
      {renderComponent(currentTab.label)}
    </div>
  );
};

export default ECommerce;
