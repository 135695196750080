import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import { RightCircleOutlined } from '@ant-design/icons';
import TextWithDivider from '../texts/TextWithDivider';
import { device } from '../../styles/mediaQueries';
import contents from '../../data/mainGuideContents';
import multiLanguage from '../../assets/multiLanguage.json';

const Container = styled.div`
  width: 100%;
  height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media ${device.tablet} {
    height: 425px;
  }
`;
const SliderWrapper = styled.div`
  align-self: flex-end;
  width: 100vw;
  height: 218px;
`;
const TabContent = styled.div`
  width: 90vw;
  display: flex;
  justify-content: space-between;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: 26vw;
  background-position: 90% 10%;
  background-color: #f7f7f7;
  padding: 26px;
`;

const Content = styled.p`
  width: 231px;
  font-size: 0.8rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.textGray};
  white-space: pre-wrap;
`;

const ContentTitle = styled.p`
  font-weight: ${({ theme }) => theme.medium};
  font-size: 0.9rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.black};
  white-space: pre-wrap;
  letter-spacing: -1px;
`;

const SliderDots = styled.ul`
  display: flex;
  li {
    width: 12px;
    height: 12px;
    background-color: #747474;
    border-radius: 6px;
    &:not(:first-child) {
      margin-left: 10px;
    }
    &.active {
      width: 45px;
      background-color: #0f0f0f;
    }
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: linear;
  }
`;

const GuideMobile = ({ currentLanguage }) => {
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  const styles = {
    root: {
      paddingRight: 30,
    },
    slideContainer: {
      padding: '0 10px',
    },
  };
  return (
    <Container>
      <SliderWrapper>
        <SwipeableViews
          index={index}
          style={styles.root}
          slideStyle={styles.slideContainer}
          onChangeIndex={handleChangeIndex}
        >
          {contents.map((item) => (
            <TabContent key={item.text} image={item.background}>
              <div>
                <TextWithDivider
                  number={item.number}
                  text={item.text}
                  subText={item.subText}
                  long
                  dividerColor='red'
                  numberColor='red'
                />
                <Link to={item.path}>
                  <ContentTitle>
                    {multiLanguage[item.contentTitle][currentLanguage]}
                    <RightCircleOutlined style={{ paddingLeft: 10 }} />
                  </ContentTitle>
                  <Content>
                    {multiLanguage[item.content][currentLanguage]}
                  </Content>
                </Link>
              </div>
            </TabContent>
          ))}
        </SwipeableViews>
      </SliderWrapper>
      <SliderDots>
        {contents.map((item, idx) => (
          <li
            className={index === idx ? 'active' : ''}
            key={item.number}
            onClick={() => handleChangeIndex(idx)}
          />
        ))}
      </SliderDots>
    </Container>
  );
};

export default GuideMobile;
