import React, { useState } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { CheckCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { FlexColCenter } from '../../styles/commonStyle';
import { device } from '../../styles/mediaQueries';
import close from '../../assets/pop-up-close-icon.png';

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  ${FlexColCenter}
  align-items: center;
  z-index: 70;
  section {
    width: 535px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    div {
      cursor: pointer;
    }
    div:first-of-type {
      display: flex;
      align-items: center;
      figure {
        margin-left: 10px;
        font-size: 1rem;
      }
    }
  }
  @media ${device.mobile} {
    section {
      width: 59vw;
    }
    div img {
      width: 7vw;
    }
    main img {
      width: 89vw;
    }
  }
`;
const ButtonContainer = styled.div`
  svg {
    font-size: 35px;
    color: #ffffff;
    @media ${device.mobile} {
      font-size: 5vw;
    }
  }
`;
const timestamp = new Date().getTime();
const expire = (checkedCheckBox) =>
  timestamp + (checkedCheckBox ? 60 * 60 * 24 * 1000 * 1 : 3600000);
const PopUp = ({ image, onShowPopup }) => {
  const [checkedCheckBox, setCheckedCheckBox] = useState(false);
  const [, setCookie] = useCookies(['pandaPopup']);

  const handleControlPopup = () => {
    setCookie('pandaPopup', 'yes', {
      expires: new Date(expire(checkedCheckBox)),
    });
    onShowPopup(false);
  };
  return (
    <Container>
      <section>
        <ButtonContainer onClick={() => setCheckedCheckBox(!checkedCheckBox)}>
          {checkedCheckBox ? <CheckCircleFilled /> : <CheckCircleOutlined />}
          <figure>오늘 하루 보지않기</figure>
        </ButtonContainer>
        <div onClick={handleControlPopup}>
          <img src={close} alt='close icon' />
        </div>
      </section>
      <main>
        {image[0].linkUrl ? (
          <a href={image[0].linkUrl} alt='pop link'>
            <img src={image[0].url} alt='popup' />
          </a>
        ) : (
          <img src={image[0].url} alt='popup' />
        )}
      </main>
    </Container>
  );
};

export default PopUp;
