import styled from 'styled-components';
import { Layout, Divider } from 'antd';
import { device } from './mediaQueries';
import { FontWeightMedium } from './commonStyle';

export const FooterWrapper = styled(Layout.Footer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.toTablet} {
    padding: 0 4.2vw;
    text-align: center;
    font-size: 0.71875rem;
    background-color: #f7f7f7;
    padding-bottom: 5.0625rem;
  }
  @media ${device.desktop} {
    text-align: left;
    padding-top: 3.5625rem;
    flex-direction: row;
    align-items: flex-start;
    background-color: ${(props) => props.theme.darkGray};
  }
`;

export const FooterLogo = styled.img`
  object-fit: contain;
  margin-right: 4rem;
`;

export const FooterTextUl = styled.ul``;

export const FooterTextWrapper = styled.div`
  ${FooterTextUl}:nth-child(2) {
    margin-top: 1rem;
  }
`;

export const FooterTextLi = styled.li`
  line-height: 1.5;
  color: ${(props) => props.theme.gray};
  svg {
    fill: ${(props) => props.theme.lightGray};
  }
  &.black {
    color: ${({ theme }) => theme.black};
  }
  @media ${device.desktop} {
    line-height: 1.625rem;
  }
`;

export const FooterDivider = styled(Divider)`
  margin: 0 50px;
  height: 120px;
  background-color: ${(props) => props.theme.gray};
`;

export const MobileFamilySite = styled.div`
  width: 100%;
  min-height: 48px;
  margin-bottom: 48px;
  ${FontWeightMedium}
  div {
    cursor: pointer;
    font-size: 0.8125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    overflow: hidden;
    border-bottom: solid 1px #eaeaea;
    &.bold {
      font-weight: 600;
    }
    &.hide {
      height: 0;
    }
    &.show {
      height: 48px;
    }
    transition: height 0.4s;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: 48px;
    font-weight: 400;
  }
`;
