import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useWindowPosition from '../hooks/useWindowPosition';

import Alibaba from '../components/information/Alibaba';
import Taobao from '../components/information/Taobao';
import Tmall from '../components/information/Tmall';
import TmallDetail from '../components/information/TmallDetail';
import AlibabaDetail from '../components/information/AlibabaDetail';
import { handleGetData } from '../action/api';

const Information = () => {
  const { currentLanguage } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  let { path } = useRouteMatch();
  const { moveYScroll } = useWindowPosition();
  React.useEffect(() => {
    moveYScroll();
  }, [moveYScroll]);

  useEffect(() => {
    (async () => {
      try {
        const params = { PK: 'guide', type: 'tmall', language: 'KR' };
        const alibabaParams = { ...params, type: 'alibaba' };
        const [tmall, alibaba] = await Promise.all([
          handleGetData('getGuideList', { params }),
          handleGetData('getGuideList', { params: alibabaParams }),
        ]);

        if (tmall.Items)
          dispatch({
            type: 'SET_TMALL_LIST',
            payload: tmall.Items,
          });
        if (alibaba.Items)
          dispatch({
            type: 'SET_ALIBABA_LIST',
            payload: alibaba.Items,
          });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch]);
  return (
    <Switch>
      <Route exact path={`${path}/tmall`}>
        <Tmall currentLanguage={currentLanguage} />
      </Route>
      <Route exact path={`${path}/tmall/:contentId`}>
        <TmallDetail currentLanguage={currentLanguage} />
      </Route>
      <Route exact path={`${path}/taobao`}>
        <Taobao currentLanguage={currentLanguage} />
      </Route>
      {/* <Route exact path={`${path}/taobao/:contentId`}>
        <TaobaoDetail />
      </Route> */}
      <Route exact path={`${path}/alibaba`}>
        <Alibaba currentLanguage={currentLanguage} />
      </Route>
      <Route exact path={`${path}/alibaba/:contentId`}>
        <AlibabaDetail currentLanguage={currentLanguage} />
      </Route>
    </Switch>
  );
};

export default Information;
