import { convertLocalDate } from '../helper/common';

const tableColums = [
  {
    title: 'Number',
    dataIndex: 'key',
    key: 'key',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text) => convertLocalDate(text),
  },
];

export default tableColums;
