import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Text from '../texts/Text';
import CommonButton from '../buttons/CommonButton';
import { WrapperWidth1240 } from '../../styles/commonStyle';
import { device } from '../../styles/mediaQueries';
import { convertLocalDate } from '../../helper/common';
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  overflow-x: hidden;
  ${WrapperWidth1240}

  @media ${device.toTablet} {
    main {
      display: flex;
      height: 365px;
      margin-left: 4.2vw;
      transform: translateX(${(props) => props.translateValue}px);
      transition: transform ease-in-out 1s;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      margin: 16px 32px 16px 16px;
    }
  }
  @media ${device.desktop} {
    padding: 50px 0;
    main {
      display: flex;
      height: 440px;
      margin-bottom: 20px;
      transform: translateX(${(props) => props.translateValue}px);
      transition: transform ease-in-out 1s;
    }
    .buttons {
      position: absolute;
      bottom: 19%;
      left: 300px;
    }
  }
`;

const Card = styled.div`
  cursor: pointer;
  margin-right: 10px;
  padding: 30px;
  width: 320px;
  min-width: 320px;
  height: 364px;
  background-color: #ffffff;
  overflow: hidden;
  p {
    padding-top: 20px;
  }
  p.content {
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 1.5;
  }

  @media ${device.desktop} {
    margin-right: 20px;
    padding: 30px;
    width: 280px;
    min-width: 280px;
    height: 330px;
    min-height: 330px;
    border-top: solid 2px red;
    background-color: #f9f9f9;
    overflow: hidden;
    transition-property: min-height;
    transition-duration: 0.5s;
    transition-delay: 1s;
    transition-timing-function: linear;
    p {
      padding-top: 20px;
    }
    p.content {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* 라인수 */
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      line-height: 1.5;
      min-height: 145px;
    }
    &.active {
      min-height: 440px;
      color: #ffffff;
      background-color: ${({ theme }) => theme.red};
      p.content {
        min-height: 255px;
      }
    }
  }
`;

export const Button = styled.button`
  width: 50px;
  height: 50px;
  border: 1px solid ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.white};
    border-color: ${({ theme }) => theme.red};
    background-color: ${({ theme }) => theme.red};
  }

  @media ${device.desktop} {
    width: 60px;
    height: 60px;
    :first-child {
      margin-right: 10px;
    }
  }
`;

const PandaNews = ({ data, isDesktop, width }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [translateValue, setTranslateValue] = useState(0);
  const history = useHistory();

  const goToPrevSilde = () => {
    if (activeIndex === 0) return;

    setActiveIndex(activeIndex - 1);
    setTranslateValue(translateValue + (isDesktop ? 300 : width - 25));
  };
  const goToNextSlide = () => {
    if (activeIndex === data.length - 1) {
      setActiveIndex(0);
      setTranslateValue(0);
      return;
    }

    setActiveIndex(activeIndex + 1);
    setTranslateValue(translateValue - (isDesktop ? 300 : width - 25));
  };

  const handleGoToDetail = (item) => {
    const { content, SK, imageUrl } = item;

    if (content || (imageUrl && imageUrl.length > 0)) {
      return history.push(`/company/news/${SK}#tab`);
    }
    return false;
  };
  return (
    <Wrapper translateValue={translateValue}>
      <main>
        {data.length > 0 &&
          data.map((item, index) => (
            <Card
              key={item.key}
              className={index === activeIndex ? 'active' : ''}
              onClick={() => handleGoToDetail(item)}
            >
              <Text fontSize={'1rem'}>{convertLocalDate(item.date)}</Text>
              <Text fontSize={'1rem'} strong>
                {item.title}
              </Text>
              <p className='content'>{item.content}</p>
            </Card>
          ))}
      </main>

      <div className='buttons'>
        <Button onClick={goToPrevSilde}>
          <LeftOutlined />
        </Button>
        <Button onClick={goToNextSlide}>
          <RightOutlined />
        </Button>
      </div>
      {isDesktop && (
        <div className='pd-flex-center'>
          <CommonButton onClick={() => history.push('/company/news#tab')}>
            View More
          </CommonButton>
        </div>
      )}
    </Wrapper>
  );
};

export default PandaNews;
