import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';
import WithTopDivider from '../common/WithTopDivider';
import ContactUs from '../main/ContactUs';
import PathDotItem from './components/PathDotItem';
import SubHeading from '../texts/SubHeading';
import PortfolioSlide from './components/online/PortfolioSlide';
import multiLanguage from '../../assets/multiLanguage.json';
import { PROVIDE_LIST } from '../../data/constants';

import { ContactUsWrapper } from '../../styles/MainContent';
import { device } from '../../styles/mediaQueries';
import {
  FlexColCenter,
  WrapperWidth,
  FontWeightMedium,
  FontWeightNormal,
  FontWeightDemiLite,
  ColorTextGray,
  BackgroundRed,
  BackgroundLightRed,
  BackgroundLightGray,
  ColorTextRed,
} from '../../styles/commonStyle';

import online1 from '../../assets/business/online/online-image-01.png';
import online2 from '../../assets/business/online/online-image-02.png';
import online2M from '../../assets/business/online/online-image-m-02.png';
import online3 from '../../assets/business/online/online-image-03.png';
import online3M from '../../assets/business/online/online-image-m-03.png';
import online4 from '../../assets/business/online/online-image-04.png';
import online1Icon1 from '../../assets/business/online/online-icon-01-01.png';
import online1Icon2 from '../../assets/business/online/online-icon-01-02.png';
import online1Icon3 from '../../assets/business/online/online-icon-01-03.png';
import online1Icon4 from '../../assets/business/online/online-icon-01-04.png';
import online1Icon from '../../assets/business/online/online-icon-02-01.png';
import online2Icon from '../../assets/business/online/online-icon-02-02.png';
import online3Icon from '../../assets/business/online/online-icon-02-03.png';
import online4Icon from '../../assets/business/online/online-icon-02-04.png';
import online5Icon from '../../assets/business/online/online-icon-02-05.png';
import online6Icon from '../../assets/business/online/online-icon-02-06.png';
import online7Icon from '../../assets/business/online/online-icon-02-07.png';
import online8Icon from '../../assets/business/online/online-icon-02-08.png';
import ContentBox from './components/ContentBox';
import Arrow from '../common/Arrow';
import { css } from 'styled-components';

const Container = styled.div`
  ${FlexColCenter};
`;

const ServiceWrapper = styled.section`
  ${FlexColCenter};
  margin-bottom: 100px;
  @media ${device.desktop} {
    margin-bottom: 230px;
  }
`;
const mobileOdd = css`
  left: -31.5%;
  align-items: flex-end;
  * {
    text-align: right;
  }
`;
const ServiceContent = styled.article`
  width: 196.5px;
  height: 413.5px;
  position: relative;
  background: url(${online2M}) no-repeat top center;
  background-size: contain;
  h1 {
    text-align: center;

    font-size: 0.75rem;
    line-height: 1.3;
  }
  div:not(:first-child) {
    position: absolute;
  }

  span {
    ${FontWeightNormal}
  }
  @media ${device.toTablet} {
    div:first-child {
      width: 200px;
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:nth-child(2) {
      top: 20.5%;
      left: 68.5%;
      align-items: flex-start;
    }
    div:nth-child(3) {
      top: 40%;
      ${mobileOdd}
    }
    div:nth-child(4) {
      top: 57%;
      left: 68.5%;
      align-items: flex-start;
    }
    div:last-child {
      top: 77.5%;
      ${mobileOdd}
    }
  }

  @media ${device.desktop} {
    width: 1432px;
    height: 305px;
    margin: 100px 0 130px;
    position: relative;
    background: url(${online2}) no-repeat center;
    h1 {
      font-size: 1.5rem;
    }

    div:first-child {
      width: 300px;
      height: 305px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:nth-child(2) {
      top: -32%;
      left: 27%;
    }
    div:nth-child(3) {
      top: 78%;
      left: 44.5%;
    }
    div:nth-child(4) {
      top: ${({ currentLanguage }) => (currentLanguage === 'EN' ? -37 : -32)}%;
      left: 64.5%;
    }
    div:last-child {
      top: 78%;
      left: 83.5%;
    }
    span {
      ${FontWeightNormal}
    }
  }
`;

const ServiceItem = styled.div`
  ${FlexColCenter}
  img {
    height: 22px;
  }
  h6 {
    margin: 10px 0;
    font-size: 0.75rem;
  }
  p {
    width: 124px;
    font-size: 0.625rem;
    ${ColorTextGray}
  }
  @media ${device.desktop} {
    img {
      height: auto;
    }
    h6 {
      margin: 20px 0;
      font-size: 1.25rem;
    }
    p {
      width: 210px;
      font-size: 1rem;
      text-align: center;
    }
  }
`;

const ServiceProcessContainer = styled.section`
  width: 100%;
  ${FlexColCenter}

  @media ${device.desktop} {
    padding: 70px 0;
    ${BackgroundLightRed}
  }
`;
const ServiceProcessWrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 13px 1fr;
  gap: 70px 30px;
  @media ${device.desktop} {
    grid-template-columns: 1fr repeat(3, 17px 1fr);
    gap: 75px;
    main {
      margin-left: 85px;
    }
  }
`;

const StrongPointContainer = styled.div`
  ${FlexColCenter};
  margin: 196px 0 400px;
  @media ${device.toTablet} {
    margin: 138px 0 150px;
    p {
      font-size: 0.75rem;
      ${FontWeightDemiLite}
    }
  }
`;

const StrongPoint = styled.div`
  position: relative;
  margin-top: 95px;
  width: 90px;
  height: 464px;
  background: url(${online3M}) center no-repeat;
  background-size: contain;
  h3 {
    font-size: 0.75rem;
    ${FontWeightMedium};
    line-height: 2;
  }
  div {
    position: absolute;
    width: 156px;
    word-break: keep-all;
    p {
      font-size: 0.625rem;
      ${FontWeightDemiLite}
    }
  }
  div:nth-child(1) {
    top: -4%;
    left: -123%;
    text-align: right;
  }
  div:nth-child(2) {
    top: 18%;
    left: 64%;
  }
  div:nth-child(3) {
    top: 41%;
    left: -120%;
    text-align: right;
  }
  div:nth-child(4) {
    top: 67%;
    left: 75%;
  }
  div:nth-child(5) {
    top: 93%;
    left: -133%;
    text-align: right;
  }
  @media ${device.desktop} {
    position: relative;
    width: 1314px;
    height: 304px;
    margin: 121px 0 100px;
    background: url(${online3}) center no-repeat;
    h3 {
      font-size: 1.125rem;
      text-align: center;
    }
    div {
      width: 255px;
      p {
        font-size: 1rem;
        text-align: center;
      }
    }
    div:nth-child(1) {
      top: 22%;
      left: -16%;
      & > * {
        text-align: right;
      }
    }
    div:nth-child(2) {
      top: 92%;
      left: 16%;
    }
    div:nth-child(3) {
      top: 47%;
      left: 43%;
    }
    div:nth-child(4) {
      top: 62%;
      left: 65%;
    }
    div:nth-child(5) {
      top: 32%;
      left: 96%;
      & > * {
        text-align: left;
      }
    }
  }
`;

const PartnerServiceContainer = styled.section`
  ${FlexColCenter}
  @media ${device.toTablet} {
    padding: 0 4.2vw;
    p {
      width: 180px;
      text-align: center;
      font-size: 0.8rem;
      ${FontWeightDemiLite}
    }
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 36px;
  ul {
    padding: 20px 80px;
    ${BackgroundLightGray}
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 35px;
      ${FontWeightDemiLite}
      line-height: 1.5;
    }
    li:first-child {
      margin-bottom: 4px;
      ${FontWeightMedium};
      font-size: 1rem;
      ${ColorTextGray}
      border-bottom: 1px solid ${({ theme }) => theme.textGray};
    }
  }
  ul:last-child {
    margin-top: 20px;
    ${BackgroundLightRed}
    li:first-child {
      ${FontWeightMedium};
      ${ColorTextRed}
      border-bottom: 1px solid ${({ theme }) => theme.textRed};
    }
  }

  @media ${device.toTablet} {
    li {
      font-size: 0.625rem;
    }
  }
  @media ${device.desktop} {
    flex-direction: row;
    margin: 47px 0 100px;
    padding: 0;
    ul {
      padding: 59px 145px;
      li {
        width: 300px;
        height: 52px;
      }
      li:first-child {
        font-size: 1.125rem;
      }
    }
    ul:last-child {
      margin: 0 0 0 12px;
    }
  }
`;

const ProvideContainer = styled.div`
  ${WrapperWidth};
  ${FlexColCenter}
  aside p {
    ${ColorTextRed}
  }
  main {
    display: grid;
    div::after {
      content: '';
      display: block;
    }
  }
  @media ${device.toTablet} {
    aside {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      p {
        text-align: right;
        font-size: 0.75rem;
        ${FontWeightMedium}
      }
      img {
        width: 92px;
      }
    }
    main {
      width: 315px;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(4, 35px);
      padding: 40px 0 40px 20px;
      * {
        font-size: 0.5rem;
      }
      div::after {
        width: 8px;
        margin-top: 5px;
        border-bottom: solid 1px #dedede;
      }
    }
  }
  @media ${device.desktop} {
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 378px;
    height: 370px;
    aside {
      position: relative;
      width: 400px;
      p {
        font-size: 1.5rem;
        margin-top: 74.6px;
        margin-left: 55px;
      }
      img {
        position: absolute;
        height: auto;
        bottom: 62px;
        right: 52px;
      }
    }

    main {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: repeat(4, 73px);
      justify-items: start;
      align-items: baseline;
      width: 779px;
      padding: 60px 72px 0 85px;
      font-size: 1rem;
      div {
        text-align: left;
        ::after {
          width: 30px;
          margin-top: 10px;
          border-bottom: solid 2px #dedede;
        }
        &:hover {
          ::after {
            width: 100%;
            border-bottom: solid 2px ${({ theme }) => theme.red};
            transition: all 0.3s;
          }
        }
      }
    }
  }
`;

const StoreContainer = styled.section`
  width: 100%;
  padding: 25px 0 107px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.lightRed} 54%,
    #ffffff 38%
  );
  z-index: 0;
  @media ${device.desktop} {
    padding: 97px 0 400px;
    background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.lightRed} 38%,
      #ffffff 38%
    );
  }
`;

const StrongPointItem = ({ title, text }) => (
  <div>
    <h3>{title}</h3>
    <p>{text}</p>
  </div>
);
const serviceProcess = [
  { title: '티몰 시장조사', text: '티몰 시장조사 DESC', icon: online1Icon },
  { title: '입점 개설', text: '입점 개설 DESC', icon: online2Icon },
  { title: '컨텐츠 제작', text: '컨텐츠 제작 DESC', icon: online3Icon },
  { title: '티몰 샵 디자인', text: '티몰 샵 디자인 DESC', icon: online4Icon },
  { title: '운영/마케팅', text: '운영/마케팅 DESC', icon: online5Icon },
  { title: '고객 CS', text: '고객 CS DESC', icon: online6Icon },
  { title: '데이터 분석', text: '데이터 분석 DESC', icon: online7Icon },
  { title: '물류 배송', text: '물류 배송 DESC', icon: online8Icon },
];
const Online = ({ currentLanguage, isDesktop }) => {
  return (
    <Container>
      <Row style={{ position: 'relative', width: '100%' }} justify='center'>
        <ContentBox
          margin={{
            marginTop: isDesktop ? 0 : 30,
            marginBottom: isDesktop ? 250 : 100,
          }}
          titleBold
          mobileCenter
          mobileTextCenter
          text={
            multiLanguage['티몰글로벌 입점 및 운영대행 매장관리부터'][
              currentLanguage
            ]
          }
          bigImg={online1}
        >
          {multiLanguage['중국 온라인 운영대행Styled'][currentLanguage]}
        </ContentBox>
      </Row>
      <Row justify='center'>
        <ServiceWrapper>
          <WithTopDivider marginBottom={isDesktop ? '160px' : '21px'}>
            <SubHeading fontSize={'1.5625rem'}>
              {multiLanguage['운영대행 서비스'][currentLanguage]}
            </SubHeading>
          </WithTopDivider>
          <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
            <ServiceContent currentLanguage={currentLanguage}>
              <div>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: `${multiLanguage['중국 시장에 대한 막연한 두려움'][currentLanguage]}`,
                  }}
                />
              </div>
              <ServiceItem>
                <img src={online1Icon1} alt='service item icon' />
                <h6>{multiLanguage['정보 부족'][currentLanguage]}</h6>
                <p>
                  {
                    multiLanguage[
                      '중국 시장에 대한 잘못된 정보와 이해 부족이 중국진출의 어려운 가장 큰 이유입니다.'
                    ][currentLanguage]
                  }
                </p>
              </ServiceItem>
              <ServiceItem>
                <img src={online1Icon2} alt='service item icon' />
                <h6>{multiLanguage['마케팅 효과'][currentLanguage]}</h6>
                <p>
                  {
                    multiLanguage[
                      '다양한 방법을 연구하고 시도해보지만 실질적인 효과를 내기 어렵고 판매까지 이뤄지기는 더욱 힘듭니다.'
                    ][currentLanguage]
                  }
                </p>
              </ServiceItem>
              <ServiceItem>
                <img src={online1Icon3} alt='service item icon' />
                <h6>{multiLanguage['운영 난이도'][currentLanguage]}</h6>
                <p>
                  {
                    multiLanguage[
                      '국내 상거래와 비교해 훨씬 높은 난이도를 가지고 있어 쉽게 진입할 수 있는 시장이 아닙니다.'
                    ][currentLanguage]
                  }
                </p>
              </ServiceItem>
              <ServiceItem>
                <img src={online1Icon4} alt='service item icon' />
                <h6>{multiLanguage['인력 관리'][currentLanguage]}</h6>
                <p>
                  {
                    multiLanguage[
                      '중국 온라인 전문 인력 채용이 어렵고 조직 구성, 직원관리가 부담됩니다.'
                    ][currentLanguage]
                  }
                </p>
              </ServiceItem>
            </ServiceContent>
          </ScrollAnimation>
        </ServiceWrapper>
      </Row>
      <ServiceProcessContainer>
        <WithTopDivider marginBottom={'67px'}>
          <SubHeading fontSize={'1.5625rem'}>SERVICE PROCESS</SubHeading>
        </WithTopDivider>
        <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
          <ServiceProcessWrapper>
            {serviceProcess.map(({ title, text, icon }, index) => (
              <Fragment key={title}>
                <PathDotItem
                  title={multiLanguage[title][currentLanguage]}
                  text={multiLanguage[text][currentLanguage]}
                  icon={icon}
                  mobileCol={!isDesktop}
                />
                {isDesktop
                  ? index !== 3 && index !== 7 && <Arrow />
                  : !(index % 2) && <Arrow degree={45} />}
              </Fragment>
            ))}
          </ServiceProcessWrapper>
        </ScrollAnimation>
      </ServiceProcessContainer>
      <Row justify='center'>
        <StrongPointContainer>
          <WithTopDivider marginBottom={'13px'}>
            <SubHeading fontSize={'1.5625rem'}>STRONG POINT</SubHeading>
          </WithTopDivider>
          <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
            <p>PROVIDING ONE-STOP NEW RETAILING SOLUTION SERVICE</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
            <StrongPoint>
              {multiLanguage['strongPoint'].map((item) => (
                <StrongPointItem
                  key={item[0][currentLanguage]}
                  title={item[0][currentLanguage]}
                  text={item[1][currentLanguage]}
                />
              ))}
            </StrongPoint>
          </ScrollAnimation>
        </StrongPointContainer>
      </Row>
      <PartnerServiceContainer>
        <WithTopDivider marginBottom={'13px'}>
          <SubHeading fontSize={'1.5625rem'}>
            <span
              dangerouslySetInnerHTML={{
                __html: `${multiLanguage['파트너쉽 서비스 제공'][currentLanguage]}`,
              }}
            />
          </SubHeading>
        </WithTopDivider>
        <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
          <p>
            {
              multiLanguage[
                '용역 판매가 아닌 파트너쉽으로 단기간이 아닌 장기간 상생을 목표'
              ][currentLanguage]
            }
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
          <TableWrapper>
            <ul>
              <li>{multiLanguage['브랜드사 직접 운영'][currentLanguage]}</li>
              <li>
                {
                  multiLanguage['월 1,600만원 선 최소 5~6명 전문 인력 투입'][
                    currentLanguage
                  ]
                }
              </li>
              <li>
                {
                  multiLanguage['커뮤니케이션 문제(언어, 문화)'][
                    currentLanguage
                  ]
                }
              </li>
              <li>
                {
                  multiLanguage['적극적인 관여의 부재 상황 자주 발생'][
                    currentLanguage
                  ]
                }
              </li>
              <li>
                {
                  multiLanguage['경험 부족, 진행 과정마다 운영 이슈 발생'][
                    currentLanguage
                  ]
                }
              </li>
              <li>
                {
                  multiLanguage['급변하는 중국 온라인 마케팅 이해 부족'][
                    currentLanguage
                  ]
                }
              </li>
              <li>
                {
                  multiLanguage['해외 배송 및 재고관리에 대한 부담'][
                    currentLanguage
                  ]
                }
              </li>
            </ul>
            <ul>
              <li>{multiLanguage['판다코리아 운영 대행'][currentLanguage]}</li>
              <li>
                {
                  multiLanguage['2명정도의 인건비 수준, 조직 구성 부담 해소'][
                    currentLanguage
                  ]
                }
              </li>
              <li>
                {
                  multiLanguage['발 빠른 커뮤니케이션, 담당 인원 배정'][
                    currentLanguage
                  ]
                }
              </li>
              <li>{multiLanguage['즉각 대응, 즉시 반영'][currentLanguage]}</li>
              <li>
                {
                  multiLanguage['전문적 관리, 운영상 이슈 최소화'][
                    currentLanguage
                  ]
                }
              </li>
              <li>
                {
                  multiLanguage['정확하고 최적화한 마케팅 전략 제공'][
                    currentLanguage
                  ]
                }
              </li>
              <li>{multiLanguage['물류&창고 서비스 제공'][currentLanguage]}</li>
            </ul>
          </TableWrapper>
        </ScrollAnimation>
      </PartnerServiceContainer>
      <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
        <Row justify='center' align='middle'>
          <ProvideContainer>
            <aside>
              <p>
                We Provide
                <br />
                For you
              </p>
              <img src={online4} alt='background' />
            </aside>
            <main>
              {PROVIDE_LIST.map((item, index) => {
                return (
                  <div key={`step${index + 1}`}>
                    {multiLanguage[item[`step${index + 1}`]][currentLanguage]}
                  </div>
                );
              })}
            </main>
          </ProvideContainer>
        </Row>
      </ScrollAnimation>
      <StoreContainer>
        <WithTopDivider marginBottom={'67px'}>
          <SubHeading fontSize={'1.5625rem'}>
            {multiLanguage['운영중인 글로벌 스토어'][currentLanguage]}
          </SubHeading>
        </WithTopDivider>
        <PortfolioSlide />
      </StoreContainer>
      <ContactUsWrapper>
        <ContactUs currentLanguage={currentLanguage} isDesktop={isDesktop} />
      </ContactUsWrapper>
    </Container>
  );
};

export default Online;
