import React from 'react';
import { Row } from 'antd';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';
import ImageWIthBox from '../../common/ImageWIthBox';
import CompanyContentText from '../../texts/CompanyContentText';

import Image1 from '../../../assets/company/about/introduce-1.png';
import Image2 from '../../../assets/company/about/introduce-2.png';
import Image3 from '../../../assets/company/about/introduce-3.png';
import Image4 from '../../../assets/company/about/introduce-4.png';

import multiLanguage from '../../../assets/multiLanguage.json';
import { device } from '../../../styles/mediaQueries';

const paddingUnit = '7.2vw';
const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 52.2px;
  @media ${device.desktop} {
    padding-bottom: 250px;
  }
`;
const Wrapper = styled(Row)`
  display: flex;
  position: relative;
  padding-bottom: 100px;
  ${({ $right }) =>
    $right &&
    css`
      flex-direction: row-reverse;
    `}
  @media ${device.toTablet} {
    padding: 0 4.2vw 60px;
    ${({ $right }) =>
      $right &&
      css`
        text-align: right;
      `}
  }
`;

const BackgroundGray = styled.div`
  @media ${device.desktop} {
    position: absolute;
    bottom: ${({ fromBottom }) => fromBottom};
    width: 100%;
    height: ${({ height }) => height};
    background-color: #f7f7f7;
    z-index: -1;
  }
`;

const CompanyContent = () => {
  const { currentLanguage } = useSelector((state) => state.global);

  return (
    <Container>
      <Wrapper align='middle'>
        <ImageWIthBox
          image={Image1}
          boxStyle={{
            backgroundColor: '#f7f7f7',
            width: 150,
            height: 225,
            margin: '-40px 0 0 -40px',
          }}
          style={{ paddingRight: paddingUnit }}
        />
        <ScrollAnimation
          animateIn='fadeInRight'
          duration={1}
          animateOnce={true}
        >
          <CompanyContentText
            number='01'
            mainText={multiLanguage['쉽고 싸게 직접'][currentLanguage]}
            subText='(Easily and cheaply directly)'
            textHeading={
              multiLanguage[
                '중소기업들의 중국 진출에 대한 높은 진입 장벽을 허물다.'
              ][currentLanguage]
            }
            text={multiLanguage['판다코리아닷컴은 DESC'][currentLanguage]}
          />
        </ScrollAnimation>
      </Wrapper>
      <BackgroundGray fromBottom='50%' height='825px' />
      <Wrapper $right align='middle'>
        <ImageWIthBox
          right
          image={Image2}
          boxStyle={{
            border: '1px solid #000000',
            width: 220,
            height: 220,
            alignSelf: 'start',
            justifySelf: 'end',
            margin: '-40px -40px 0 0',
          }}
          style={{ paddingLeft: paddingUnit }}
        />
        <ScrollAnimation animateIn='fadeInLeft' duration={1} animateOnce={true}>
          <CompanyContentText
            right
            number='02'
            mainText={multiLanguage['한류'][currentLanguage]}
            subText='(Korean Wave)'
            textHeading={
              multiLanguage['최초의 엔터커머스 사업모델을 선보이다.'][
                currentLanguage
              ]
            }
            text={
              multiLanguage['초창기 우수한 국내외 인재들이 DESC'][
                currentLanguage
              ]
            }
          />
        </ScrollAnimation>
      </Wrapper>
      <Wrapper align='middle'>
        <ImageWIthBox
          image={Image3}
          boxStyle={{
            backgroundColor: '#f7f7f7',
            width: 230,
            height: 230,
            alignSelf: 'end',
            margin: '0 0 -40px -40px',
          }}
          style={{ paddingRight: paddingUnit }}
        />
        <ScrollAnimation
          animateIn='fadeInRight'
          duration={1}
          animateOnce={true}
        >
          <CompanyContentText
            number='03'
            mainText={multiLanguage['현지화'][currentLanguage] + ' '}
            subText='(Localization)'
            textHeading={
              multiLanguage['판다코리아닷컴의 경쟁력은 철저한 현지화'][
                currentLanguage
              ]
            }
            text={
              multiLanguage['철저한 현지화가 우리의 경쟁력입니다. DESC'][
                currentLanguage
              ]
            }
          />
        </ScrollAnimation>
      </Wrapper>
      <BackgroundGray fromBottom='0' height='698px' />
      <Wrapper $right align='middle' style={{ paddingBottom: 0 }}>
        <ImageWIthBox
          right
          image={Image4}
          boxStyle={{
            width: 250,
            height: 440,
            alignSelf: 'start',
            justifySelf: 'end',
            margin: '-40px -40px 0 0',
            backgroundColor: '#f9f1f1',
          }}
          style={{ paddingLeft: paddingUnit }}
        />
        <ScrollAnimation animateIn='fadeInLeft' duration={1} animateOnce={true}>
          <CompanyContentText
            right
            number='04'
            mainText={multiLanguage['지속적인 성장'][currentLanguage] + ' '}
            subText='(Sustained growth)'
            textHeading={
              multiLanguage['B2B2C의 옴니채널로 거듭나다'][currentLanguage]
            }
            text={
              multiLanguage['판다코리아닷컴은 단순히 B2B·B2C가 DESC'][
                currentLanguage
              ]
            }
          />
        </ScrollAnimation>
      </Wrapper>
    </Container>
  );
};

export default CompanyContent;
