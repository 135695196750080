import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Form, Input, Select, Checkbox } from 'antd';
import CommonButton from '../buttons/CommonButton';
import ConfirmModal from '../common/ConfirmModal';
import MainTitle from '../texts/MainTitle';
import Text from '../texts/Text';

import { device } from '../../styles/mediaQueries';
import { WrapperWidth1240 } from '../../styles/commonStyle';

import downLoadIcon from '../../assets/main/download-icon.png';
import downLoadIconBlack from '../../assets/company/ci/download-black-icon.png';

import multiLanguage from '../../assets/multiLanguage.json';
import { handleSendMessage } from '../../action/api';
import { URL } from '../../data/constants';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${WrapperWidth1240}

  h1 {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  div {
    flex: 1;
    p {
      width: 300px;
    }
  }
  .ant-form-item-label > label {
    color: ${({ color, theme }) => theme[color]};
    font-weight: ${({ theme }) => theme.bold};
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    ::before {
      display: none;
    }
    ::after {
      display: inline-block;
      margin-right: 4px;
      color: ${({ color, theme }) => theme[color]};
      font-size: 14px;
      line-height: 1;
      content: '*';
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: ${({ color, theme }) => theme[color]};
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: ${({ color, theme }) =>
      color === 'white' ? theme.black : theme.red};
  }

  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    background: transparent;
    border-color: ${({ color, theme }) =>
      color === 'white' ? theme.black : theme.red} !important;
    color: ${({ color, theme }) =>
      color === 'white' ? theme.black : theme.red};
  }

  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background: transparent;
    border-color: ${({ color, theme }) =>
      color === 'white' ? theme.black : theme.red};
    color: ${({ color, theme }) =>
      color === 'white' ? theme.black : theme.red};
  }

  .ant-input {
    color: ${({ color, theme }) => theme[color]};
    background-color: transparent;

    ::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
    :hover {
      background-color: transparent;
    }
  }

  .ant-checkbox-inner {
    background-color: ${({ color }) =>
      color === 'black' ? 'inherit' : 'transparent'};
    border-color: ${({ color, theme }) => theme[color]};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ color, theme }) =>
      color === 'black' ? theme.black : '#ffffff'};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${({ color, theme }) => theme[color]};
    background-color: ${({ color, theme }) =>
      color === 'black' ? theme.red : 'transparent'};
    color: ${({ color, theme }) => theme[color]};
  }

  .text-bold {
    color: ${({ color, theme }) => theme[color]};
    font-weight: ${({ theme }) => theme.bold};
  }

  .extra-text {
    color: ${({ color, theme }) => theme[color]};
  }
  @media ${device.toTablet} {
    padding: 15vw 4.2vw 4.2vw;
  }
  @media ${device.desktop} {
    flex-direction: row;
    padding: unset;
    h1 {
      justify-content: unset;
      margin-bottom: unset;
    }
    div {
      min-width: 600px;
    }
    div > p {
      width: unset;
    }
  }
`;

const MobileSendButton = styled.button`
  width: 100%;
  font-weight: 600;
  padding: 2vw 0;
  color: ${({ theme, black }) => (black ? theme.black : theme.red)};
  ${({ black, theme }) =>
    black &&
    css`
      border: solid 1px ${theme.black};
    `}
  background-color: #ffffff;
`;

const IconImage = styled.figure`
  margin-left: 1.4375rem;
  height: 25px;
  width: 25px;
  background: ${({ black }) =>
    black ? `url(${downLoadIconBlack})` : `url(${downLoadIcon})`};
  background-repeat: no-repeat;
`;

const DownLoadButton = styled.button`
  height: 2.64375rem;
  font-size: 13px;
  border-radius: 0.625rem;
  background-color: transparent;
  border: solid 1px
    ${({ color, theme }) => (color ? theme[color] : theme.black)};
  & > * {
    color: ${({ color, theme }) => (color ? theme[color] : theme.black)};
  }
  :hover {
    & > * {
      color: #ffffff;
    }
    background-color: ${({ theme }) => theme.red};
    border-color: #ffffff;
    ${IconImage} {
      background: url(${downLoadIcon});
    }
  }
  a {
    padding: 0 15px;
    line-height: 25px;
    display: flex;
    justify-content: center;
  }
`;

const MobileDownLoadWrapper = styled.div`
  color: #ffffff;
  font-size: 0.9375rem;
  p {
    line-height: 2;
    color: ${({ color, theme }) => (color ? theme[color] : theme.black)};
  }
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.9vw 4vw;
    color: ${({ color, theme }) => (color ? theme[color] : theme.black)};
    border: solid 1px
      ${({ color, theme }) => (color ? theme[color] : theme.black)};
  }
`;

const limit = 1000;
const ContactUs = ({ black, currentLanguage, isDesktop }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const formRef = useRef();

  const formattedContent = (text) => {
    let limitedtext = text;
    if (text.length > limit) {
      limitedtext = text.slice(0, limit);
    }

    return limitedtext;
  };

  const handleFinish = async (value) => {
    const sendContent = value;
    const text = formattedContent(sendContent.inquiry);
    sendContent.inquiry = text;
    const sent = await handleSendMessage(sendContent);
    formRef.current.resetFields();
    if (sent) setVisibleModal(true); // 모달
  };
  return (
    <>
      <ContentWrapper color={black ? 'black' : 'white'}>
        <div>
          <MainTitle
            text={'contact us'}
            noIcon
            color={black ? 'black' : 'white'}
          />
          <Text
            color={black ? 'black' : 'white'}
            style={{ paddingBottom: isDesktop ? 50 : 25 }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: multiLanguage['궁금하신 사항이'][currentLanguage],
              }}
            />
          </Text>
          {isDesktop && (
            <DownLoadButton color={black ? 'black' : 'white'}>
              <a
                color={black ? 'black' : 'white'}
                href={URL.BUSINESS_PLAN}
                download
              >
                {multiLanguage['사업소개서 다운로드'][currentLanguage]}

                <IconImage black={black} />
              </a>
            </DownLoadButton>
          )}
        </div>
        <div>
          <Form layout='vertical' ref={formRef} onFinish={handleFinish}>
            <Form.Item name='inquiryType'>
              <Select
                placeholder={
                  multiLanguage['문의 종류를 선택해 주세요.'][currentLanguage]
                }
              >
                {multiLanguage['문의사항 선택'].map((item) => (
                  <Select.Option key={item[currentLanguage]} value={item.EN}>
                    {item[currentLanguage]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='companyName'
              label={multiLanguage['회사명'][currentLanguage]}
              required
              rules={[
                {
                  required: true,
                  message: `${multiLanguage['회사명은 필수 사항입니다.'][currentLanguage]}`,
                },
              ]}
            >
              <Input
                placeholder={
                  multiLanguage['회사명을 입력해주세요.'][currentLanguage]
                }
              />
            </Form.Item>
            <Form.Item
              name='email'
              label={multiLanguage['이메일'][currentLanguage]}
              rules={[
                { type: 'email', message: 'Invaild E-Mail!' },
                {
                  required: true,
                  message: `${multiLanguage['이메일은 필수 사항입니다.'][currentLanguage]}`,
                },
              ]}
            >
              <Input
                placeholder={
                  multiLanguage['이메일을 입력해주세요.'][currentLanguage]
                }
              />
            </Form.Item>
            <Form.Item
              name='telNo'
              label={multiLanguage['전화번호'][currentLanguage]}
            >
              <Input
                placeholder={
                  multiLanguage['전화번호를 입력해주세요.'][currentLanguage]
                }
              />
            </Form.Item>
            <Form.Item
              name='inquiry'
              label={multiLanguage['문의내용'][currentLanguage]}
              required
              rules={[
                {
                  required: true,
                  message: `${multiLanguage['문의내용은 필수 사항입니다.'][currentLanguage]}`,
                },
              ]}
            >
              <Input.TextArea
                limit={limit}
                rows={5}
                placeholder={
                  multiLanguage['문의내용을 입력해주세요.'][currentLanguage]
                }
              />
            </Form.Item>
            <Form.Item name='funnelsType'>
              <Select
                placeholder={
                  multiLanguage['유입 경로를 선택해 주세요.'][currentLanguage]
                }
              >
                {multiLanguage['유입경로 선택'].map((item) => (
                  <Select.Option key={item[currentLanguage]} value={item.EN}>
                    {item[currentLanguage]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='agreement'
              valuePropName='checked'
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          `${multiLanguage['개인정보수집이용에 동의 해 주세요'][currentLanguage]}`
                        ),
                },
              ]}
            >
              <Checkbox>
                <strong className='text-bold'>
                  {multiLanguage['개인정보수집이용 동의'][currentLanguage]}
                </strong>
                <p className='extra-text'>
                  {multiLanguage['해당 문의에 대한 회신 목적'][currentLanguage]}
                </p>
              </Checkbox>
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              {isDesktop ? (
                <CommonButton
                  color={black ? 'black' : 'white'}
                  htmlType='submit'
                >
                  Send
                </CommonButton>
              ) : (
                <MobileSendButton black={black} type='submit'>
                  Send
                </MobileSendButton>
              )}
            </Form.Item>
          </Form>
        </div>
        {!isDesktop && (
          <MobileDownLoadWrapper color={black ? 'black' : 'white'}>
            <p>{multiLanguage['사업소개서'][currentLanguage]}</p>
            <a href={URL.BUSINESS_PLAN} download>
              Download <IconImage black={black} />
            </a>
          </MobileDownLoadWrapper>
        )}
      </ContentWrapper>
      <ConfirmModal
        isDesktop={isDesktop}
        visible={visibleModal}
        onSetModalVisible={setVisibleModal}
      />
    </>
  );
};

export default ContactUs;
