import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import ContactUs from '../main/ContactUs';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import ContentBox from './components/ContentBox';
import SubIconWithText from '../texts/SubIconWithText';
import TotalSolutionProcess from './components/ecommerce/TotalSolutionProcess';
import ProductItem from './components/ecommerce/ProductItem';
import Management from './components/ecommerce/Management';
import CompetitiveItem from './components/ecommerce/CompetitiveItem';
import ProductIcon from './components/ecommerce/ProductIcon';

import multiLanguage from '../../assets/multiLanguage.json';
import { ContactUsWrapper } from '../../styles/MainContent';
import { device } from '../../styles/mediaQueries';
import {
  BackgroundLightRed,
  FontWeightNormal,
  FontWeightThin,
  FontWeightMedium,
} from '../../styles/commonStyle';

import eCommerce1 from '../../assets/business/ecommerce/ecommerce-img-01.png';
import directIcon from '../../assets/business/ecommerce/ecommerce-icon-01.png';
import marketingIcon from '../../assets/business/ecommerce/ecommerce-icon-02.png';
import mediaIcon from '../../assets/business/ecommerce/ecommerce-icon-03.png';
import flexibleIcon from '../../assets/business/ecommerce/ecommerce-icon-04.png';

const FONTSIZE_30 = '1.875rem';

const Wrapper = styled.main`
  @media ${device.toTablet} {
    .title {
      padding: 0 4.2vw;
      overflow-wrap: break-word;
    }
  }
`;

const SolutionWrapper = styled.div`
  width: 350px;
  display: flex;
  flex-wrap: wrap;

  @media ${device.desktop} {
    width: 1247px;
    justify-content: center;
    img {
      width: 542px;
    }
  }
`;
const ProductsWrapper = styled.div`
  position: relative;
  @media ${device.toTablet} {
  }
`;

const ChannelWrapper = styled.div`
  @media ${device.desktop} {
    width: 1180px;
    height: 655px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 300px;
  }
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${BackgroundLightRed}
  padding-bottom: 100px;
  @media ${device.desktop} {
    padding-top: 117px;
    padding-bottom: 94px;
  }
`;

const CompetitiveWrapper = styled.section`
  display: inline-grid;
  grid-template-columns: 300px;
  counter-reset: article;
  @media ${device.toTablet} {
    row-gap: 113px;
    article:nth-child(2) {
      flex-direction: row-reverse;
      p {
        text-align: right;
      }
    }
  }
  @media ${device.desktop} {
    grid-template-columns: repeat(3, 380px);
    column-gap: 19px;
  }
`;

const Text = styled.p`
  font-size: 1.0625rem;
  ${FontWeightNormal}
  text-align: center;
  word-wrap: keep-all;
  strong {
    ${FontWeightMedium}
  }

  @media ${device.toTablet} {
    width: 220px;
    font-size: 0.75rem;
    ${FontWeightThin}
  }
`;

const ValueWrapper = styled.div`
  display: grid;
  justify-content: center;
  div {
    position: relative;
  }
  @media ${device.toTablet} {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    div {
      padding: 0 10px;
    }
  }
  @media ${device.desktop} {
    grid-template-columns: repeat(4, 1fr);
    width: 1180px;
    div:not(:last-child):after {
      content: '';
      position: absolute;
      left: calc(100% + 9px);
      width: 1px;
      height: 230px;
      top: 10%;
      background-color: #e8e8e8;
    }
  }
`;

const TotalSolution = ({ currentLanguage, isDesktop }) => {
  const Mobile = isDesktop ? '' : 'M';
  return (
    <Wrapper>
      <Row
        justify='center'
        align='middle'
        style={{ marginBottom: isDesktop ? '469px' : '50px' }}
      >
        <SolutionWrapper>
          <ContentBox
            isDesktop={isDesktop}
            subtitle={
              multiLanguage['판다코리아 이커머스 토탈 솔루션'][currentLanguage]
            }
            text={
              multiLanguage['제조사(브랜드)가 상품 기획 및 개발 DESC'][
                currentLanguage
              ]
            }
            bigImg={eCommerce1}
            mobileCenter
            noMobileTopMargin
            mobileTextCenter
          >
            {
              multiLanguage[`글로벌 E-commerce 토탈 솔루션${Mobile}`][
                currentLanguage
              ]
            }
          </ContentBox>
        </SolutionWrapper>
      </Row>
      <Row className='title' justify='center'>
        <WithTopDivider marginBottom={'13px'}>
          <SubHeading fontSize={FONTSIZE_30}>
            <span
              dangerouslySetInnerHTML={{
                __html: `${
                  multiLanguage[
                    `이커머스 토탈 솔루션이 제공하는 가치${Mobile}`
                  ][currentLanguage]
                }`,
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <Row
        justify='center'
        style={{ marginBottom: isDesktop ? '99px' : '30px' }}
      >
        <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
          <Text>
            {
              multiLanguage[
                '다년간의 채널 서비스 운영 노하우로 다양한 서비스를 제공합니다.'
              ][currentLanguage]
            }
          </Text>
        </ScrollAnimation>
      </Row>
      <Row
        justify='center'
        style={{ marginBottom: isDesktop ? '569px' : '50px' }}
      >
        <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
          <ValueWrapper>
            <SubIconWithText
              title={'Direct to Consumer'}
              text={multiLanguage['복잡한 유통 단계를 줄여'][currentLanguage]}
              icon={directIcon}
              regular
            />
            <SubIconWithText
              title={'Target Marketing'}
              text={multiLanguage['해당 브랜드 성격에 맞는'][currentLanguage]}
              icon={marketingIcon}
              regular
            />
            <SubIconWithText
              title={'Media Commerce'}
              text={
                multiLanguage['라이브방송, 동영상 컨텐츠등을'][currentLanguage]
              }
              icon={mediaIcon}
              regular
            />

            <SubIconWithText
              title={'Flexible Design'}
              text={multiLanguage['각 나라별, 플랫폼별로'][currentLanguage]}
              icon={flexibleIcon}
              regular
            />
          </ValueWrapper>
        </ScrollAnimation>
      </Row>
      <Row className='title' justify='center'>
        <WithTopDivider marginBottom={'13px'}>
          <SubHeading fontSize={FONTSIZE_30}>
            <span
              dangerouslySetInnerHTML={{
                __html: `${
                  multiLanguage[
                    `글로벌 이커머스 토탈 솔루션 프로세스${Mobile}`
                  ][currentLanguage]
                }`,
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <Row
        justify='center'
        style={{ marginBottom: isDesktop ? '181px' : '30px' }}
      >
        <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
          <Text>
            {multiLanguage['하나의 브랜드를 전 세계'][currentLanguage]}
          </Text>
        </ScrollAnimation>
      </Row>
      <Row
        justify='center'
        style={{ marginBottom: isDesktop ? '106px' : '50px' }}
      >
        <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
          <TotalSolutionProcess
            isDesktop={isDesktop}
            currentLanguage={currentLanguage}
          />
        </ScrollAnimation>
      </Row>
      <Row
        justify='center'
        style={{ marginBottom: isDesktop ? '367px' : '113px' }}
      >
        <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
          <ProductsWrapper>
            {isDesktop && <ProductIcon hasBg={true} />}
            <ChannelWrapper>
              {multiLanguage['채널 설명'].map((item, index) => (
                <ProductItem
                  key={index}
                  title={item[0][currentLanguage]}
                  text={item[1][currentLanguage]}
                  index={index}
                  isDesktop={isDesktop}
                  currentLanguage={currentLanguage}
                />
              ))}
            </ChannelWrapper>
          </ProductsWrapper>
        </ScrollAnimation>
      </Row>
      <Row
        justify='center'
        style={{ marginBottom: isDesktop ? '458px' : '25px' }}
      >
        <BackgroundWrapper>
          <div className='title'>
            <WithTopDivider marginBottom={isDesktop ? '65.4px' : '78px'}>
              <SubHeading fontSize={FONTSIZE_30}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${
                      multiLanguage[
                        isDesktop
                          ? '판다코리아 글로벌 이커머스 토탈 솔루션의 경쟁력'
                          : '글로벌 이커머스 토탈 서비스의 경쟁력'
                      ][currentLanguage]
                    }`,
                  }}
                />
              </SubHeading>
            </WithTopDivider>
          </div>
          <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
            <CompetitiveWrapper>
              {['Exploration', 'Global Business', 'Partnership'].map(
                (item, index) => (
                  <CompetitiveItem
                    key={item}
                    title={item}
                    text={multiLanguage['경쟁력'][index][currentLanguage]}
                  />
                )
              )}
            </CompetitiveWrapper>
          </ScrollAnimation>
        </BackgroundWrapper>
      </Row>
      <Row className='title' justify='center'>
        <WithTopDivider marginBottom={isDesktop ? '13px' : '27px'}>
          <SubHeading fontSize={FONTSIZE_30}>
            <span
              dangerouslySetInnerHTML={{
                __html: `${multiLanguage['글로벌 이커머스 토탈 서비스의 운영방식'][currentLanguage]}`,
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <Row
        justify='center'
        style={{ marginBottom: isDesktop ? '181px' : '60px' }}
      >
        <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
          <Text>
            {
              multiLanguage[
                '각 분야의 전문 인력들이 수년간의 경험과 운영 노하우로 통합 솔루션을 진행합니다.'
              ][currentLanguage]
            }
          </Text>
        </ScrollAnimation>
      </Row>
      <Row
        justify='center'
        style={{ marginBottom: isDesktop ? '300px' : '50px' }}
      >
        <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
          <Management currentLanguage={currentLanguage} isDesktop={isDesktop} />
        </ScrollAnimation>
      </Row>

      <ContactUsWrapper>
        <ContactUs currentLanguage={currentLanguage} isDesktop={isDesktop} />
      </ContactUsWrapper>
    </Wrapper>
  );
};

export default TotalSolution;
