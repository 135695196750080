import React from 'react';
import { Row } from 'antd';
import { useSelector } from 'react-redux';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import CompanyContent from './comonents/CompanyContent';
import VisionSummery from './comonents/VisionSummery';
import multiLanguage from '../../assets/multiLanguage.json';

const About = ({ isDesktop }) => {
  const { currentLanguage } = useSelector((state) => state.global);

  return (
    <>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  multiLanguage['판다코리아닷컴의 비전과 가치'][
                    currentLanguage
                  ],
              }}
            />
          </SubHeading>
        </WithTopDivider>
        <VisionSummery isDesktop={isDesktop} />
      </Row>
      <CompanyContent />
    </>
  );
};

export default About;
