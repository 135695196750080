import React from "react";
import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

const StyledVerticalDivider = styled.div`
  float: left;
  height: 250px;
  width: 1px;
  background-color: #eeeeee;
  margin: 0 10px;
  ${({ $style }) =>
    $style &&
    css`
      ${$style}
      ${$style.marginRight} && margin-right:${$style.marginRight * 0.4}px;
      ${$style.marginRight}&&margin-left: ${$style.marginLeft * 0.4}px;
      @media ${device.desktop} {
        ${$style.marginRight}&&margin-right: ${$style.marginRight}px;
        ${$style.marginRight}&&margin-left: ${$style.marginLeft}px;
      }
    `}
`;

const VerticalDivider = ({ style }) => {
  return <StyledVerticalDivider $style={style} />;
};

export default VerticalDivider;
