import React from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';
import ContentBox from './components/ContentBox';
import BackgroundBox from './components/BackgroundBox';
import Button from '../buttons/Button';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import PathDotItem from './components/PathDotItem';
import Arrow from '../common/Arrow';

import { device } from '../../styles/mediaQueries';
import { URL } from '../../data/constants';
import multiLanguage from '../../assets/multiLanguage.json';
import {
  BackgroundLightRed,
  ColorTextGray,
  Subtitle,
} from '../../styles/commonStyle';

import alibaba1 from '../../assets/business/b2b/b2b-image-alibaba.png';
import alibabaIcon_1_1 from '../../assets/business/b2b/b2b-icon-01-01.png';
import alibabaIcon_1_2 from '../../assets/business/b2b/b2b-icon-01-02.png';
import alibabaIcon_2_1 from '../../assets/business/b2b/b2b-icon-02-01.png';
import alibabaIcon_2_2 from '../../assets/business/b2b/b2b-icon-02-02.png';
import alibabaIcon_2_3 from '../../assets/business/b2b/b2b-icon-02-03.png';
import alibabaIcon_2_4 from '../../assets/business/b2b/b2b-icon-02-04.png';
import alibabaIcon_2_5 from '../../assets/business/b2b/b2b-icon-02-05.png';
import alibabaIcon_2_6 from '../../assets/business/b2b/b2b-icon-02-06.png';

const ExportProcessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.toTablet} {
    width: 100%;
    padding: 0 4.2vw 80px;
  }
  @media ${device.desktop} {
    height: 630px;
    margin-bottom: 315px;
  }
`;

const TransportationWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media ${device.toTablet} {
    grid-template-columns: 1fr;
    article:last-child {
      border-top: 1.5px solid ${({ theme }) => theme.lightGray};
    }
  }
  @media ${device.desktop} {
    margin-top: 144px;
    article:last-child {
      border-left: 5px solid ${({ theme }) => theme.lightGray};
    }
  }
`;

const Transportation = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 120px;
  & > * {
    text-align: center;
  }
  h6 {
    font-size: 1.09375rem;
    line-height: 3;
    margin-top: 40px;
  }
  p {
    ${ColorTextGray}
    font-size: 0.96875rem;
    line-height: 3.1;
  }
  @media ${device.toTablet} {
    width: 324px;
    padding: 50px 35px;
    flex-direction: row;
    justify-content: flex-start;
    div:first-child {
      margin-right: 29px;
    }
    h6 {
      font-size: 0.9rem;
      margin-top: 0px;
    }
    p {
      font-size: 0.75rem;
      text-align: left;
    }
    img {
      width: 51px;
    }
  }
`;

const ProcessWrapper = styled.div`
  padding: 73px 0 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.toTablet} {
    padding: 0 0 85px;
  }
  @media ${device.desktop} {
    ${BackgroundLightRed}
  }
`;

const ProcessContent = styled.section`
  width: 910px;
  margin-top: 76px;
  display: grid;
  grid-template-columns: 1fr 25px 1fr 25px 1fr;
  align-items: center;
  justify-items: center;
  grid-row-gap: 120px;
  @media ${device.toTablet} {
    width: 100%;
    margin-top: 42px;
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
`;

const B2b = ({ currentLanguage, isDesktop }) => {
  return (
    <div>
      <Row style={{ position: 'relative', width: '100%' }} justify='center'>
        <ContentBox
          margin={{
            marginBottom: isDesktop ? 250 : 58,
          }}
          titleBold
          alignItems={'flex-start'}
          mobileCenter
          mobileTextCenter
          text={
            multiLanguage['판다코리아닷컴은 글로벌 알리바바의 DESC'][
              currentLanguage
            ]
          }
          bigImg={alibaba1}
          button={
            <Button
              redButton
              buttonText={multiLanguage['알리바바 바로가기'][currentLanguage]}
              path={URL.ALIBABA}
            />
          }
        >
          {multiLanguage['글로벌 알리바바 Global Alibaba'][currentLanguage]}
        </ContentBox>
        <BackgroundBox bottom={isDesktop ? 10 : null} />
      </Row>
      <ExportProcessWrapper>
        <WithTopDivider marginBottom={'13px'}>
          <SubHeading fontSize={'1.5625rem'}>
            <span
              dangerouslySetInnerHTML={{
                __html: `${multiLanguage['알리바바 수출 절차'][currentLanguage]}`,
              }}
            />
          </SubHeading>
        </WithTopDivider>
        <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
          <Subtitle>
            {`${multiLanguage['크게 두 가지 방법으로 운송 수단 구분을 하는데 수출 제품의 부피와 총중량 DESC'][currentLanguage]}`}
          </Subtitle>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
          <TransportationWrapper>
            <Transportation>
              <div>
                <img src={alibabaIcon_1_1} alt='icon' />
                <h6>{multiLanguage['항공 운송'][currentLanguage]}</h6>
              </div>
              <div>
                <p>
                  {
                    multiLanguage['·샘플 발송 혹은 소량으로 수출할 시'][
                      currentLanguage
                    ]
                  }
                </p>
                <p>
                  {
                    multiLanguage['·주로 30KG 미만으로 진행할 시'][
                      currentLanguage
                    ]
                  }
                </p>
                <p>{multiLanguage['·배송기간 3일 - 5일'][currentLanguage]}</p>
              </div>
            </Transportation>
            <Transportation>
              <div>
                <img src={alibabaIcon_1_2} alt='icon' />
                <h6>{multiLanguage['해상 운송'][currentLanguage]}</h6>
              </div>
              <div>
                <p>
                  {multiLanguage['·LCL 혹은 FCL로 수출할 시'][currentLanguage]}
                </p>
                <p>
                  {
                    multiLanguage['·한 팔레트가 1CBM 이상일 시'][
                      currentLanguage
                    ]
                  }
                </p>
                <p>
                  {multiLanguage['·배송기간 1주일 - 1개월'][currentLanguage]}
                </p>
              </div>
            </Transportation>
          </TransportationWrapper>
        </ScrollAnimation>
      </ExportProcessWrapper>
      <ProcessWrapper>
        <WithTopDivider marginBottom={isDesktop ? '13px' : '0'}>
          <SubHeading fontSize={'1.5625rem'}>
            <span
              dangerouslySetInnerHTML={{
                __html: `${multiLanguage['알리바바 제휴 절차'][currentLanguage]}`,
              }}
            />
          </SubHeading>
        </WithTopDivider>
        <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
          <ProcessContent>
            <PathDotItem
              title={`${multiLanguage['상담/미팅'][currentLanguage]}`}
              text={`${multiLanguage['수출을 위한 전화 상담 후 대면 또는 비대면 미팅'][currentLanguage]}`}
              icon={alibabaIcon_2_1}
            />
            <Arrow />
            <PathDotItem
              title={`${multiLanguage['상품 업로드'][currentLanguage]}`}
              text={`${multiLanguage['글로벌 알리바바닷컴에 브랜드사 상품 업로드'][currentLanguage]}`}
              icon={alibabaIcon_2_2}
            />
            <Arrow />
            <PathDotItem
              title={`${multiLanguage['바이어 대응'][currentLanguage]}`}
              text={`${multiLanguage['해외 바이어들의 온라인 문의에 대한 대응'][currentLanguage]}`}
              icon={alibabaIcon_2_3}
            />
            {!isDesktop && <Arrow />}
            <PathDotItem
              title={`${multiLanguage['제품 발주'][currentLanguage]}`}
              text={`${multiLanguage['계약이 체결될 시 제품 발주'][currentLanguage]}`}
              icon={alibabaIcon_2_4}
            />
            <Arrow />
            <PathDotItem
              title={`${multiLanguage['선매입 후출고'][currentLanguage]}`}
              text={`${multiLanguage['주로 선매입 후출고 구조로 하지만, 상황에 따라 50% 예치금으로 진행 가능'][currentLanguage]}`}
              icon={alibabaIcon_2_5}
            />
            <Arrow />
            <PathDotItem
              title={`${multiLanguage['창고 입고'][currentLanguage]}`}
              text={`${multiLanguage['브랜드사 제품을 판다코리아닷컴 인천창고에 입고'][currentLanguage]}`}
              icon={alibabaIcon_2_6}
            />
          </ProcessContent>
        </ScrollAnimation>
      </ProcessWrapper>
    </div>
  );
};

export default B2b;
