import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import useWindowPosition from '../hooks/useWindowPosition';

import ECommerce from '../components/business/ECommerce';
import IoT from '../components/business/IoT';

const Business = () => {
  const { currentLanguage } = useSelector((state) => state.global);
  let { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { isDesktop, isMobile } = useWindowSize();
  const { moveYScroll } = useWindowPosition();
  React.useEffect(() => {
    moveYScroll();
  }, [moveYScroll]);
  return (
    <Switch>
      <Route exact path={`${path}/e-commerce/:tabId`}>
        <ECommerce
          pathname={pathname}
          currentLanguage={currentLanguage}
          isDesktop={isDesktop}
        />
      </Route>
      <Route exact path={`${path}/iot/:tabId`}>
        <IoT
          pathname={pathname}
          currentLanguage={currentLanguage}
          isDesktop={isDesktop}
        />
      </Route>
    </Switch>
  );
};

export default Business;
