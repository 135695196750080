import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CATEGORY } from '../../data/constants';
import { convertLocalDate } from '../../helper/common';
import { device } from '../../styles/mediaQueries';

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border: solid 1px #e6e6e6;
  padding: 3.125rem 2.725rem;
  cursor: pointer;
  :hover {
    h3 span {
      background-image: linear-gradient(0, #ffc1c0 50%, transparent 50%);
    }
  }
  summary,
  h3 {
    font-weight: ${({ theme }) => theme.bold};
  }
  summary {
    display: block;
    color: ${({ isClosed, theme }) => (isClosed ? theme.textGray : theme.red)};
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  h3,
  p {
    word-break: break-word;
  }

  h3 {
    font-size: 1rem;
    line-height: 1.5;
    white-space: pre-wrap;
    -webkit-line-clamp: 3; /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    text-overflow: -o-ellipsis-lastline;
  }

  p {
    max-height: 112px;
    width: 100%;
    font-size: 0.9375rem;
    line-height: 1.5;
    letter-spacing: -0.6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    text-overflow: -o-ellipsis-lastline;
    color: ${({ theme }) => theme.textGray};
  }
  time {
    margin-top: 40px;
    color: ${({ theme }) => theme.textGray};
  }
  @media ${device.toTablet} {
    h3 {
      margin-bottom: 10px;
    }
  }
  @media ${device.desktop} {
    width: 380px;
    height: 100%;
    min-height: 240px;
    max-height: 386px;
    align-items: flex-start;
    h3 {
      height: 87px;
      font-size: 1.1875rem;
    }
    summary {
      font-size: 1.0625rem;
    }
  }
`;

const BoardCard = ({ data, currentLanguage, path }) => {
  const history = useHistory();
  const goToDetail = (item) => {
    const { content, SK, imageUrl } = item;
    if (content || (imageUrl && imageUrl.length > 0)) {
      return history.push(`${path}/${SK}`);
    }
    return false;
  };
  return (
    <CardContainer
      isClosed={data.type === 'close'}
      onClick={() => goToDetail(data)}
    >
      <div>
        <summary>{CATEGORY[data.type][currentLanguage]}</summary>
        <h3>
          <span>{data.title}</span>
        </h3>
      </div>
      <p>{data.content}</p>
      <time>{convertLocalDate(data.date)}</time>
    </CardContainer>
  );
};

export default BoardCard;
