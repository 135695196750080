export const menus = [
  {
    path: '/company',
    name: 'COMPANY',
    label: 'company',
    topName: null,
    sub: [
      {
        name: '회사소개',
        path: '/about',
        label: 'about',
        topName: 'company',
      },
      { name: 'CEO 인사말', path: '/ceo', label: 'ceo', topName: 'company' },
      { name: '연혁', path: '/history', label: 'history', topName: 'company' },
      { name: 'CI 소개', path: '/ci', label: 'ci', topName: 'company' },
      { name: '판다뉴스', path: '/news', label: 'news', topName: 'company' },
    ],
  },
  {
    path: '/business',
    name: 'BUSINESS',
    label: 'business',
    topName: null,
    sub: [
      {
        name: 'E-commerce',
        path: '/e-commerce',
        label: 'e-commerce',
        topName: 'business',
        sub: [
          {
            name: '이커머스 토탈솔루션',
            path: '/total-solution',
            label: 'total-solution',
            topName: 'business',
          },
          {
            name: 'B2C채널서비스',
            path: '/b2c',
            label: 'b2c',
            topName: 'business',
          },
          {
            name: 'B2B알리바바 서비스',
            path: '/b2b',
            label: 'b2b',
            topName: 'business',
          },
          {
            name: '중국 온라인 운영대행',
            path: '/online',
            label: 'online',
            topName: 'business',
          },
        ],
      },
      {
        name: '공유 IOT 서비스',
        path: '/iot',
        label: 'iot',
        topName: 'business',
        sub: [
          {
            name: '공유 보조배터리 충전돼지',
            path: '/battery',
            label: 'battery',
            topName: 'business',
          },
          {
            name: '공유 전기 자전거 카카오 바이크',
            path: '/bike',
            label: 'bike',
            topName: 'business',
          },
        ],
      },
    ],
  },
  {
    path: '/information',
    name: 'INFORMATION',
    label: 'information',
    topName: null,
    sub: [
      {
        name: '티몰 가이드',
        path: '/tmall',
        label: 'tmall',
        topName: 'information',
      },
      {
        name: '타오바오 가이드',
        path: '/taobao',
        label: 'taobao',
        topName: 'information',
      },
      {
        name: '알리바바 가이드',
        path: '/alibaba',
        label: 'alibaba',
        topName: 'information',
      },
    ],
  },
  {
    path: '/recruit',
    name: 'RECRUIT',
    label: 'recruit',
    topName: null,
    sub: [
      { name: '인재상', path: '/career', label: 'career', topName: 'recruit' },
      { name: '입사지원', path: '/apply', label: 'apply', topName: 'recruit' },
    ],
  },
  {
    path: '/contact',
    name: 'CONTACT US',
    label: 'contact',
    topName: null,
    sub: [
      {
        name: '문의하기',
        path: '/inquiry',
        label: 'inquiry',
        topName: 'contact',
      },
      {
        name: '오시는 길',
        path: '/location',
        label: 'location',
        topName: 'contact',
      },
    ],
  },
];
