import React from 'react';
import { breakpoints } from '../styles/mediaQueries';
/**
 * isDesktop, isMobile : boolean type
 * css조정 외에 width에 따른 조정이 필요 할떄 사용
 */
export default function useWindowSize() {
  const isSSR = typeof window !== 'undefined';
  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? window.innerWidth : breakpoints.desktop,
    height: isSSR ? window.innerHeight : 800,
    isDesktop: window.innerWidth >= breakpoints.desktop,
    isMobile: window.innerWidth < breakpoints.tablet,
  });

  const changeWindowSize = React.useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isDesktop: window.innerWidth >= breakpoints.desktop,
      isMobile: window.innerWidth < breakpoints.tablet,
    });
  }, []);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, [changeWindowSize]);

  return windowSize;
}
