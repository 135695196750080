import detectBrowserLanguage from 'detect-browser-language';

const browserLanguage = detectBrowserLanguage();
let currentLanguage;

switch (browserLanguage.toLowerCase()) {
  case 'ko':
  case 'ko-kr':
  case 'ko-kp':
    currentLanguage = 'KR';
    break;
  case 'zh':
  case 'zh-hk':
  case 'zh-cn':
  case 'zh-sg':
  case 'zh-tw':
    currentLanguage = 'CN';
    break;
  default:
    currentLanguage = 'EN';
}

const initState = {
  currentLanguage,
  windowSize: { height: 800, width: 1920 },
  sliderList: {
    mobileKR: [],
    desktopKR: [],
    mobileEN: [],
    desktopEN: [],
    mobileCN: [],
    desktopCN: [],
  },
  popupList: { KR: [], EN: [], CN: [] },
  newsList: [],
  tmallList: [],
  alibabaList: [],
  recruitList: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      return {
        ...state,
        currentLanguage: action.payload,
      };
    }
    case 'SET_NEWSLIST': {
      if (state.currentLanguage === action.payload[0].language) {
        return {
          ...state,
          newsList: action.payload,
        };
      }
      return state;
    }
    case 'SET_TMALL_LIST': {
      return {
        ...state,
        tmallList: action.payload,
      };
    }
    case 'SET_ALIBABA_LIST': {
      return {
        ...state,
        alibabaList: action.payload,
      };
    }
    case 'SET_RECRUIT_LIST': {
      return {
        ...state,
        recruitList: action.payload,
      };
    }
    case 'SET_SLIDERLIST': {
      return {
        ...state,
        sliderList: {
          ...state.sliderList,
          ...action.payload,
        },
      };
    }
    case 'SET_POPUPLIST': {
      return {
        ...state,
        popupList: {
          ...state.popupList,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
