import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import TextWithDivider from '../texts/TextWithDivider';
import { RightCircleOutlined } from '@ant-design/icons';
import { WrapperWidth1240 } from '../../styles/commonStyle';
import contents from '../../data/mainGuideContents';
import multiLanguage from '../../assets/multiLanguage.json';

const TabWrapper = styled.div`
  ${WrapperWidth1240}
  height: 336px;
  display: flex;
`;

const TabList = styled.ul`
  padding-top: 25px;
  width: 381px;
`;

const TabItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  height: 97px;
  font-size: 1.125rem;
  z-index: 100;
  color: ${({ active, theme }) => (active ? theme.white : theme.black)};

  ::after {
    ${({ active }) =>
      active &&
      css`
        content: ' ';
        position: absolute;
        top: 0;
        left: -20px;
        height: 97px;
        background-color: ${({ theme }) => theme.red};
        z-index: -1;
        width: 440px;
      `}
  }

  span {
    font-weight: ${({ theme }) => theme.thin};
    padding-right: 39px;
    font-size: 3.125rem;
  }
`;

const TabContent = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding-left: 105px;
  padding-top: 70.6px;
  width: 802px;
`;

const Content = styled.p`
  font-size: 0.9375rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.textGray};
  white-space: pre-wrap;
`;

const ContentTitle = styled.p`
  font-weight: ${({ theme }) => theme.medium};
  font-size: 1.125rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.black};
  white-space: pre-wrap;
`;

const ContentImage = styled.img`
  padding-bottom: 48px;
  padding-right: 50px;
  align-self: flex-end;
`;

const GuideTap = ({ currentLanguage }) => {
  const [activeTab, setActiveTab] = useState(0);
  const data = [
    { text: multiLanguage['티몰 개설 가이드'][currentLanguage] },
    { text: multiLanguage['타오바오 개설 가이드'][currentLanguage] },
    { text: multiLanguage['알리바바 개설 가이드'][currentLanguage] },
  ];
  return (
    <TabWrapper>
      <TabList>
        {data.map((item, index) => (
          <TabItem
            key={index}
            active={activeTab === index}
            onMouseEnter={() => setActiveTab(index)}
          >
            <span>{index + 1}</span>
            {item.text}
          </TabItem>
        ))}
      </TabList>
      <TabContent>
        <div>
          <TextWithDivider
            number={contents[activeTab].number}
            text={contents[activeTab].text}
            subText={contents[activeTab].subText}
            long
            dividerColor='red'
            numberColor='red'
          />
          <Link to={contents[activeTab].path}>
            <ContentTitle>
              {multiLanguage[contents[activeTab].contentTitle][currentLanguage]}
              <RightCircleOutlined style={{ paddingLeft: 10 }} />
            </ContentTitle>
            <Content>
              {multiLanguage[contents[activeTab].content][currentLanguage]}
            </Content>
          </Link>
        </div>
        <ContentImage src={contents[activeTab].background} />
      </TabContent>
    </TabWrapper>
  );
};

export default GuideTap;
