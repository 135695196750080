import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import CommonButton from '../buttons/CommonButton';
import { WrapperWidth1240 } from '../../styles/commonStyle';
import { device } from '../../styles/mediaQueries';
import { convertLocalDate } from '../../helper/common';
import { CATEGORY } from '../../data/constants';

const DetailContainer = styled.div`
  ${WrapperWidth1240}
  border-top: solid 2px #000000;
  text-align: center;
  margin-bottom: 4.5rem;

  section,
  article {
    display: flex;
  }

  section,
  article {
    padding: 0 3.125rem;
    @media ${device.toTablet} {
      padding: 0 4.2vw;
    }
  }

  section {
    justify-content: space-between;
    align-items: center;
    height: 7.6875rem;
    line-height: 1.5;
    border-bottom: solid 1px #dddddd;
    margin-bottom: 4.25rem;
    @media ${device.toTablet} {
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
      margin-bottom: 0;
    }
    h1 {
      font-size: 1.5625rem;
      color: ${({ theme }) => theme.black};
      font-weight: ${({ theme }) => theme.medium};
      @media ${device.toTablet} {
        font-size: 1.1875rem;
      }
    }
    aside {
      font-family: ${({ $onGoing, theme }) =>
        $onGoing ? theme.bold : theme.thin};
      color: ${({ onGoing, theme }) => (onGoing ? theme.red : theme.liteGray)};
    }
  }
  article {
    flex-direction: column;
    font-size: 0.9375rem;
    line-height: 2;
    text-align: left;
    margin-bottom: 7.5rem;
    p {
      margin-top: 40px;
      line-height: 2;
    }
    @media ${device.toTablet} {
      margin-bottom: 50px;
      img {
        margin-top: 50px;
        width: 100%;
      }
    }
  }

  ul {
    display: flex;
    border-top: solid 1px #dddddd;
    li {
      display: flex;
      align-items: center;
      flex: 9;
      width: 100%;
      height: 3.9375rem;
      font-size: 1.0625rem;
      line-height: 1;
      font-weight: ${({ theme }) => theme.demiLite};
      a {
        width: 100%;
        height: 100%;
        padding: 0 5px;
        display: inline-block;
        text-align: left;
        line-height: 3.9375rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @media ${device.toTablet} {
        font-size: 0.9rem;
        a {
          width: 77vw;
          padding: 0 4.2vw;
        }
      }
    }
    li:first-child {
      flex: 1;
      color: ${({ theme }) => theme.textGray};
      padding-left: 20px;
      span {
        margin-left: 15px;
        font-size: 0.75rem;
      }
      @media ${device.toTablet} {
        flex: 3;
        padding-left: 4.2vw;
        span {
          margin-left: 5px;
        }
      }
    }
  }
  ul.last {
    border-bottom: solid 1px #dddddd;
    margin-bottom: 2.25rem;
  }
  a.link {
    align-self: center;
    padding: 10px;
    margin: 30px 0;
    max-width: 200px;
    text-align: center;
    background-color: ${({ theme }) => theme.red};
    color: #ffffff;
    border-radius: 10px;
  }
  div.tag {
    cite {
      margin-left: 10px;
      background-color: ${({ theme }) => theme.lightGray};
      padding: 4px 10px;
      border-radius: 10px;
      color: #ffffff;
    }
  }
`;

const BoardDetail = ({ data, path, currentLanguage }) => {
  const { current, next, prev } = data;
  const isApply =
    'type' in current &&
    (current.type === 'hiring' || current.type === 'close');
  return (
    <DetailContainer $onGoing={isApply}>
      <section>
        <h1>{current.title}</h1>
        {isApply ? (
          <aside>{CATEGORY[current.type][currentLanguage]}</aside>
        ) : (
          <aside>{convertLocalDate(current.date)}</aside>
        )}
      </section>
      <article>
        {current.imageUrl && (
          <img src={current.imageUrl[0].url} alt={current.title} />
        )}
        <p>{current.content}</p>
        {current.link && current.linkUrl && (
          <a
            className='link'
            href={current.linkUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            {current.link}
          </a>
        )}
      </article>
      {next && (
        <ul className={prev ? '' : 'last'}>
          <li>
            <p>다음글</p> <UpOutlined />
          </li>
          <li>
            <Link to={`${path}/${next.SK}#tab`}>{next.title}</Link>
          </li>
        </ul>
      )}
      {prev && (
        <ul className='last'>
          <li>
            <p>이전글</p>
            <DownOutlined />
          </li>
          <li>
            <Link to={`${path}/${prev.SK}#tab`}>{prev.title}</Link>
          </li>
        </ul>
      )}
      <CommonButton square>
        <Link to={`${path}#tab`}>목록보기</Link>
      </CommonButton>
    </DetailContainer>
  );
};

export default BoardDetail;
