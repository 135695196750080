import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/mediaQueries';
import {
  FontWeightThin,
  FontWeightDemiLite,
  FontWeightNormal,
} from '../../styles/commonStyle';

const IconWithTextWrapper = styled.div`
  text-align: center;
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 41.4vw;
  padding: 5px;
  figure {
    max-height: 60px;
  }
  img {
    width: 50%;
    object-fit: scale-down;
  }
  @media ${device.desktop} {
    width: 284px;
    figure {
      height: 100px;
      max-height: 100px;
    }
    img {
      width: initial;
    }
  }
  p {
    font-size: 0.75rem;
    letter-spacing: -0.6px;
    @media ${device.desktop} {
      font-size: 1rem;
    }
  }

  p:first-of-type {
    margin-top: 25px;
    margin-bottom: ${({ subTitle }) => (subTitle ? 5 : 10)} px;
    font-size: 0.9375rem;
    ${FontWeightDemiLite}
    @media ${device.desktop} {
      font-size: 1.125rem;
      ${FontWeightNormal}
    }
  }
  .sub-title {
    font-size: 1rem;
    margin-bottom: 10px;
    @media ${device.desktop} {
      font-size: 1.125rem;
    }
  }
  .text {
    padding-top: 20px;
    ${FontWeightThin}
    color: ${({ theme }) => theme.textGray};
    word-break: keep-all;
    min-height: 130px;
    max-width: 140px;
    @media ${device.desktop} {
      max-width: 200px;
      font-weight: ${({ regular }) => (regular ? 400 : 500)};
    }
  }
`;

const SubIconWithText = ({ title, subTitle, text, icon, regular }) => (
  <IconWithTextWrapper subTitle={subTitle} regular={regular}>
    <figure>
      <img src={icon} alt='icon' />
    </figure>
    <p>{title}</p>
    {subTitle && <p className='sub-title'>{subTitle}</p>}
    <p className='text'>{text}</p>
  </IconWithTextWrapper>
);

export default SubIconWithText;
