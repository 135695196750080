import React from 'react';
import styled from 'styled-components';

import icon1 from '../../../assets/business/bike/bike-icon-01.png';
import icon2 from '../../../assets/business/bike/bike-icon-02.png';
import icon3 from '../../../assets/business/bike/bike-icon-03.png';
import icon4 from '../../../assets/business/bike/bike-icon-04.png';
import icon5 from '../../../assets/business/bike/bike-icon-05.png';
import icon6 from '../../../assets/business/bike/bike-icon-06.png';
import icon7 from '../../../assets/business/bike/bike-icon-07.png';
import label1 from '../../../assets/business/bike/bike-label-01.png';
import label2 from '../../../assets/business/bike/bike-label-02.png';
import label3 from '../../../assets/business/bike/bike-label-03.png';
import backgroundLine from '../../../assets/business/bike/background-line.png';
import backgroundLineM from '../../../assets/business/bike/background-line-m.png';
import multiLanguage from '../../../assets/multiLanguage.json';
import { ColorTextGray, FlexColCenter } from '../../../styles/commonStyle';
import { device } from '../../../styles/mediaQueries';

const Container = styled.div`
  margin: 165px 0 560px;
  width: 1332px;
  height: 559px;
  position: relative;
  background: url(${backgroundLine}) no-repeat center;
  article,
  aside {
    position: absolute;
  }
  @media ${device.toTablet} {
    margin: 69px 0 280px;
    width: 212px;
    height: 582px;
    background: url(${backgroundLineM}) no-repeat center;
    background-size: contain;
  }
`;

const ItemContainer = styled.div`
  article:first-child {
    top: -8%;
    left: 15%;
  }
  article:nth-child(2) {
    top: -9%;
    left: 63%;
  }
  article:nth-child(3) {
    top: -8%;
    left: 93%;
  }
  article:nth-child(4) {
    top: 84%;
    left: 73%;
  }
  article:nth-child(5) {
    top: 85%;
    left: 28.5%;
  }
  article:nth-child(6) {
    top: 45%;
    left: -5%;
  }
  article:nth-child(7) {
    top: 43%;
    left: 14%;
  }
  @media ${device.toTablet} {
    article:first-child {
      top: 23%;
      left: 16%;
    }
    article:nth-child(2) {
      top: 48.5%;
      left: 16%;
    }
    article:nth-child(3) {
      top: 74%;
      left: 16%;
    }
    article:nth-child(4) {
      top: 92%;
      left: -34%;
    }
    article:nth-child(5) {
      top: 89.5%;
      left: 66.5%;
    }
    article:nth-child(6) {
      top: -4%;
      left: 16%;
    }
    article:nth-child(7) {
      top: -4%;
      left: -33%;
    }
  }
`;

const LineContainer = styled.div`
  aside {
    width: 165px;
    text-align: center;
    font-size: ${({ currentLanguage }) =>
      currentLanguage === 'EN' ? 20 : 24}px;
    line-height: 1.3;
    ${ColorTextGray}
  }
  aside:first-child {
    top: 3%;
    left: 40%;
  }
  aside:nth-child(2) {
    top: ${({ currentLanguage }) => (currentLanguage === 'EN' ? -10 : -7)}%;
    left: 80%;
  }
  aside:nth-child(3) {
    top: 28%;
    left: 80%;
  }
  aside:nth-child(4) {
    top: 97%;
    left: 94%;
  }
  aside:nth-child(5) {
    top: 97%;
    left: 54%;
  }
  aside:nth-child(6) {
    top: 97%;
    left: 7.5%;
  }
  aside:nth-child(7) {
    top: 17%;
    left: -8%;
  }
  @media ${device.toTablet} {
    aside {
      width: 100px;
      font-size: 12px;
      line-height: 1;
      letter-spacing: -0.5px;
    }

    aside:first-child {
      top: 41.5%;
      left: 54%;
      text-align: left;
    }
    aside:nth-child(2) {
      top: 67%;
      left: 65%;
      text-align: left;
    }
    aside:nth-child(3) {
      top: 67%;
      left: -10%;
      text-align: right;
    }
    aside:nth-child(4) {
      top: 83%;
      left: -41%;
      text-align: right;
    }
    aside:nth-child(5) {
      top: 97.5%;
      left: 25%;
    }
    aside:nth-child(6) {
      top: 47%;
      left: 103%;
      text-align: left;
    }
    aside:nth-child(7) {
      top: 13.5%;
      left: 53%;
      text-align: left;
    }
  }
`;

const ItemWrapper = styled.article`
  ${FlexColCenter}
  color: ${({ isRed, theme }) => (isRed ? theme.red : theme.yellow)};
  p {
    max-width: 200px;
    margin: 14px 0 20px;
    text-align: center;
    font-size: 24px;
    line-height: 1.2;
  }
  @media ${device.toTablet} {
    width: 147px;
    p {
      max-width: 147px;
      margin: 5px 0;
      font-size: 12px;
    }
    img:first-child {
      max-height: 30px;
    }
    img:last-child {
      width: 98px;
    }
  }
`;

const Item = ({ icon, text, label, isRed }) => (
  <ItemWrapper isRed={isRed}>
    <img src={icon} alt='icon' />
    <p>{text}</p>
    <img src={label} alt='icon' />
  </ItemWrapper>
);

const items = [
  { icon: icon1, text: '중국 제조사', label: label1, isRed: false },
  { icon: icon2, text: '제조관리(1차 검수)', label: label3, isRed: true },
  { icon: icon3, text: '품질검수(2차 검수)', label: label3, isRed: true },
  { icon: icon4, text: '물류 수입 통관', label: label2, isRed: true },
  { icon: icon5, text: '납품 검수(3차 검수)', label: label3, isRed: true },
  { icon: icon6, text: '요구 분석', label: label1, isRed: false },
  { icon: icon7, text: '인증', label: label2, isRed: false },
];
const BikeCycle = ({ isDesktop, currentLanguage }) => {
  return (
    <Container>
      <ItemContainer currentLanguage={currentLanguage}>
        {items.map(({ icon, text, label, isRed }) => (
          <Item
            key={text}
            icon={icon}
            text={multiLanguage[text][currentLanguage]}
            label={label}
            isRed={isRed}
          />
        ))}
      </ItemContainer>

      <LineContainer currentLanguage={currentLanguage}>
        <aside>{multiLanguage['생산'][currentLanguage]}</aside>
        <aside>{multiLanguage['관리'][currentLanguage]}</aside>
        <aside>{multiLanguage['개선'][currentLanguage]}</aside>
        <aside>{multiLanguage['이동'][currentLanguage]}</aside>
        <aside>{multiLanguage['물류 이동'][currentLanguage]}</aside>
        <aside>{multiLanguage['개선'][currentLanguage]}</aside>
        <aside>
          {multiLanguage['요구사항 전달 및 생산 조율'][currentLanguage]}
        </aside>
      </LineContainer>
    </Container>
  );
};

export default BikeCycle;
