import React from 'react';
import styled from 'styled-components';
import arrow from '../../assets/main/arrow.png';
import { device } from '../../styles/mediaQueries';

const TitleWrapper = styled.h1`
  display: flex;
  flex-direction: row;
  padding: 0 10px 0 0;
  font-weight: ${({ theme }) => theme.bold};
  font-size: 1.375rem;
  vertical-align: text-bottom;
  margin-bottom: 30px;
  color: ${({ theme, color }) => (color ? theme[color] : 'inherit')};
  justify-content: center;
  @media ${device.tablet} {
    font-size: 2.75rem;
  }
  @media ${device.desktop} {
    font-size: 1.875rem;
    justify-content: flex-start;
    align-self: flex-start;
  }
`;
const IconWrapper = styled.img`
  margin-left: 15.7px;
  width: 48px;
  object-fit: contain;
`;
const MainTitle = ({ text, noIcon, color }) => {
  return (
    <TitleWrapper color={color}>
      {text.toUpperCase()}
      {noIcon ? null : <IconWrapper src={arrow} />}
    </TitleWrapper>
  );
};

export default MainTitle;
