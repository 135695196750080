import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Carousel, Row, Col } from 'antd';

import { device } from '../../../../styles/mediaQueries';
import {
	BackgroundLightGray,
	FontWeightDemiLite,
} from '../../../../styles/commonStyle';

import leftIcon from '../../../../assets/slide-icon-left.png';
import rightIcon from '../../../../assets/slide-icon-right.png';
import { stores } from '../../../../data/portfolio';

const Container = styled.div`
	margin: 0 auto;
	display: flex;
	justify-content: center;
	max-width: 300px;
	width: 300px;
	@media ${device.desktop} {
		max-width: 1548px;
		width: 1380px;
	}
`;

const HoverLayer = styled.div`
	width: 355px;
	height: 330px;
	display: none;
	position: absolute;
	text-align: center;
	vertical-align: text-top;
	background-color: rgba(50, 50, 50, 0.5);
	color: #ffffff;
	left: 14px;
	font-size: 24px;
	padding-top: 138px;
`;
const LinkWrapper = styled.a`
	display: inline-block;
	position: relative;
	&:hover ${HoverLayer} {
		display: block;
	}
`;
const Image = styled.img`
	width: 142px;
	height: auto;
	object-fit: cover;

	@media ${device.desktop} {
		margin: 0 15px;
		width: 354.9px;
	}
`;
const StoreName = styled.p`
	margin-bottom: 60px;
	text-align: center;
	font-size: 1.5rem;
	${FontWeightDemiLite}
	@media ${device.toTablet} {
		margin-bottom: 15px;
		font-size: 0.9rem;
	}
`;
const BlankSlideItem = styled.figure`
	margin: 0 15px;
	width: 354.9px !important;
	height: 330px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	${BackgroundLightGray}
	p {
		font-size: 1.5rem;
		${FontWeightDemiLite}
	}
	@media ${device.toTablet} {
		margin: 0;
		width: 142px !important;
		height: 132px;
		p {
			font-size: 0.9rem;
		}
	}
`;
function Arrow(props) {
	const { className, style, onClick, image } = props;
	return (
		<img
			src={image}
			className={className}
			style={{ ...style }}
			onClick={onClick}
		/>
	);
}
const Portfolio = ({ currentLanguage, isDesktop = false }) => {
	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		draggable: false,
		centerMode: true,
		variableWidth: true,
		nextArrow: <Arrow image={rightIcon} />,
		prevArrow: <Arrow image={leftIcon} />,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1247,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					draggable: true,
					centerMode: false,
					variableWidth: false,
				},
			},
		],
	};
	return (
		<Container>
			<Row justify="center">
				{stores.map(({ url, name, image }) =>
					!!name ? (
						<Col className="gutter-row" xs={12} xl={8}>
							<div style={{ textAlign: 'center' }} key={url}>
								<LinkWrapper
									href={url.includes('http') ? url : '#'}
									target={url.includes('http') ? '_blank' : ''}
								>
									<HoverLayer>사이트 바로가기 ➔</HoverLayer>
									<Image src={image} alt={name} />
								</LinkWrapper>
								<StoreName>
									{!!name?.kr
										? currentLanguage === 'KR'
											? name.kr
											: name.en
										: name}
								</StoreName>
							</div>
						</Col>
					) : (
						<BlankSlideItem key={url}>
							<p>Coming soon</p>
						</BlankSlideItem>
					)
				)}
			</Row>
		</Container>
	);
};

export default Portfolio;
