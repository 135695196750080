import React from 'react';
import styled from 'styled-components';
import { Collapse, Row } from 'antd';
import { useSelector } from 'react-redux';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';

import { device } from '../../styles/mediaQueries';
import { WrapperWidth1240 } from '../../styles/commonStyle';
import multiLanguage from '../../assets/multiLanguage.json';

import placeIcon from '../../assets/contact/place-red-icon.png';
import map from '../../assets/sample-map.png';
import incheon from '../../assets/incheon.png';

const { Panel } = Collapse;

const BranchWrapper = styled.div`
  ${WrapperWidth1240}
  padding-bottom: 142px;
  h1 {
    line-height: 80px;
    font-weight: ${({ theme }) => theme.bold};
    font-size: 1.375rem;
    ::before {
      content: url(${placeIcon});
      margin-right: 25.8px;
      width: 21.2px;
    }
  }

  .ant-collapse {
    background-color: transparent;
    border: 0;
    border-top: solid 2px ${({ theme }) => theme.red};
    border-bottom: solid 2px ${({ theme }) => theme.red};
  }
  @media ${device.toTablet} {
    h1 {
      line-height: 50px;
      font-size: 1rem;
    }
  }
`;

const Branch = styled.div`
  section {
    display: flex;
    margin-bottom: 39.4px;
    address {
      max-width: 320px;
      padding-right: 40px;
    }
    h2 {
      font-weight: ${({ theme }) => theme.bold};
    }
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  @media ${device.toTablet} {
    section {
      flex-direction: column;
      address {
        margin-top: 10px;
      }
    }
  }
  @media ${device.desktop} {
    padding: 0 50px;
  }
`;

const Location = () => {
  const { currentLanguage } = useSelector((state) => state.global);

  return (
    <div className='pd-flex-center'>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  multiLanguage['판다코리아닷컴 오시는길'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <BranchWrapper>
        <Collapse
          defaultActiveKey={['1']}
          // onChange={(key) => console.log(key)}
          expandIconPosition={'right'}
        >
          <Panel
            header={<h1>{multiLanguage['서울 본사'][currentLanguage]}</h1>}
            key='1'
          >
            <Branch>
              <section>
                <address>
                  <h2>Adress.</h2>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        multiLanguage[
                          '서울특별시 성동구 성수동1가 상원1길 26 4층 서울숲 A타워 4층 402-404호'
                        ][currentLanguage],
                    }}
                  />
                </address>
                <address>
                  <h2>Tel.</h2>
                  070 - 5038 - 3858
                </address>
                <address>
                  <h2>Fax.</h2>
                  0505 - 055 - 6800
                </address>
              </section>
              <figure>
                <img src={map} alt='map' />
              </figure>
            </Branch>
          </Panel>
          <Panel
            header={<h1>{multiLanguage['인천 물류센터'][currentLanguage]}</h1>}
            key='2'
          >
            <Branch>
              <section>
                <address>
                  <h2>Adress.</h2>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        multiLanguage[
                          '인천광역시 서구 북항로 193번길 46 B동 1층(판다코리아 물류센터)'
                        ][currentLanguage],
                    }}
                  />
                </address>
                <address>
                  <h2>Tel.</h2>
                  070 - 5038 - 3858
                </address>
                <address>
                  <h2>Fax.</h2>
                  0505 - 055 - 6800
                </address>
              </section>
              <figure>
                <img src={incheon} alt='incheon' />
              </figure>
            </Branch>
          </Panel>
          <Panel
            header={<h1>{multiLanguage['상해 지사'][currentLanguage]}</h1>}
            key='3'
          >
            <Branch>
              <section>
                <address>
                  <h2>Adress.</h2>
                  {
                    multiLanguage['상해시 민항구 오중로 1100호 1013실'][
                      currentLanguage
                    ]
                  }
                </address>
                <address>
                  <h2>Tel.</h2>
                  070 - 5038 - 3858
                </address>
                <address>
                  <h2>Fax.</h2>
                  0505 - 055 - 6800
                </address>
              </section>
            </Branch>
          </Panel>
          <Panel
            header={
              <h1>
                {multiLanguage['연변 지사(소프트웨어 개발)'][currentLanguage]}
              </h1>
            }
            key='4'
          >
            <Branch>
              <section>
                <address>
                  <h2>Adress.</h2>
                  延边盼达高新技术开发有限公司
                  <br />
                  {
                    multiLanguage['중국 길림성 연길시우의로 363호'][
                      currentLanguage
                    ]
                  }
                </address>
                <address>
                  <h2>Tel.</h2>
                  070 - 5038 - 3858
                </address>
                <address>
                  <h2>Fax.</h2>
                  0505 - 055 - 6800
                </address>
              </section>
            </Branch>
          </Panel>
        </Collapse>
      </BranchWrapper>
    </div>
  );
};

export default Location;
