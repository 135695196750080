import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';

import { CaretDownOutlined } from '@ant-design/icons';

import global from '../../assets/navigation/global-icon.png';
import globalWhite from '../../assets/navigation/global-white-icon.png';
import { device } from '../../styles/mediaQueries';

const GlobalIcon = styled.img`
	object-fit: contain;
`;
const LanguageSelectWrapper = styled.div`
	display: none;
	@media ${device.desktop} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const LanguageSelect = styled(Select)`
	&,
	.ant-select-arrow {
		color: ${({ theme, $isMain, $showSubMenu }) =>
			$isMain ? theme.black : $showSubMenu ? theme.black : theme.white};
	}
`;
function Languages({ isMain, showSubMenu, onChangeLang, currentLanguage }) {
	return (
		<LanguageSelectWrapper>
			<GlobalIcon
				src={isMain ? global : showSubMenu ? global : globalWhite}
				alt='language icon'
			/>
			<LanguageSelect
				suffixIcon={<CaretDownOutlined />}
				value={currentLanguage}
				bordered={false}
				$isMain={isMain}
				$showSubMenu={showSubMenu}
				onChange={onChangeLang}
			>
				<LanguageSelect.Option value='KR'>KR</LanguageSelect.Option>
				<LanguageSelect.Option value='EN'>EN</LanguageSelect.Option>
				<LanguageSelect.Option value='CN'>CN</LanguageSelect.Option>
			</LanguageSelect>
		</LanguageSelectWrapper>
	);
}

export default Languages;
