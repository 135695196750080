import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'antd';

import { device } from '../../../styles/mediaQueries';
import {
  BackgroundLightGray,
  FontWeightDemiLite,
} from '../../../styles/commonStyle';
import leftIcon from '../../../assets/slide-icon-left.png';
import rightIcon from '../../../assets/slide-icon-right.png';
import { logos, photos } from '../../../data/batterySliders';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 44px;
  .ant-carousel {
    max-width: 270px;
    .slick-prev,
    .slick-next,
    .slick-prev:hover,
    .slick-next:hover,
    .slick-prev:focus,
    .slick-next:focus {
      top: 35%;
      width: 35px;
      height: 35px;
      z-index: 20;
    }
    .slick-prev {
      left: -17%;
    }

    .slick-next {
      right: -15%;
    }
  }
  @media ${device.desktop} {
    margin-top: 70px;
    .ant-carousel {
      padding-left: 50px;
      max-width: 1300px;
      .slick-prev,
      .slick-next,
      .slick-prev:hover,
      .slick-next:hover,
      .slick-prev:focus,
      .slick-next:focus {
        width: 66px;
        height: 66px;
      }
      .slick-prev {
        left: -10%;
      }
      .slick-next {
        right: -5%;
      }
    }
  }
`;

const LogoContainer = styled.figure`
  width: 150px !important;
  height: 150px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${BackgroundLightGray}

  @media ${device.toTablet} {
    width: 75px !important;
    height: 75px;
    img {
      max-width: 50px;
    }
  }
`;

function Arrow(props) {
  const { className, style, onClick, image } = props;
  return (
    <img
      src={image}
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}
const LogoSlide = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    draggable: false,
    centerMode: false,
    variableWidth: false,
    nextArrow: <Arrow image={rightIcon} />,
    prevArrow: <Arrow image={leftIcon} />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1247,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          draggable: true,
        },
      },
    ],
  };
  return (
    <Container>
      <Carousel {...settings}>
        {logos.map((image, index) => (
          <LogoContainer key={index}>
            <img src={image} alt='logo' />
          </LogoContainer>
        ))}
      </Carousel>
    </Container>
  );
};

export default LogoSlide;
