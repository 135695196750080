import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Apply from '../components/recruit/Apply';
import ApplyDetail from '../components/recruit/ApplyDetail';
import Career from '../components/recruit/Career';
import useWindowSize from '../hooks/useWindowSize';
import useWindowPosition from '../hooks/useWindowPosition';

const Recruit = () => {
  let { path } = useRouteMatch();
  const { currentLanguage } = useSelector((state) => state.global);
  const { isDesktop } = useWindowSize();
  const { moveYScroll } = useWindowPosition();
  React.useEffect(() => {
    moveYScroll();
  }, [moveYScroll]);
  return (
    <Switch>
      <Route path={`${path}/career`}>
        <Career currentLanguage={currentLanguage} isDesktop={isDesktop} />
      </Route>
      <Route exact path={`${path}/apply`}>
        <Apply currentLanguage={currentLanguage} />
      </Route>
      <Route path={`${path}/apply/:contentId`}>
        <ApplyDetail currentLanguage={currentLanguage} />
      </Route>
    </Switch>
  );
};

export default Recruit;
