import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../../styles/mediaQueries';

import CommonButton from '../buttons/CommonButton';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 25.6px 0 20px;
  @media ${device.tablet} {
    padding: 60px 0 50px 0;
  }
  @media ${device.desktop} {
    width: 18.4375rem;
    height: 20rem;
    padding: 3.0375rem 0 1.73125rem 0;
  }
  border: solid 1px #cbcbcb;
`;

const Icon = styled.img`
  object-fit: contain;
  margin-bottom: 5px;
  @media ${device.mobile} {
    height: 48.21px;
    margin-bottom: 5px;
  }
  @media ${device.tablet} {
    height: 113px;
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-weight: ${({ theme }) => theme.bold};
    text-align: center;
    font-size: 1.3rem;
    line-height: 1;
    margin-bottom: 33px;

    @media ${device.mobile} {
      font-size: 0.8rem;
      margin-bottom: 15px;
    }
    @media ${device.desktop} {
      font-size: 0.875rem;
      margin-bottom: 15px;
      font-weight: ${({ theme }) => theme.normal};
    }
  }
`;

const MainText = styled.p`
  font-size: 1.375rem;
  line-height: 2rem;
  font-weight: ${({ theme }) => theme.bold};
  @media ${device.toTablet} {
    display: none;
  }
`;

const ServiceCard = ({ mainText, subText, icon, path }) => {
  const history = useHistory();
  return (
    <CardWrapper>
      <MainText>{mainText.toUpperCase()}</MainText>
      <Icon src={icon} />
      <ButtonWrapper>
        <p>{subText}</p>
        <CommonButton onClick={() => history.push(path)} />
      </ButtonWrapper>
    </CardWrapper>
  );
};

export default ServiceCard;
