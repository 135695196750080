import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Col } from 'antd';
import Navigation from './Navigation';
import MainHeaderSlide from './MainHeaderSlide';
import SubHeaderTab from './SubHeaderTab';
import HamburgerMenus from './HamburgerMenus';
import MobileHamburgerMenus from './MobileHamburgerMenus';
import Languages from '../components/Languages';
import useWindowSize from '../../hooks/useWindowSize';

import {
  Wrapper,
  HeaderLogo,
  HeaderWrapper,
  HeaderRightIcons,
  MenuIcon,
  HeaderMenuWrapper,
} from '../../styles/MainHeader';

import logo from '../../assets/main/header-logo.png';
import logoWhite from '../../assets/main/logo-white.png';
import menu from '../../assets/navigation/menu-icon.png';
import menuWhite from '../../assets/navigation/menu-white-icon.png';
import useElementObserver from '../../hooks/useElementObserver';

const MainHeader = ({
  pathname,
  showBlackBackground,
  onShowBlackBackground,
  sliderList,
}) => {
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((state) => state.global);
  const [cookies, setCookie] = useCookies(['currentLanguage']);
  const { isDesktop } = useWindowSize();
  const isMain = pathname === '/';

  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  useEffect(() => {
    if (cookies.currentLanguage) {
      dispatch({
        type: 'SET_LANGUAGE',
        payload: cookies.currentLanguage,
      });
    }
  }, [cookies.currentLanguage, dispatch]);

  useEffect(() => {
    setShowHamburgerMenu(false);
    setShowSubMenu(false);
  }, [pathname]);

  const handleShowSubMenu = (value) => {
    setShowSubMenu(value);
  };
  const handleShowHamburgerMenu = (value) => {
    setShowHamburgerMenu(value);
  };

  const handleChangeLang = (value) => {
    setCookie('currentLanguage', value, {});
    dispatch({
      type: 'SET_LANGUAGE',
      payload: value,
    });
  };

  const [containerRef, isScrollTop] = useElementObserver(
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.9,
    },
    isMain && !isDesktop
  );
  return (
    <>
      <Wrapper $isMain={isMain}>
        <div ref={containerRef} /> {/* scroll top check */}
        <HeaderWrapper>
          <HeaderMenuWrapper
            $isMain={isMain}
            $showSubMenu={showSubMenu}
            isScrollTop={isScrollTop}
          >
            <Col xs={6} sm={6} md={6} lg={3} xl={3}>
              <Link to='/' className='header-top'>
                <HeaderLogo
                  src={!isMain && !showSubMenu && isDesktop ? logoWhite : logo}
                  alt='panda logo'
                />
              </Link>
            </Col>
            <Col xs={16} sm={16} md={16} lg={16} xl={16}>
              <Navigation
                isMain={isMain}
                onShowSubMenu={handleShowSubMenu}
                showSubMenu={showSubMenu}
                currentLanguage={currentLanguage}
              />
            </Col>
            <Col xs={2} sm={2} md={2} lg={5} xl={5}>
              <HeaderRightIcons>
                <Languages
                  isMain={isMain}
                  showSubMenu={showSubMenu}
                  onChangeLang={handleChangeLang}
                  currentLanguage={currentLanguage}
                />
                <MenuIcon
                  src={
                    isMain
                      ? menu
                      : showSubMenu
                      ? menu
                      : isDesktop
                      ? menuWhite
                      : menu
                  }
                  onClick={() => setShowHamburgerMenu(true)}
                />
              </HeaderRightIcons>
            </Col>
          </HeaderMenuWrapper>
          {isMain ? (
            <MainHeaderSlide
              sliderList={sliderList}
              isDesktop={isDesktop}
              currentLanguage={currentLanguage}
            />
          ) : (
            <SubHeaderTab
              isDesktop={isDesktop}
              pathname={pathname}
              currentLanguage={currentLanguage}
              showBlackBackground={showBlackBackground}
              onShowBlackBackground={onShowBlackBackground}
            />
          )}
        </HeaderWrapper>
      </Wrapper>
      {isDesktop ? (
        <HamburgerMenus
          isMain={isMain}
          onShowSubMenu={handleShowSubMenu}
          showHamburgerMenu={showHamburgerMenu}
          currentLanguage={currentLanguage}
          onShowHamburgerMenu={handleShowHamburgerMenu}
        />
      ) : (
        <MobileHamburgerMenus
          onShowSubMenu={handleShowSubMenu}
          showHamburgerMenu={showHamburgerMenu}
          currentLanguage={currentLanguage}
          onChangeLang={handleChangeLang}
          onShowHamburgerMenu={handleShowHamburgerMenu}
        />
      )}
    </>
  );
};

export default MainHeader;
