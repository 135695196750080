import React from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';

import TitleWithIcon from '../../texts/TitleWithIcon';

import multiLanguage from '../../../assets/multiLanguage.json';
import { device } from '../../../styles/mediaQueries';
import { timeline } from '../../../data/timeline';

const Wrapper = styled.div`
  width: 100%;
`;

const dotProperty = `
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  background-color: #f8322f;
  border-radius: 50%;
`;

const DotDivider = styled(Divider)`
  position: relative;
  background-color: #d6d6d6;
  height: 1px;
  @media ${device.toTablet} {
    margin: 20px 0;
  }
  @media ${device.desktop} {
    margin-left: -20px;
    &::before {
      ${dotProperty}
    }
  }
`;

const Image = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  object-fit: contain;
  img {
    padding: 0 4.2vw;
    height: 33.2vw;
  }
  @media ${device.toTablet} {
    margin-bottom: 20px;
  }
  @media ${device.desktop} {
    justify-content: flex-end;
    img {
      margin-top: 60px;
      padding: 0 40px 0 0;
      height: 230px;
    }
  }
`;

const TimeLineWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media ${device.toTablet} {
    h2 {
      font-size: 0.9375rem;
      font-weight: 500;
    }
    h1 {
      font-size: 1.0625rem;
    }
    article {
      padding: 0 4.2vw;
    }
    section {
      margin-bottom: 50px;
    }
    h3 {
      font-size: 1.375rem;
      margin-bottom: 20px;
    }
    aside {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      h3,
      span {
        font-weight: ${({ theme }) => theme.bold};
      }
      span,
      p {
        font-size: 0.875rem;
        line-height: 2;
      }
      p {
        margin-left: 15px;
        width: 80vw;
        color: ${({ theme }) => theme.textGray};
      }
    }
  }
  @media ${device.desktop} {
    line-height: 2.5;
    h3,
    span {
      font-weight: ${({ theme }) => theme.bold};
    }
    h3 {
      font-size: 2rem;
    }
    li {
      display: flex;
      justify-content: flex-start;
    }
    span {
      padding-right: 1.125rem;
      line-height: 1.75;
    }
    section {
      padding-bottom: 150px;
      aside {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
        p {
          width: 500px;
          line-height: 1.75;
          letter-spacing: -0.8px;
        }
      }
    }
    li:nth-child(odd) {
      flex-direction: row-reverse;
      ${Image} {
        justify-content: flex-start;
        img {
          padding: 0 0 0 40px;
        }
      }
      article {
        display: flex;
        justify-content: flex-end;
      }
      h3 {
        text-align: right;
      }
      section > aside {
        display: flex;
        flex-direction: row-reverse;
        p {
          text-align: right;
        }
        span {
          padding-right: unset;
          padding-left: 1.125rem;
        }
      }
      ${DotDivider} {
        margin-left: 20px;
        &::before {
          content: none;
        }
        &::after {
          ${dotProperty}
          left: unset;
          right: -5px;
        }
      }
    }
  }
`;

const Tail = styled.div`
  display: none;
  @media ${device.desktop} {
    display: block;
    width: 1px;
    margin: -15px 20px 0;
    border-left: 1px solid #e5e5e5;
  }
`;

const Timeline = ({ isDesktop }) => {
  const { currentLanguage } = useSelector((state) => state.global);
  return (
    <Wrapper>
      <TimeLineWrapper>
        {timeline.map((time, index) => {
          const isOdd = !!(Number(time.year) % 2);
          return (
            <li key={time.year}>
              <Image>
                <ScrollAnimation
                  animateIn={isOdd ? 'fadeInRight' : 'fadeInLeft'}
                  duration={1}
                  animateOnce={true}
                >
                  <img src={time.image} alt={time.year} />
                </ScrollAnimation>
              </Image>
              <Tail />
              <div style={{ flex: 1 }}>
                <ScrollAnimation
                  animateIn={isOdd ? 'fadeInLeft' : 'fadeInRight'}
                  duration={1}
                  animateOnce={true}
                >
                  <article>
                    <TitleWithIcon
                      left={isDesktop ? isOdd : false}
                      title={multiLanguage[time.title][currentLanguage]}
                      subTitle={
                        multiLanguage[time.subTitle]
                          ? multiLanguage[time.subTitle][currentLanguage]
                          : time.subTitle
                      }
                      image={time.icon}
                    />
                  </article>
                </ScrollAnimation>
                <DotDivider />
                <article>
                  <ScrollAnimation
                    animateIn={
                      Number(time.year) % 2 ? 'fadeInLeft' : 'fadeInRight'
                    }
                    duration={1}
                    animateOnce={true}
                  >
                    <section>
                      <h3>{time.year}</h3>
                      {multiLanguage['연혁 페이지'][time.year].map((item) => (
                        <aside
                          key={item[currentLanguage]}
                          dangerouslySetInnerHTML={{
                            __html: item[currentLanguage],
                          }}
                        />
                      ))}
                    </section>
                  </ScrollAnimation>
                </article>
              </div>
            </li>
          );
        })}
      </TimeLineWrapper>
    </Wrapper>
  );
};

export default Timeline;
