import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  PlusCircleFilled,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  FooterWrapper,
  FooterLogo,
  FooterTextWrapper,
  FooterTextUl,
  FooterTextLi,
  FooterDivider,
  MobileFamilySite,
} from '../../styles/Footer';

import multiLanguage from '../../assets/multiLanguage.json';
import useWindowSize from '../../hooks/useWindowSize';
import { URL } from '../../data/constants';
import logo from '../../assets/main/footer-logo.png';

const Footer = () => {
  const [showFamilySite, setShowFamilySite] = useState(false);
  const { currentLanguage } = useSelector((state) => state.global);
  const { isDesktop } = useWindowSize();
  return (
    <FooterWrapper>
      {isDesktop ? (
        <FooterLogo src={logo} />
      ) : (
        <MobileFamilySite showFamilySite={showFamilySite}>
          <div className={showFamilySite ? 'show' : 'hide'}>
            <a
              href={URL.PIGGY_1}
              alt='battery piggy'
              target='_blank'
              rel='noopener noreferrer'
            >
              충전돼지
            </a>
          </div>
          <div
            className={showFamilySite ? 'bold' : ''}
            onClick={() => setShowFamilySite(!showFamilySite)}
          >
            Family Site {showFamilySite ? <CloseOutlined /> : <PlusOutlined />}
          </div>
        </MobileFamilySite>
      )}
      <FooterTextWrapper>
        <FooterTextUl>
          <FooterTextLi className={isDesktop ? '' : 'black'}>
            {multiLanguage['상호 : (주) 판다코리아닷컴'][currentLanguage]}
          </FooterTextLi>
          <FooterTextLi>
            {
              multiLanguage[
                `주소 : 서울시 성동구${isDesktop ? '' : ' Mobile'}`
              ][currentLanguage]
            }
          </FooterTextLi>
          <FooterTextLi>
            {
              multiLanguage[
                `사업자등록번호 : 206-86-90915${isDesktop ? '' : ' Mobile'}`
              ][currentLanguage]
            }
          </FooterTextLi>
          {!isDesktop && (
            <FooterTextLi>
              {
                multiLanguage['통신판매업신고번호 : 2014-서울성동-0457호'][
                  currentLanguage
                ]
              }
            </FooterTextLi>
          )}
        </FooterTextUl>
        <FooterTextUl>
          <FooterTextLi>
            Copyright © PANDAKOREA. ALL RIGHTS RESERVED.
          </FooterTextLi>
        </FooterTextUl>
      </FooterTextWrapper>
      {isDesktop && (
        <>
          <FooterDivider type='vertical' />
          <FooterTextWrapper>
            <FooterTextUl>
              <FooterTextLi>Email : contact@pandakorea.com</FooterTextLi>
              <FooterTextLi>Tel : 070-4866-5831</FooterTextLi>
              <FooterTextLi>Fax : 0505-055-6800</FooterTextLi>
            </FooterTextUl>
            <FooterTextUl>
              <FooterTextLi>
                FAMILY SITE <PlusCircleFilled />
              </FooterTextLi>
            </FooterTextUl>
          </FooterTextWrapper>
        </>
      )}
    </FooterWrapper>
  );
};

export default Footer;
