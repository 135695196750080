import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../styles/mediaQueries';
import arrowIcon from '../../assets/main/arrow-view-more-icon.png';
import arrowIconWhite from '../../assets/main/arrow-view-more-white-icon.png';

const MoreButton = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
  max-width: ${({ noAnimation }) => (noAnimation ? 95 : 25)}px;
  height: 25px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  border: solid 1px ${({ white, theme }) => (white ? theme.white : '#0f0f0f')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ white, theme }) => (white ? theme.white : '#0f0f0f')};
  background: url(${({ white }) => (white ? arrowIconWhite : arrowIcon)})
    no-repeat center;
  font-size: 0.8rem;
  ${({ noAnimation }) =>
    noAnimation &&
    css`
      background-position: 10% 50%;
      &::before {
        display: block;
        padding-left: 15px;
        content: 'View';
      }
      &::after {
        display: block;
        padding-left: 5px;
        content: 'More';
      }
    `}

  &:hover {
    max-width: 95px;
    background-position: 10% 50%;
    &::before {
      display: block;
      padding-left: 15px;
      content: 'View';
    }
    &::after {
      display: block;
      padding-left: 5px;
      content: 'More';
    }
  }
  a {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  @media ${device.tablet} {
    max-width: ${({ noAnimation }) => (noAnimation ? 189 : 50)}px;
    height: 50px;
    font-size: 1.375rem;
  }
  @media ${device.desktop} {
    max-width: 44px;
    height: 44px;
    &:hover {
      max-width: 140px;
      &::before {
        padding-left: 5px;
      }
    }
  }
`;

const ViewMoreButton = ({ noAnimation, white, path }) => {
  return (
    <MoreButton white={white} noAnimation={noAnimation}>
      <a href={path} target='_blank' rel='noopener noreferrer'>
        <i className='fa fa-phone' aria-hidden='true'></i>{' '}
      </a>
    </MoreButton>
  );
};

export default ViewMoreButton;
