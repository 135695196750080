import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../styles/mediaQueries';
import {
  FontWeightDemiLite,
  ColorRed,
  FontWeightMedium,
  FontWeightThin,
  FlexColCenter,
} from '../../../../styles/commonStyle';

const CompetitiveItemStyled = styled.article`
  display: flex;
  justify-content: space-between;
  text-align: center;
  h5 {
    position: relative;
    ${FlexColCenter}
    margin-top: 23px;
    text-align: center;
    font-size: 1.90625rem;
    ${FontWeightDemiLite}
    ::before {
      counter-increment: article;
      content: '0' counter(article);
      font-size: 100px;
      ${FontWeightMedium}
      letter-spacing: -7px;
      ${ColorRed}
    }
    ::after {
      content: '';
      position: absolute;
      top: 39%;
      left: 66%;
      width: 0;
      height: 0;
      border-bottom: 45px solid ${({ theme }) => theme.lightRed};
      border-left: 26px solid transparent;
      border-right: 10px solid transparent;
    }
  }
  p {
    width: 210px;
    padding-top: 15.8px;
    font-size: 0.9rem;
    ${FontWeightThin}
    letter-spacing: -0.6px;
  }
  @media ${device.toTablet} {
    align-items: center;
    h5 {
      width: 104px;
      height: 105px;
      margin-top: 0;
      font-size: 15.25px;
      ::before {
        font-size: 72.5px;
        margin-bottom: 5px;
      }
      ::after {
        top: 22.5%;
        left: 71%;
      }
    }
    p {
      width: 150px;
      padding-top: 0;
      text-align: left;
      font-size: 11.5px;
      line-height: 2;
    }
  }
  @media ${device.desktop} {
    flex-direction: column;
    align-items: center;
    width: 380px;
    height: 366px;
    p {
      width: 272px;
      min-height: 176px;
      font-size: 18px;
    }
  }
`;

const CompetitiveItem = ({ title, text }) => {
  return (
    <CompetitiveItemStyled>
      <h5>{title}</h5>
      <p>{text}</p>
    </CompetitiveItemStyled>
  );
};

export default CompetitiveItem;
