import React from 'react';
import styled from 'styled-components';
import multiLanguage from '../../../../assets/multiLanguage.json';
import icon06 from '../../../../assets/business/ecommerce/ecommerce-icon-06.png';
import icon07 from '../../../../assets/business/ecommerce/ecommerce-icon-07.png';
import icon08 from '../../../../assets/business/ecommerce/ecommerce-icon-08.png';
import icon09 from '../../../../assets/business/ecommerce/ecommerce-icon-09.png';
import icon10 from '../../../../assets/business/ecommerce/ecommerce-icon-10.png';
import icon11 from '../../../../assets/business/ecommerce/ecommerce-icon-11.png';
import { device } from '../../../../styles/mediaQueries';
import {
  FontWeightThin,
  FontWeightDemiLite,
  FontWeightMedium,
  FontWeightBold,
  ColorTextRed,
  ColorOrange,
} from '../../../../styles/commonStyle';

const Wrapper = styled.div`
  width: 1247px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.toTablet} {
    width: 100%;
  }
`;
const ManagementWrapper = styled.div`
  width: 100%;
  height: 761px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: start;
  h6 {
    font-size: 1.5rem;
    ${FontWeightBold}
    ${ColorOrange}
  }
  p {
    margin-left: 23px;
    font-size: 1.25rem;
    ${FontWeightMedium}
    ${ColorOrange}
  }
  img {
    align-self: center;
    object-fit: contain;
  }
  section:nth-of-type(odd) {
    p,
    h6 {
      ${ColorTextRed}
    }
  }

  section {
    min-width: 182px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    figure {
      height: 125px;
      margin-bottom: 40px;
    }
    div {
      display: flex;
      margin-bottom: 43px;
    }
    blockquote {
      margin-bottom: 22px;
      font-size: 14px;
      ${FontWeightDemiLite}
      letter-spacing: -1px;
      overflow-wrap: break-word;
      word-break: break-word;
    }
  }
`;

const MobileList = styled.article`
  display: grid;
  align-items: center;
  padding: 18px 9vw 18px 7vw;
  section {
    display: flex;
    margin-bottom: 42px;
  }
  img {
    width: 67px;
    margin-right: 32px;
  }
  section:nth-of-type(odd) {
    p,
    h6 {
      ${ColorTextRed}
    }
  }
  h6 {
    margin-bottom: 10px;
    font-size: 0.9375rem;
    ${FontWeightDemiLite}
    ${ColorOrange}
  }
  blockquote {
    font-size: 0.875rem;
    ${FontWeightThin}
    line-height: 1.3;
    color: ${({ theme }) => theme.textGray};
  }
`;

const icons = [icon06, icon07, icon08, icon09, icon10, icon11];
const managementList = [
  'Marketing',
  'Operation',
  'CS',
  'Design',
  'Analysis',
  'Distribution',
];
const Management = ({ currentLanguage, isDesktop }) => {
  return (
    <Wrapper>
      {isDesktop ? (
        <ManagementWrapper>
          {managementList.map((title, index) => (
            <section key={index}>
              <figure>
                <img src={icons[index]} alt='mobile icon' />
              </figure>
              <div>
                {currentLanguage !== 'EN' && (
                  <h6>{multiLanguage[title][0][currentLanguage]}</h6>
                )}
                <p>{title}</p>
              </div>
              {multiLanguage[title][1].map((item) => (
                <blockquote key={item[currentLanguage]}>
                  {item[currentLanguage]}
                </blockquote>
              ))}
            </section>
          ))}
        </ManagementWrapper>
      ) : (
        <MobileList>
          {managementList.map((title, index) => (
            <section key={title}>
              <img src={icons[index]} alt='mobile icon' />
              <div>
                <h6>{multiLanguage[title][0][currentLanguage]}</h6>
                <blockquote>
                  {multiLanguage[title][1].map(
                    (item, index) =>
                      `${index ? ', ' : ''}${item[currentLanguage]}`
                  )}
                </blockquote>
              </div>
            </section>
          ))}
        </MobileList>
      )}
    </Wrapper>
  );
};

export default Management;
