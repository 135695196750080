import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetData } from '../action/api';

const LIMIT = 6; // 로딩시 보여지는 채용 아이템 갯수
const useRecruitData = (currentLanguage) => {
  const [showLoadMore, setShowLoadMore] = useState(LIMIT);
  const list = useSelector((state) => state.global.recruitList);
  const dispatch = useDispatch();
  const handleShowMore = () => {
    setShowLoadMore((prevNumber) => prevNumber + LIMIT);
  };
  React.useEffect(() => {
    const getData = async (currentLanguage) => {
      try {
        const recruit = await handleGetData('getRecruitList', {
          params: { PK: 'recruit', language: currentLanguage },
        });
        if (recruit.Items)
          dispatch({
            type: 'SET_RECRUIT_LIST',
            payload: recruit.Items,
          });
      } catch (error) {
        console.error(error);
      }
    };
    getData(currentLanguage);
  }, [currentLanguage, dispatch]);

  const showButton = list.length > showLoadMore;

  return { list, showLoadMore, showButton, handleShowMore };
};

export default useRecruitData;
