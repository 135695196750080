import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import MainLayout from './layout/MainLayout';
import Main from './views/Main';
import Company from './views/Company';
import Recruit from './views/Recruit';
import Contact from './views/Contact';
import Business from './views/Business';
import Information from './views/Information';

import theme from './styles/theme';
import './styles/less/index.less';
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <MainLayout>
            <Route path='/business' component={Business} />
            <Route path='/information' component={Information} />
            <Route path='/contact' component={Contact} />
            <Route path='/recruit' component={Recruit} />
            <Route path='/company' component={Company} />
            <Route exact path='/' component={Main} />
          </MainLayout>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
