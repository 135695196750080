import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import ContentBox from './components/ContentBox';
import BackgroundBox from './components/BackgroundBox';
import Button from '../buttons/Button';
import LogoSlide from './components/LogoSlide';
import PhotoSlide from './components/PhotoSlide';

import multiLanguage from '../../assets/multiLanguage.json';

import battery1 from '../../assets/business/battery/battery-image-01.png';
import battery2 from '../../assets/business/battery/battery-image-02.png';

import { FlexColCenter, Subtitle } from '../../styles/commonStyle';
import AppButtonGroup from './components/AppButtonGroup';
import { URL } from '../../data/constants';

const SlidersContainer = styled.section`
  ${FlexColCenter}
`;

const BatteryPiggy = ({ currentLanguage, isDesktop }) => {
  return (
    <div>
      <Row justify='center'>
        <ContentBox
          mobileCenter
          mobileTextCenter
          titleBold
          margin={{
            marginTop: isDesktop ? 65 : 0,
            marginBottom: isDesktop ? 388 : 187,
          }}
          text={
            multiLanguage['충전이 필요할 때, 근처에 위치한 충전돼지 DESC'][
              currentLanguage
            ]
          }
          bigImg={battery1}
          button={
            <AppButtonGroup
              isDesktop={isDesktop}
              currentLanguage={currentLanguage}
              homeText={'충전돼지 홈페이지'}
              homeUrl={URL.PIGGY}
              appleUrl={URL.PIGGY_APPLE}
              googleUrl={URL.PIGGY_GOOGLE}
            />
          }
        >
          {
            multiLanguage['2% 남은 배터리를 위해 보조배터리 공유서비스'][
              currentLanguage
            ]
          }
        </ContentBox>
        <BackgroundBox bottom={isDesktop ? 15 : null} />
      </Row>

      <Row justify='center' align='middle'>
        <ContentBox
          mobileCenter
          mobileTextCenter
          margin={{ marginBottom: isDesktop ? 520 : 206 }}
          reverse
          alignItems={'end'}
          text={
            multiLanguage[
              '필요한 순간에 원하는 장소에서 필요한 만큼 충전이 가능하고 DESC'
            ][currentLanguage]
          }
          bigImg={battery2}
          button={<div style={{ paddingBottom: isDesktop ? 120 : 0 }} />}
        >
          {multiLanguage['충전돼지 서비스 특징'][currentLanguage]}
        </ContentBox>
      </Row>
      <SlidersContainer>
        <WithTopDivider marginBottom={'24px'}>
          <SubHeading>
            {multiLanguage['주요 제휴 설치점'][currentLanguage]}
          </SubHeading>
        </WithTopDivider>
        <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
          <Subtitle>
            {`${
              multiLanguage[
                `대형 상업시설 호텔, 주점 형식의 대학교, DESC${
                  isDesktop ? '' : ' Mobile'
                }`
              ][currentLanguage]
            }`}
          </Subtitle>
        </ScrollAnimation>
        <LogoSlide />
        <PhotoSlide currentLanguage={currentLanguage} />
      </SlidersContainer>
    </div>
  );
};

export default BatteryPiggy;
