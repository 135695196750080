import React from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { device } from '../../styles/mediaQueries';

const LoadItems = styled.div`
  margin-top: 5.625rem;
  margin-bottom: 50px;
  @media ${device.desktop} {
    margin-bottom: 11.5625rem;
  }
  span {
    display: inline-block;
    cursor: pointer;
    width: 58px;
    height: 58px;
    line-height: 58px;
    border-radius: 50%;
    color: #ffffff;
    background-color: ${(props) => props.theme.red};
  }
`;
const LoadItemButton = ({ onClick }) => {
  return (
    <LoadItems onClick={onClick}>
      <span>
        <PlusOutlined style={{ fontSize: 20 }} />
      </span>
    </LoadItems>
  );
};

export default LoadItemButton;
