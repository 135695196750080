import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';

import MainHeader from './components/MainHeader';
import Footer from './components/Footer';
import { device } from '../styles/mediaQueries';
import PopUp from '../components/common/PopUp';
import Portal from '../components/common/Portal';

const { Content } = Layout;
const LayoutContainer = styled(Layout)`
  background-color: #ffffff;
  overflow-x: hidden;
`;
const ContentContainer = styled(Content)`
  @media ${device.desktop} {
    position: relative;
    padding-top: 146px;
    z-index: 20;
  }
`;
const BackgroundBlack = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${({ showPopUp }) => (showPopUp ? 25 : 21)};
`;
const MainLayout = ({ children }) => {
  const [showBlackBackground, setShowBlackBackground] = useState(true);
  const [showPopUp, setShowPopup] = useState(true);
  const [cookies] = useCookies(['pandaPopup']);
  const { currentLanguage, popupList, sliderList } = useSelector(
    (state) => state.global
  );
  const handleShowBlackBackground = (value) => {
    setShowBlackBackground(value);
  };
  let { pathname } = useLocation();
  useEffect(() => {
    if (pathname !== '/' && !showPopUp) return;
    if (
      pathname === '/' &&
      !cookies.pandaPopup &&
      popupList[currentLanguage].length > 0
    )
      handleShowPopup(true);
    else handleShowPopup(false);
  }, [pathname, cookies, popupList, currentLanguage, showPopUp]);

  const handleShowPopup = (value) => {
    setShowPopup(value);
    setShowBlackBackground(value);
  };
  return (
    <LayoutContainer>
      {showBlackBackground && (
        <BackgroundBlack
          showPopUp={showPopUp}
          onClick={() => handleShowPopup(false)}
        />
      )}
      {showPopUp && popupList[currentLanguage].length > 0 && (
        <Portal>
          <PopUp
            image={popupList[currentLanguage]}
            onShowPopup={handleShowPopup}
          />
        </Portal>
      )}
      <MainHeader
        sliderList={sliderList}
        pathname={pathname}
        showBlackBackground={showBlackBackground}
        onShowBlackBackground={handleShowBlackBackground}
      />
      <ContentContainer>
        <div className='site-layout-content'>{children}</div>
      </ContentContainer>
      <Footer />
    </LayoutContainer>
  );
};

export default MainLayout;
