import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/mediaQueries';

const Wrapper = styled.span`
  font-size: 0.9375rem;
  font-weight: ${({ strong, theme }) => (strong ? theme.bold : theme.demiLite)};
  line-height: 1.5;
  @media ${device.desktop} {
    max-width: 820px;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.5625rem')};
  }
`;

const SubHeading = ({ children, strong, fontSize }) => {
  return (
    <Wrapper strong={strong} fontSize={fontSize}>
      {children}
    </Wrapper>
  );
};

export default SubHeading;
