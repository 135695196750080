import React from 'react';
import { Row } from 'antd';
import { useSelector } from 'react-redux';

import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import Timeline from './comonents/Timeline';

import multiLanguage from '../../assets/multiLanguage.json';
import styled from 'styled-components';
import { device } from '../../styles/mediaQueries';
const Container = styled.div`
  @media ${device.desktop} {
    margin-bottom: 200px;
  }
`;
const History = () => {
  const { currentLanguage } = useSelector((state) => state.global);

  return (
    <Container>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: multiLanguage['판다코리아 연혁'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <Row>
        <Timeline />
      </Row>
    </Container>
  );
};

export default History;
