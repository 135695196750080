import React from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import OverlayWIP from '../common/OverlayWIP';
import { WrapperWidth1240 } from '../../styles/commonStyle';
import { device } from '../../styles/mediaQueries';
import multiLanguage from '../../assets/multiLanguage.json';
import taoBao from '../../assets/sub/taobao-guide.png';

const TableWrapper = styled.div`
  ${WrapperWidth1240}
  font-size: 1.0625rem;
  .ant-table-wrapper {
    border-top: solid 2px #0f0f0f;
  }
  .ant-table-cell {
    cursor: pointer;
    :hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  @media ${device.toTablet} {
    margin-top: 0;
    padding: 0 4.2vw;
  }
`;
const Taobao = ({ currentLanguage }) => {
  return (
    <div className='pd-flex-center'>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: multiLanguage['타오바오 입점 가이드'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      {/* <Row justify="center">
        <Search
          placeholder="input search text"
          onSearch={(value) => console.log(value)}
          enterButton
          style={{ width: 510 }}
        />
      </Row> */}
      <TableWrapper>
        {/* <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => history.push(`/information/taobao/${rowIndex}`),
            };
          }}
          showHeader={false}
          pagination={{ position: ['none', 'bottomCenter'] }}
          columns={tableColums}
          dataSource={taobaoGuide}
        /> */}
        <OverlayWIP image={taoBao} currentLanguage={currentLanguage} />
      </TableWrapper>
    </div>
  );
};

export default Taobao;
