import React from 'react';
import styled, { css } from 'styled-components';
import { FontWeightBold } from '../../../../styles/commonStyle';
import productsIcon from '../../../../assets/business/ecommerce/ecommerce-icon-05.png';
import { device } from '../../../../styles/mediaQueries';

const Container = styled.figure`
  position: absolute;
  top: 43%;
  left: 44.3%;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${({ hasBg }) =>
    hasBg &&
    css`
      top: 33%;
      left: 42.5%;
      margin: auto;
      width: 180px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      figcaption {
        font-size: 20px;
      }
      div > img {
        width: 83px;
      }
    `}
  ${FontWeightBold}
  img {
    position: relative !important;
    width: 29px;
    object-fit: contain;
    margin-bottom: 6px;
  }
  @media ${device.toTablet} {
    top: 36%;
    left: 42.5%;
    font-size: 0.625rem !important;
  }
  @media ${device.desktop} {
    img {
      position: relative !important;
      width: 58px;
      object-fit: cover;
      margin-bottom: 6px;
    }
  }
`;
const ProductIcon = ({ hasBg }) => {
  return (
    <Container hasBg={hasBg}>
      <div>
        <img src={productsIcon} alt='product icon' />
        <figcaption>Products</figcaption>
      </div>
    </Container>
  );
};

export default ProductIcon;
