import React from 'react';
import { handleGetData } from '../action/api';
import { useDispatch, useSelector } from 'react-redux';
/**
 * 메인페이지 들어올때 로딩
 * 1. 팝업이미지 (언어별)
 * 2. 뉴스데이터
 * 3. 슬라이더에 사용되는 이미지(언어별)
 */
const useLoadMainData = () => {
  const { currentLanguage, newsList } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  React.useEffect(() => {
    (async () => {
      try {
        const popUp = await handleGetData('getPopup');
        if (popUp.Items)
          dispatch({
            type: 'SET_POPUPLIST',
            payload: popUp.Items.reduce(
              (accumulator, current) => ({
                ...accumulator,
                [current.language]: current.images,
              }),
              {}
            ),
          });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch]);

  React.useEffect(() => {
    (async () => {
      try {
        const sliders = await handleGetData('getImageSlider');

        if (sliders.Items)
          dispatch({
            type: 'SET_SLIDERLIST',
            payload: sliders.Items.reduce(
              (accumulator, current) => ({
                ...accumulator,
                [current.type + current.language]: current.images,
              }),
              {}
            ),
          });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch]);

  React.useEffect(() => {
    (async () => {
      try {
        const news = await handleGetData('getPostList', {
          params: {
            PK: 'board',
            language: currentLanguage,
          },
        });
        if (news.Items) {
          dispatch({
            type: 'SET_NEWSLIST',
            payload: news.Items,
          });
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch, currentLanguage]);

  return { currentLanguage, newsList };
};

export default useLoadMainData;
