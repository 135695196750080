import axios from 'axios';
import { URL } from '../data/constants';

export const handleSendMessage = async (value) => {
  const url = URL.API + 'setContactUs';

  return await axios
    .post(url, value)
    .then(async (response) => {
      return true;
    })
    .catch((e) => {
      console.log(e);
    });
};

export const handleGetData = async (endPoint, params = {}) => {
  const url = URL.API + endPoint;
  return await axios
    .get(url, params)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error(e);
    });
};
