import React from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import { device } from '../../styles/mediaQueries';

const Wrapper = styled(Row)``;

const SubTitle = styled.h2`
  font-size: 1.125rem;
  line-height: 2;
  font-weight: ${({ theme }) => theme.normal};
  color: ${({ theme }) => theme.red};
  ${({ left }) => left && { textAlign: 'right' }};
`;

const Title = styled.h1`
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: ${({ theme }) => theme.normal};
  color: ${({ theme }) => theme.black};
`;

const IconImage = styled.img`
  width: 11.4vw;
  @media ${device.toTablet} {
    margin-left: 10px;
    padding-bottom: 1px;
  }
  @media ${device.desktop} {
    width: 4vw;
    object-fit: cover;
    ${({ left }) => (left ? { marginRight: 10 } : { marginLeft: 10 })}
  }
`;

const TitleWithIcon = ({ title, subTitle, image, left }) => {
  return (
    <Wrapper align='bottom'>
      {left && <IconImage src={image} alt={title} left={left} />}
      <div>
        <SubTitle left={left}>{subTitle}</SubTitle>
        <Title>{title}</Title>
      </div>
      {!left && <IconImage src={image} alt={title} left={left} />}
    </Wrapper>
  );
};

export default TitleWithIcon;
