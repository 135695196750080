import React, { useState, useRef } from 'react';
import { Carousel } from 'antd';
import styled from 'styled-components';
import { WrapperWidth1240 } from '../../styles/commonStyle';
import { device } from '../../styles/mediaQueries';
import arrowLeft from '../../assets/main/arrow-left-icon.png';
import arrowRight from '../../assets/main/arrow-right-icon.png';
import pause from '../../assets/main/pause-icon.png';
import tmall from '../../assets/main/logos/tmall.png';
import taobao from '../../assets/main/logos/taobao.png';
import lazada from '../../assets/main/logos/lazada.png';
import alibaba from '../../assets/main/logos/alibaba.png';
import wechat from '../../assets/main/logos/wechat.png';
import weibo from '../../assets/main/logos/weibo.png';
import posco from '../../assets/main/logos/posco.png';
import kakao from '../../assets/main/logos/kakao.png';
import gfi from '../../assets/main/logos/gfi.png';
import kotra from '../../assets/main/logos/kotra.png';
import nh from '../../assets/main/logos/nh.png';
import samyang from '../../assets/main/logos/samyang.png';
import donga from '../../assets/main/logos/donga.png';
import ilyang from '../../assets/main/logos/ilyang.png';
import sk from '../../assets/main/logos/sk.png';
import cj from '../../assets/main/logos/cj.png';
import at from '../../assets/main/logos/at.png';
import gmarket from '../../assets/main/logos/gmarket.png';
import wemake from '../../assets/main/logos/wemake.png';
import coupang from '../../assets/main/logos/coupang.png';
import naver from '../../assets/main/logos/naver.png';
import tmon from '../../assets/main/logos/tmon.png';
import lg from '../../assets/main/logos/lg.png';
import interpark from '../../assets/main/logos/interpark.png';
import benepia from '../../assets/main/logos/benepia.png';
import sangrock from '../../assets/main/logos/sangrock.png';
import thek from '../../assets/main/logos/thek.png';
import jenedu from '../../assets/main/logos/jenedu.png';
import goonbun from '../../assets/main/logos/goonbun.png';
import altai from '../../assets/main/logos/altai.png';
import onestar from '../../assets/main/logos/onestar.png';
import dashu from '../../assets/main/logos/dashu.png';
import maeil from '../../assets/main/logos/maeil.png';
import greenstore from '../../assets/main/logos/greenstore.png';
import namyang from '../../assets/main/logos/namyang.png';
import st11 from '../../assets/main/logos/11st.png';
import kosme from '../../assets/main/logos/kosme.png';
import kt from '../../assets/main/logos/kt.png';
import sbc from '../../assets/main/logos/sbc.png';
import lghealth from '../../assets/main/logos/lghealth.png';
import sinotrans from '../../assets/main/logos/sinotrans.png';
import alipay from '../../assets/main/logos/alipay.png';
import kolsa from '../../assets/main/logos/kolsa.png';
import jekiss from '../../assets/main/logos/jekiss.png';
import dreamleader from '../../assets/main/logos/dreamleader.png';
import keyeast from '../../assets/main/logos/keyeast.png';
import mss from '../../assets/main/logos/mss.png';
import sm from '../../assets/main/logos/sm.png';
import purito from '../../assets/main/logos/purito.png';
import livinglife from '../../assets/main/logos/livinglife.png';

const logoList = [
  [tmall, taobao, lazada, alibaba, wechat, weibo, posco, kakao, gfi, kotra],
  [nh, samyang, donga, ilyang, sk, cj, at, gmarket, wemake, coupang],
  [naver, tmon, lg, interpark, benepia, sangrock, thek, jenedu, goonbun, altai],
  [onestar, dashu, maeil, greenstore, namyang, st11, kosme, kt, sbc, lghealth],
  [
    sinotrans,
    alipay,
    kolsa,
    jekiss,
    dreamleader,
    keyeast,
    mss,
    sm,
    purito,
    livinglife,
  ],
];

const CarouselWrapper = styled.div`
  .ant-carousel {
    padding-bottom: 5px;
    .slick-dots-bottom {
      bottom: inherit;
    }
    .slick-dots li {
      width: 50px;
      button {
        background: ${({ theme }) => theme.gray};
        opacity: 0.4;
      }
      &.slick-active {
        opacity: 1;
        background: ${({ theme }) => theme.red};
      }
    }
  }
  @media ${device.desktop} {
    .ant-carousel {
      max-width: 1240px;
    }
  }
  @media ${device.toTablet} {
    width: 100vw;
    margin-top: 50px;
  }
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 27.7vw;
  height: 15.2vw;
  border: solid 1px #e2e2e2;
  @media ${device.desktop} {
    width: 220px;
    height: 134px;
  }
`;

const Logo = styled.img`
  object-fit: scale-down;
  max-width: 152px;
  @media ${device.mobile} {
    width: 60px;
  }
  @media ${device.tablet} {
    width: 100px;
  }
`;

const Slide = styled.div`
  ${WrapperWidth1240}
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 330px;
`;

const MobileWrapper = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  ul {
    margin-top: 30px;
    display: flex;
    align-items: center;
    li {
      img {
        height: 3vh;
      }
      .pause {
        margin: 0 25px;
        height: 2.3vh;
      }
    }
  }
  .slick-slider {
    max-width: ${({ width }) => width}px;
  }
`;

const PartnerSlide = ({ isDesktop, width }) => {
  const [autoPlay, setAutoPlay] = useState(true);
  const setting = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: autoPlay,
    autoplaySpeed: 2000,
  };
  const carousel = useRef(null);
  return (
    <CarouselWrapper>
      {isDesktop ? (
        <Carousel>
          {logoList.map((item, index) => {
            return (
              <div key={index}>
                <Slide>
                  {item.map((logo, index) => (
                    <LogoBox key={index}>
                      <Logo src={logo} alt='item' />
                    </LogoBox>
                  ))}
                </Slide>
              </div>
            );
          })}
        </Carousel>
      ) : (
        <MobileWrapper width={width}>
          <Carousel ref={carousel} {...setting}>
            {logoList.flat().map((logo, index) => (
              <div key={index}>
                <LogoBox>
                  <Logo src={logo} alt='item' />
                </LogoBox>
              </div>
            ))}
          </Carousel>
          <ul>
            <li onClick={() => carousel.current.prev()}>
              <img src={arrowLeft} alt='left' />
            </li>
            <li onClick={() => setAutoPlay(!autoPlay)}>
              <img className='pause' src={pause} alt='left' />
            </li>
            <li onClick={() => carousel.current.next()}>
              <img src={arrowRight} alt='left' />
            </li>
          </ul>
        </MobileWrapper>
      )}
    </CarouselWrapper>
  );
};

export default PartnerSlide;
