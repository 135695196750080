import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row } from 'antd';

import { device } from '../../styles/mediaQueries';
import { menus } from '../../data/menus';
import multilanguage from '../../assets/multiLanguage.json';

const HeaderMenu = styled.ul`
  display: none;
  @media ${device.desktop} {
    line-height: 5.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .left {
    text-align: left;
  }

  li {
    width: 11vw;
    text-align: center;
    a,
    button {
      ${({ $isMain, $showSubMenu }) =>
        !$isMain && !$showSubMenu && { color: '#ffffff' }}
      font-weight: ${({ theme }) => theme.medium};
      font-size: 1.1875rem;
    }
    ul li a {
      font-weight: ${({ theme }) => theme.normal};
      font-size: 1.125rem;
    }

    :hover {
      .parent,
      & > a:hover {
        color: ${({ theme }) => theme.red};
      }
      ul {
        border-top: solid 2px ${({ theme }) => theme.red};
      }
    }
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  li {
    display: flex;
    flex-direction: column;
    a:hover,
    li:hover {
      color: ${({ theme }) => theme.red};
    }
  }
`;

export const SubMenu = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  font-size: 1.25rem;
  line-height: 2.5;
  li {
    width: 100%;
  }
`;

export const HambugerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background: ${({ theme }) => theme.red};
  z-index: 200;
`;

export const HeaderMenuWrapper = styled(Row)`
  background-color: ${({ $showSubMenu }) =>
    $showSubMenu ? '#ffffff' : 'inherit'};
  cursor: pointer;
  border-bottom: #fff solid 1px;
  .header-top {
    height: 88px;
    display: flex;
    align-items: center;
  }
`;

const Navigation = ({
  isMain,
  onShowSubMenu,
  showSubMenu,
  currentLanguage,
}) => {
  return (
    <HeaderMenu $isMain={isMain} $showSubMenu={showSubMenu}>
      {menus.map((menu) => (
        <MenuWrapper key={menu.path}>
          <li
            onMouseEnter={() => onShowSubMenu(true)}
            onMouseLeave={() => onShowSubMenu(false)}
          >
            <Link
              to={`${menu.path}${menu.sub[0].path}${
                menu.sub[0].sub ? menu.sub[0].sub[0].path : ''
              }`}
              className='parent'
              onClick={() => onShowSubMenu(!showSubMenu)}
            >
              {multilanguage[menu.name][currentLanguage]}
            </Link>

            {showSubMenu && (
              <SubMenu>
                {menu.sub.map((item) => (
                  <li key={item.path}>
                    <Link
                      to={
                        menu.path +
                        item.path +
                        (item.sub ? item.sub[0].path : '')
                      }
                    >
                      {multilanguage[item.name][currentLanguage]}
                    </Link>
                  </li>
                ))}
              </SubMenu>
            )}
          </li>
        </MenuWrapper>
      ))}
    </HeaderMenu>
  );
};

export default Navigation;
