import React from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';
import { device } from '../../styles/mediaQueries';
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '50px'};
  @media ${device.desktop} {
    margin-bottom: ${({ marginBottom }) =>
      marginBottom ? marginBottom : '78.4px'};
  }
`;
const StyledDivider = styled(Divider)`
  width: 23px;
  min-width: 23px;
  height: 1.5px;
  margin-top: 25px;
  background-color: ${({ theme, color }) => (color ? theme[color] : theme.red)};
  @media ${device.toTablet} {
    margin-bottom: 25px;
  }
  @media ${device.desktop} {
    margin-top: 0;
    width: 2.875rem;
    min-width: 2.875rem;
    height: 3px;
  }
`;
const WithTopDivider = ({ children, marginBottom, color }) => {
  return (
    <Wrapper marginBottom={marginBottom}>
      <StyledDivider color={color} />
      {children}
    </Wrapper>
  );
};

export default WithTopDivider;
