/*
- main page header color 변경을 위한 hook
- 역할: 해당 엘리먼트가 root엘리먼트와 겹치는지 확인하는 hook
- https://developer.mozilla.org/ko/docs/Web/API/Intersection_Observer_API 참고
- 모바일 메인 페이지 상단이 top일땐 헤더의 배경색이 투명 / 아닐시 회색
*/
import { useEffect, useRef, useState } from 'react';
const useElementObserver = (options, isActive = true) => {
  const containerRef = useRef(null);
  const [isScrollTop, setIsScrollTop] = useState(false);

  const callbackFunction = (entries) => {
    if (!isActive) return;
    const [entry] = entries;
    setIsScrollTop(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);

  return [containerRef, isScrollTop];
};

export default useElementObserver;
