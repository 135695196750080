import React, { Fragment } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { HambugerWrapper } from '../../styles/commonStyle';
import multiLanguage from '../../assets/multiLanguage.json';
import { menus } from '../../data/menus';
import logoWhite from '../../assets/main/logo-white.png';

const HeaderMenu = styled.ul`
  line-height: 5.5rem;
  display: flex;
  justify-content: center;
  text-align: center;

  .left {
    text-align: left;
  }

  li {
    width: 11vw;
    text-align: center;
    a,
    button {
      ${({ $isMain, $showSubMenu }) =>
        !$isMain && !$showSubMenu && { color: '#ffffff' }}
      font-weight: ${({ theme }) => theme.medium};
      font-size: 1.1875rem;
    }
    ul li a {
      font-weight: ${({ theme }) => theme.normal};
      font-size: 1.125rem;
    }

    :hover {
      .parent,
      & > a:hover {
        color: ${({ theme }) => theme.red};
      }
      ul {
        border-top: solid 2px ${({ theme }) => theme.red};
      }
    }
  }
`;

const BasicImage = styled.img`
  object-fit: contain;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  li {
    display: flex;
    flex-direction: column;
    a:hover,
    li:hover {
      color: ${({ theme }) => theme.red};
    }
  }
`;

export const SubMenu = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  font-size: 1.25rem;
  line-height: 2.5;
  li {
    width: 100%;
  }
`;

const HamburgerMenusWrapper = styled(MenuWrapper)`
  margin-top: 164px;
  margin-right: 20px;
  a {
    display: block;
    color: #ffffff;
    font-size: 20px;
    font-weight: ${({ theme }) => theme.normal};
  }
  ul {
    width: 100%;
  }
  & > li {
    text-align: left;
    width: 200px;
  }
  .depth {
    padding-left: 20px;
  }
  li:hover {
    .parent,
    & > a:hover {
      color: ${({ theme }) => theme.black};
    }
    & > ul {
      border-top: solid 2px ${({ theme }) => theme.black};
    }
  }

  ${SubMenu} {
    border-top: solid 2px ${({ theme }) => theme.white};
  }
`;

const HamburgerMenus = ({
  onShowSubMenu,
  showHamburgerMenu,
  onShowHamburgerMenu,
  currentLanguage,
  isMain,
}) => {
  return (
    <AnimatePresence>
      {showHamburgerMenu && (
        <HambugerWrapper
          key='modal'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Row justify='center' align='center'>
            <Col offset={1} span={2}>
              <Link to='/'>
                <BasicImage src={logoWhite} />
              </Link>
            </Col>
            <Col span={17}>
              <HeaderMenu className='hamburger'>
                {menus.map((menu) => (
                  <HamburgerMenusWrapper $isMain={isMain} key={menu.path}>
                    <li
                      onMouseEnter={() => onShowSubMenu(true)}
                      onMouseLeave={() => onShowSubMenu(false)}
                    >
                      <button className='parent left'>{menu.name}</button>
                      {
                        <SubMenu>
                          {menu.sub.map((item) => {
                            if (item.sub) {
                              return (
                                <Fragment key={item.path}>
                                  <li>
                                    <Link
                                      className='left'
                                      to={menu.path + item.path}
                                    >
                                      {
                                        multiLanguage[item.name][
                                          currentLanguage
                                        ]
                                      }
                                    </Link>
                                  </li>
                                  <ul className='depth'>
                                    {item.sub.map((sub) => (
                                      <li key={sub.path}>
                                        <Link
                                          className='left'
                                          to={menu.path + item.path + sub.path}
                                        >
                                          {
                                            multiLanguage[sub.name][
                                              currentLanguage
                                            ]
                                          }
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </Fragment>
                              );
                            } else {
                              return (
                                <li key={item.path}>
                                  <Link
                                    className='left'
                                    to={menu.path + item.path}
                                  >
                                    {multiLanguage[item.name][currentLanguage]}
                                  </Link>
                                </li>
                              );
                            }
                          })}
                        </SubMenu>
                      }
                    </li>
                  </HamburgerMenusWrapper>
                ))}
              </HeaderMenu>
            </Col>
            <Col span={4} style={{ textAlign: 'left', paddingTop: 10 }}>
              <CloseOutlined
                style={{ fontSize: 25, color: '#ffffff' }}
                onClick={() => onShowHamburgerMenu(false)}
              />
            </Col>
          </Row>
        </HambugerWrapper>
      )}
    </AnimatePresence>
  );
};

export default HamburgerMenus;
