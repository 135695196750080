import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import confirm from '../../assets/confirm-icon.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight: ${({ theme }) => theme.bold};
    font-size: 1.5625rem;
    padding: 26px 0 19px 0;
  }
  p {
    padding-bottom: 24.4px;
  }
`;

const Button = styled.button`
  cursor: pointer;
  width: 206px;
  height: 49px;
  border-radius: 24.5px;
  color: #ffffff;
  background-color: #ee3334;
`;

const ConfirmModal = ({ isDesktop, visible, onSetModalVisible }) => {
  return (
    <Modal
      centered
      width={isDesktop ? '356px' : '280px'}
      visible={visible}
      footer={[
        <Button onClick={() => onSetModalVisible(false)} key='back'>
          닫기
        </Button>,
      ]}
    >
      <Wrapper>
        <img src={confirm} alt='confirm' />
        <h1>Thank you</h1>
        <p>
          고객님의 문의글이 등록되었습니다.
          <br />
          빠른 시간내에 답변 드리겠습니다.
        </p>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmModal;
