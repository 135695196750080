import React from 'react';
import styled from 'styled-components';
import ChannelContent from './components/ChannelContent';
import tmall from '../../assets/business/b2c/b2c-image-tmall.png';
import taobao from '../../assets/business/b2c/b2c-image-taobao.png';
import pandahao from '../../assets/business/b2c/b2c-image-pandahao.png';
import lazada from '../../assets/business/b2c/b2c-image-lazada.png';
import multiLanguage from '../../assets/multiLanguage.json';

const B2cContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const content = [
  {
    mainTitle: '티몰글로벌 Tmall Global',
    text: '중국 온라인 직구 시장 점유율 1위인 티몰 글로벌은 DESC',
    image: tmall,
    reverse: false,
    subContent: [
      {
        title: '판다전영점 뷰티편집숍',
        content: '중국 최대 B2C 직구 쇼핑 플랫폼에 DESC',
        buttonText: '뷰티관 바로가기',
        path: 'https://pandahzp.tmall.hk/shop/view_shop.htm',
        redButton: true,
      },
      {
        title: '판다코리아 티몰글로벌 뷰티관 제휴 문의',
        content:
          '중국 내 해외 상품 구매 선호도가 높아짐에 따라 한국의 정품을 DESC',
        buttonText: '제휴 문의하러 가기',
        path: 'contact/inquiry',
        redButton: false,
      },
    ],
  },
  {
    mainTitle: '타오바오 Taobao',
    text: '중국 최대 오픈 마켓 타오바오는 중국 내 DESC',
    image: taobao,
    reverse: true,
    subContent: [
      {
        title: '타오바오 한국관 DESC',
        content: '국내 대표적인 음식 라면, 영유아 제품, 분유, 간식 등 DESC',
        buttonText: '한국 식품관 바로가기',
        path: 'https://login.taobao.com/member/login.jhtml?redirectURL=http%3A%2F%2Fshop362812590.world.taobao.com%2Fshop%2Fview_shop.htm%3Fspm%3Da1z10.3-c-s.w5001-18952719859.2.42d759b6PzhCWG%26mytmenu%3Dmdianpu%26user_number_id%3D3684962563%26scene%3Dtaobao_shop&uuid=dad6b3119393ee8e39a61d0584e16660',
        redButton: true,
      },
      {
        title: '판다코리아닷컴 타오바오 한국관 제휴 문의',
        content:
          '중국 전자상거래법 물류 통관 등 관련 법규가 다양하게 변화를 보이고 있습니다 DESC',
        buttonText: '제휴 문의하러 가기',
        path: '/contact/inquiry',
        redButton: false,
      },
    ],
  },
  {
    mainTitle: '국내 온라인 B2C 중국 식품관 판다하오',
    text: '판다하오는 판다 코리아의 역직구 사업을 DESC',
    image: pandahao,
    reverse: false,
    subContent: [
      {
        title: '다양한 중국 식문화',
        content: '대륙의 모든 맛을 소개하겠다 DESC',
        buttonText: '판다하오 바로가기',
        path: 'ttps://smartstore.naver.com/panda_korea',
        redButton: true,
      },
      {
        title: '판다하오 물류 네트워크',
        content: '판다하오는 다년간 형성된 탄탄한 중국 물류 네트워크를 DESC',
        buttonText: '제휴 문의하러 가기',
        path: '/contact/inquiry',
        redButton: false,
      },
    ],
  },
  {
    mainTitle: '라자다 Lazada',
    text: '2012년에 설립된 라자다는 동남아시아 DESC',
    image: lazada,
    reverse: true,
    subContent: [
      {
        title: '라자다 한국관 DESC',
        content: 'K-pop, K-beauty로 대표되는 한류가 DESC',
        buttonText: '라자다 한국관 바로가기',
        path: 'https://www.lazada.sg/shop/pandapick/',
        redButton: true,
      },
      {
        title: '판다코리아닷컴 라자다 한국관 제휴 문의',
        content: '동남아 글로벌 이커머스 업계에서 DESC',
        buttonText: '제휴 문의하러 가기',
        path: '/contact/inquiry',
        redButton: false,
      },
    ],
  },
];

const B2c = ({ pathname, currentLanguage, isDesktop, isMobile }) => {
  return (
    <B2cContainer>
      {content.map(({ mainTitle, text, image, reverse, subContent }) => (
        <ChannelContent
          key={mainTitle}
          multiLanguage={multiLanguage}
          currentLanguage={currentLanguage}
          reverse
          mainTitle={multiLanguage[mainTitle][currentLanguage]}
          text={multiLanguage[text][currentLanguage]}
          image={image}
          reverse={reverse}
          subContent={subContent}
          isDesktop={isDesktop}
        />
      ))}
    </B2cContainer>
  );
};

export default B2c;
