import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Collapse } from 'antd';
import { AnimatePresence } from 'framer-motion';
import { MinusOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { HambugerWrapper } from '../../styles/commonStyle';
import { menus } from '../../data/menus';
import multilanguage from '../../assets/multiLanguage.json';
import { device } from '../../styles/mediaQueries';
const { Panel } = Collapse;

const Wrapper = styled(HambugerWrapper)`
	background-color: #ffffff;
	[data-theme='compact']
		.site-collapse-custom-collapse
		.site-collapse-custom-panel,
	.site-collapse-custom-collapse .site-collapse-custom-panel {
		background-color: #ffffff;
		font-size: 1.2rem;
		border-bottom: solid 1px #dbdbdb;
		overflow: hidden;
		@media ${device.tablet} {
			font-size: 1.2rem;
		}
		.ant-collapse-header {
			height: 60px;
			line-height: 60px;
			padding: 0 39px;
			span {
				right: 39px;
			}
		}
		.ant-collapse-content-box {
			background-color: #efefef;
			font-size: 1rem;
			@media ${device.tablet} {
				font-size: 1rem;
			}
		}
		li {
			padding: 0 23px;
			height: 60px;
			line-height: 60px;
			a {
				display: block;
			}
		}
	}
`;

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px 3.7vw;
	div,
	li {
		cursor: pointer;
	}

	li {
		font-size: 0.9rem;
		float: left;
		padding: 0 4.2vw;
		@media ${device.tablet} {
			font-size: 1.5rem;
		}
		&:not(:first-child) {
			border-left: solid 1px #d3d3d3;
		}
		&.is-active {
			font-weight: bold;
		}
	}
`;

const MobileHamburgerMenus = ({
	showHamburgerMenu,
	onShowHamburgerMenu,
	currentLanguage,
	onChangeLang,
}) => {
	const handleChangeLanguage = (value) => {
		onChangeLang(value);
		onShowHamburgerMenu(false);
	};
	return (
		<AnimatePresence>
			{showHamburgerMenu && (
				<Wrapper
					key='modal'
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
				>
					<ButtonsContainer>
						<div onClick={() => onShowHamburgerMenu(false)}>
							<CloseOutlined style={{ fontSize: '1.5rem' }} />
						</div>
						<ul>
							<li
								className={currentLanguage === 'KR' ? 'is-active' : ''}
								onClick={() => handleChangeLanguage('KR')}
							>
								KOR
							</li>
							<li
								className={currentLanguage === 'EN' ? 'is-active' : ''}
								onClick={() => handleChangeLanguage('EN')}
							>
								ENG
							</li>
							<li
								className={currentLanguage === 'CN' ? 'is-active' : ''}
								onClick={() => handleChangeLanguage('CN')}
							>
								CHN
							</li>
						</ul>
					</ButtonsContainer>
					<Collapse
						bordered={false}
						defaultActiveKey={['1']}
						expandIconPosition='right'
						expandIcon={({ isActive }) =>
							isActive ? (
								<MinusOutlined style={{ fontSize: '1rem' }} />
							) : (
								<PlusOutlined style={{ fontSize: '1rem' }} />
							)
						}
						className='site-collapse-custom-collapse'
					>
						{menus.map((menu) => (
							<Panel
								className='site-collapse-custom-panel'
								key={menu.label}
								header={multilanguage[menu.name][currentLanguage]}
							>
								<ul>
									{menu.sub.map((sub) => (
										<li
											key={sub.name}
											onClick={() => onShowHamburgerMenu(false)}
										>
											<Link
												to={
													menu.path +
													sub.path +
													(sub.sub ? sub.sub[0].path : '')
												}
											>
												{multilanguage[sub.name][currentLanguage]}
											</Link>
										</li>
									))}
								</ul>
							</Panel>
						))}
					</Collapse>
				</Wrapper>
			)}
		</AnimatePresence>
	);
};

export default MobileHamburgerMenus;
