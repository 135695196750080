import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { device } from '../../styles/mediaQueries';
import HeadingText from '../texts/HeadingText';
import Text from '../texts/Text';
import Pin from '../main/Pin';
import worldMap from '../../assets/main/global-map-m.png';

const CountryListContainer = styled.ul`
  list-style: none;
  padding: 0 4.2vw 15px;
  color: #ffffff;
  align-self: flex-start;
  li {
    padding: 15px 25px 15px 0;
    line-height: 1.5;
    font-size: 13px;
    font-weight: 300;
    border-bottom: 1px dotted #ffffff;
    h4 {
      color: #ffffff;
      font-weight: 900;
      margin-bottom: 10px;
    }
  }
  @media ${device.desktop} {
    display: none;
  }
`;

const PinContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  & > div {
    position: absolute;
  }
  img {
    width: 100%;
  }
  @media ${device.toTablet} {
    margin-top: 55px;
  }
  @media ${device.mobile} {
    width: 345px;
    height: 189px;
    img {
      width: 345px;
      height: 189px;
      object-fit: contain;
    }
    div:first-of-type {
      top: 11%;
      left: 10%;
    }
    div:nth-of-type(2) {
      top: 3%;
      left: 32%;
    }
    div:nth-of-type(3) {
      top: 37%;
      left: 11%;
    }
    div:nth-of-type(4) {
      top: 26%;
      left: 21%;
    }
    div:nth-of-type(5) {
      top: 54%;
      left: 54%;
    }
    div:nth-of-type(6) {
      top: 21%;
      left: 44%;
    }
    div:nth-of-type(7) {
      top: 23%;
      left: 74%;
    }
  }
  @media ${device.tablet} {
    width: 690px;
    height: 289px;
    div:first-of-type {
      top: 8%;
      left: 19%;
    }
    div:nth-of-type(2) {
      top: 4%;
      left: 33%;
    }
    div:nth-of-type(3) {
      top: 46%;
      left: 15%;
    }
    div:nth-of-type(4) {
      top: 34%;
      left: 23%;
    }
    div:nth-of-type(5) {
      top: 68%;
      left: 57%;
    }
    div:nth-of-type(6) {
      top: 23%;
      left: 44%;
    }
    div:nth-of-type(7) {
      top: 21%;
      left: 80%;
    }
  }
  @media ${device.desktop} {
    width: 1180px;
    img {
      display: none;
    }

    div:first-of-type {
      top: 35%;
      left: 23%;
    }
    div:nth-of-type(2) {
      top: 20%;
      left: 45%;
    }
    div:nth-of-type(3) {
      top: 92%;
      left: 11%;
    }
    div:nth-of-type(4) {
      top: 85%;
      left: 26%;
    }
    div:nth-of-type(5) {
      top: 108%;
      left: 37%;
    }
    div:nth-of-type(6) {
      top: 65%;
      left: 50%;
    }
    div:nth-of-type(7) {
      top: 92%;
      left: 85%;
    }
  }
`;

const WorldMap = ({ isDesktop, currentLanguage, multiLanguage }) => {
  return (
    <>
      <div className='margin'>
        <ScrollAnimation animateIn='fadeIn' duration={1} animateOnce={true}>
          <HeadingText
            center={isDesktop}
            text={'GLOBAL PANDAKOREA'}
            color='white'
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
          <Text center={isDesktop} color='white'>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  multiLanguage[
                    isDesktop
                      ? '판다코리아닷컴은 B2B2C 옴니 전략을'
                      : '판다코리아닷컴은 B2B2C 옴니 전략을 Mobile'
                  ][currentLanguage],
              }}
            />
          </Text>
        </ScrollAnimation>
      </div>
      <PinContainer>
        <img src={worldMap} alt='world map' />
        <ScrollAnimation animateIn='fadeIn' duration={1.5}>
          <Pin
            left='14%'
            name='Europe'
            text={`UK, Poland, Finland\nHungary, Ukraine`}
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeIn' duration={1.8}>
          <Pin left='10%' name='CIS' text={`Russia,\nKazakhstan`} />
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeIn' duration={1.8}>
          <Pin left='12%' name='Africa' text={`Kenya,\nSouth Africa`} />
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeIn' duration={2}>
          <Pin left='18%' name='Middle East' text='UAE' />
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeIn' duration={2.5}>
          <Pin
            noIcon
            left='14%'
            name='Oceania'
            text={`Australia,\nNewzealand`}
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeIn' duration={2.5}>
          <Pin
            left='11%'
            name='Asia'
            text={`China, Malaysia, India,\nSingapore, Maldives, Philippines,\nThailand, Indonesia,\nJapan, Brunei, Cambodia,\nMyanmar, Vietnam, Bangladesh`}
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeIn' duration={2}>
          <Pin left='21%' name='North America' text='USA, Canada' />
        </ScrollAnimation>
      </PinContainer>
      <CountryListContainer>
        <li>
          <h4>Asia</h4>
          Ｃhina, Malaysia, India, Singapore, Maldives, Philippines, Thailand,
          Indonesia, Japan, Brunei, Cambodia, Myanmar, Vietnam, Bangladesh
        </li>
        <li>
          <h4>Europe</h4>
          UK, Poland, Finland, Hungary, Ukraine
        </li>
        <li>
          <h4>CIS / Oceania</h4>
          Russia, Kazakhstan, Australia, Newzealand
        </li>
        <li>
          <h4>North America / Middle East</h4>
          USA, Canada, UAE
        </li>
        <li>
          <h4>Africa</h4>
          Kenya, South Africa
        </li>
      </CountryListContainer>
    </>
  );
};

export default WorldMap;
