import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import useWindowPosition from '../hooks/useWindowPosition';
import {
  MainWrapper,
  CompanyPandaWrapper,
  CardWrapper,
  CountNumberWrapper,
  PandaChannelWrapper,
  PandaGuideWrapper,
  PandaPartenerWrapper,
  GlobalPandaWrapper,
  PandaNewsWrapper,
  ContactUsWrapper,
} from '../styles/MainContent';
import ServiceCard from '../components/main/ServiceCard';
import MainTitle from '../components/texts/MainTitle';
import PandaChannel from '../components/main/PandaChannel';
import GuideTap from '../components/main/GuideTap';
import PartnerSlide from '../components/main/PartnerSlide';
import GlobalPanda from '../components/main/GlobalPanda';
import PandaNews from '../components/main/PandaNews';
import ContactUs from '../components/main/ContactUs';

import multiLanguage from '../assets/multiLanguage.json';
import useWindowSize from '../hooks/useWindowSize';
import GuideMobile from '../components/main/GuideMobile';
import useLoadMainData from '../hooks/useLoadMainData';
import WorldMap from '../components/main/WorldMap';

import channelIcon from '../assets/main/channel-icon.png';
import globalIcon from '../assets/main/global-icon.png';
import operatingIcon from '../assets/main/operating-icon.png';
import piggycellIcon from '../assets/main/piggycell-icon.png';

const serviceItems = [
  {
    mainText: 'CHANNEL SERVICE',
    subText: 'B2C 채널 서비스',
    icon: channelIcon,
    path: '/business/e-commerce/b2c',
  },
  {
    mainText: 'GLOBAL SERVICE',
    subText: 'B2B 알리바바 서비스',
    icon: globalIcon,
    path: '/business/e-commerce/b2c',
  },
  {
    mainText: 'OPERATING AGENCY',
    subText: '중국 온라인 운영대행',
    icon: operatingIcon,
    path: '/business/e-commerce/online',
  },
  {
    mainText: 'PIGGYCELL',
    subText: '보조배터리 공유 서비스',
    icon: piggycellIcon,
    path: '/business/iot/battery',
  },
];

const Main = () => {
  const { currentLanguage, newsList } = useLoadMainData();
  const { width, isDesktop } = useWindowSize();
  const { moveYScroll } = useWindowPosition();
  React.useEffect(() => {
    moveYScroll();
  }, [moveYScroll]);
  return (
    <>
      <MainWrapper>
        <CompanyPandaWrapper>
          <MainTitle noIcon={!isDesktop} text={'company panda'} />
          <CardWrapper>
            {serviceItems.map(({ mainText, subText, icon, path }) => (
              <ServiceCard
                key={mainText}
                mainText={mainText}
                subText={multiLanguage[subText][currentLanguage].replace(
                  /(<([^>]+)>)/gi,
                  ''
                )}
                icon={icon}
                path={path}
              />
            ))}
          </CardWrapper>
        </CompanyPandaWrapper>
        <CountNumberWrapper>
          <WorldMap
            isDesktop={isDesktop}
            currentLanguage={currentLanguage}
            multiLanguage={multiLanguage}
          />
        </CountNumberWrapper>
        <PandaChannelWrapper>
          <MainTitle noIcon={!isDesktop} text={'panda channel'} />
          <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
            <PandaChannel
              isDesktop={isDesktop}
              currentLanguage={currentLanguage}
            />
          </ScrollAnimation>
        </PandaChannelWrapper>
        <PandaGuideWrapper>
          <MainTitle
            noIcon={!isDesktop}
            text={'panda Guide'}
            color={isDesktop ? 'black' : 'white'}
          />
          <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
            {isDesktop ? (
              <GuideTap
                currentLanguage={currentLanguage}
                isDesktop={isDesktop}
              />
            ) : (
              <GuideMobile currentLanguage={currentLanguage} />
            )}
          </ScrollAnimation>
        </PandaGuideWrapper>
        <PandaPartenerWrapper>
          <MainTitle noIcon={!isDesktop} text={'panda partner'} />
          <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
            <PartnerSlide width={width} isDesktop={isDesktop} />
          </ScrollAnimation>
        </PandaPartenerWrapper>
        <ScrollAnimation
          animateIn='fadeInUp'
          duration={1}
          animateOnce={true}
          style={{ width: '100%' }}
        >
          <GlobalPandaWrapper>
            <GlobalPanda
              isDesktop={isDesktop}
              currentLanguage={currentLanguage}
            />
          </GlobalPandaWrapper>
        </ScrollAnimation>
        <PandaNewsWrapper>
          <MainTitle noIcon={!isDesktop} text={'panda news'} />
          <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
            <PandaNews
              data={newsList}
              isDesktop={isDesktop}
              width={width}
              currentLanguage={currentLanguage}
            />
          </ScrollAnimation>
        </PandaNewsWrapper>
        <ContactUsWrapper>
          <ContactUs isDesktop={isDesktop} currentLanguage={currentLanguage} />
        </ContactUsWrapper>
      </MainWrapper>
    </>
  );
};

export default Main;
