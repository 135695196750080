import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Carousel } from 'antd';

import { device } from '../../../styles/mediaQueries';
import {
  BackgroundLightGray,
  FontWeightDemiLite,
} from '../../../styles/commonStyle';
import multiLanguage from '../../../assets/multiLanguage.json';
import { photos } from '../../../data/batterySliders';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 55px;
  .ant-carousel {
    max-width: 300px;
    .slick-dots-bottom {
      bottom: 0;
    }
    .slick-dots li {
      width: 15px;
      height: 2px;
      margin: 5px;
      opacity: 0.4;
      background-color: ${({ theme }) => theme.yellow};
      border-radius: 2px;
    }
    .slick-dots li button {
      width: 15px;
      height: 2px;
      background: transparent;
    }
    .slick-dots li.slick-active {
      opacity: 1;
    }
    .slick-slider {
      width: 300px;
      padding-bottom: 26px;
    }
  }
  @media ${device.desktop} {
    margin-top: 40px;
    margin-bottom: 270px;
    .ant-carousel {
      max-width: 1370px;
      .slick-dots li {
        width: 40px;
        height: 6px;
        margin: 10px;
        border-radius: 3px;
      }
      .slick-dots li button {
        width: 40px;
        height: 6px;
        background: transparent;
      }
      .slick-slider {
        width: 1371px;
        padding-bottom: 23px;
      }
    }
  }
`;
const PhotoWrapper = styled.figure``;
const Image = styled.img`
  width: 142px;
  height: auto;
  object-fit: cover;

  @media ${device.desktop} {
    width: 260px;
  }
`;
const StoreName = styled.p`
  margin: 17px 0 58px;
  width: 260px;
  height: 19px;
  text-align: center;
  font-size: 1.25rem;
  ${FontWeightDemiLite}
  @media ${device.toTablet} {
    margin: 12px 0 35px;
    width: 142px;
    height: 11px;
    font-size: 0.9rem;
  }
`;
const BlankSlideItem = styled.figure`
  width: 260px !important;
  height: 270px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  ${BackgroundLightGray}
  p {
    font-size: 1.5rem;
    ${FontWeightDemiLite}
  }
  @media ${device.toTablet} {
    margin: 0;
    width: 142px !important;
    height: 148px;
    p {
      font-size: 0.9rem;
    }
  }
`;

const PhotoSlide = ({ currentLanguage, isDesktop = false }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    draggable: false,
    rows: 2,
    responsive: [
      {
        breakpoint: 1247,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          draggable: true,
        },
      },
    ],
  };
  return (
    <Container>
      <Carousel {...settings}>
        {photos.map(({ name, image }) =>
          image ? (
            <PhotoWrapper key={name}>
              <Image src={image} alt={name} />
              <StoreName>{multiLanguage[name][currentLanguage]}</StoreName>
            </PhotoWrapper>
          ) : (
            <Fragment key={name}>
              <BlankSlideItem>
                <p>Coming soon</p>
              </BlankSlideItem>
              <StoreName />
            </Fragment>
          )
        )}
      </Carousel>
    </Container>
  );
};

export default PhotoSlide;
