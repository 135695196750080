import React from 'react';
import styled, { css } from 'styled-components';
import { P } from '../texts/Text';
import { device } from '../../styles/mediaQueries';

const SubHeader = styled(P)`
  font-size: ${({ bigger }) => (bigger ? '1.2rem' : '1rem')};
  font-weight: ${({ theme }) => theme.bold};
  line-height: 1.5;
  letter-spacing: -0.2px;
  ${({ last, noDivider }) =>
    last &&
    css`
      margin-bottom: ${noDivider ? '32px' : '20px'};
    `}
  @media ${device.tablet} {
    font-size: ${({ bigger }) => (bigger ? '2.125rem' : '1.8rem')};
  }
  @media ${device.desktop} {
    font-size: ${({ bigger }) => (bigger ? '1.6875rem' : '1.5rem')};
  }
`;

const NumberText = styled(SubHeader)`
  ${({ number }) =>
    number &&
    css`
      display: block;
      color: ${({ theme, numberColor }) => theme[numberColor]};
      font-weight: ${({ theme }) => theme.normal};
    `}
`;

const ChannelDivider = styled.div`
  display: block;
  height: 3px;
  width: ${({ long }) => (long ? '140px' : '22px')};
  background-color: ${({ theme, dividerColor }) => theme[dividerColor]};
  margin-bottom: 10px;
  margin-bottom: 18px;
`;
const TextWithDivider = ({
  number,
  text,
  subText,
  long,
  color,
  dividerColor,
  numberColor,
  noDivider,
}) => {
  return (
    <>
      <NumberText bigger numberColor={numberColor} number={number}>
        {number}
      </NumberText>
      <SubHeader color={color}>{text}</SubHeader>
      <SubHeader color={color} last noDivider={noDivider}>
        {subText}
      </SubHeader>
      {!noDivider && <ChannelDivider long={long} dividerColor={dividerColor} />}
    </>
  );
};

export default TextWithDivider;
