import React from 'react';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';
import SubIconWithText from '../../texts/SubIconWithText';
import VerticalDivider from '../../common/VerticalDivider';

import cheap from '../../../assets/company/about/cheap.png';
import graph from '../../../assets/company/about/graph.png';
import globe from '../../../assets/company/about/globe.png';
import shakeHand from '../../../assets/company/about/shake-hand.png';

import multiLanguage from '../../../assets/multiLanguage.json';
import styled from 'styled-components';
import { device } from '../../../styles/mediaQueries';
const Wrapper = styled.div`
  @media ${device.toTablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20vw;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .text {
        width: 35vw;
      }
      padding: 10px 0;
    }
    div:nth-child(1) {
      .text {
        width: 27vw;
      }
    }
    div:nth-child(3) {
      .text {
        width: 29vw;
      }
    }
    div:nth-child(1),
    div:nth-child(2) {
      border-bottom: 1px solid #eeeeee;
    }
    div:nth-child(1),
    div:nth-child(3) {
      border-right: 1px solid #eeeeee;
    }
  }
`;

const VisionSummery = ({ isDesktop }) => {
  const { currentLanguage } = useSelector((state) => state.global);

  return (
    <>
      <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
        <Wrapper>
          <SubIconWithText
            title={multiLanguage['쉽고 싸게 직접'][currentLanguage]}
            text={
              multiLanguage[
                '중소기업들의 중국 진출에 대한<br>높은 진입 장벽을 허물다.'
              ][currentLanguage]
            }
            icon={cheap}
          />
          {isDesktop && <VerticalDivider />}
          <SubIconWithText
            title={multiLanguage['한류'][currentLanguage]}
            text={
              multiLanguage['최초의 엔터커머스<br>사업모델을 선보이다.'][
                currentLanguage
              ]
            }
            icon={graph}
          />
          {isDesktop && <VerticalDivider />}
          <SubIconWithText
            title={multiLanguage['현지화'][currentLanguage]}
            text={
              multiLanguage['판다코리아닷컴의 경쟁력은<br>철저한 현지화'][
                currentLanguage
              ]
            }
            icon={globe}
          />
          {isDesktop && <VerticalDivider />}
          <SubIconWithText
            title={multiLanguage['지속적인 성장'][currentLanguage]}
            text={
              multiLanguage['B2B2C의<br>옴니채널로 거듭나다'][currentLanguage]
            }
            icon={shakeHand}
          />
        </Wrapper>
      </ScrollAnimation>
      {isDesktop && (
        <Divider
          style={{
            width: '62.5vw',
            minWidth: '62.5vw',
            marginTop: 76,
            marginBottom: 129,
            borderColor: '#eeeeee',
          }}
        />
      )}
    </>
  );
};

export default VisionSummery;
