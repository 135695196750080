import styled from 'styled-components';
import { device } from './mediaQueries';
import { MarginTop, WrapperWidth1240 } from './commonStyle';

import mapBg from '../assets/main/global-background-m.png';
import mapBgM from '../assets/main/global-background.png';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const CompanyPandaWrapper = styled.div`
  ${WrapperWidth1240}
  display: flex;
  flex-direction: column;
  @media ${device.toTablet} {
    margin-top: 3rem;
  }
`;

export const CardWrapper = styled.div`
  display: grid;
  width: 100%;
  padding: 0 4.2vw;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  @media ${device.tablet} {
    justify-content: center;
    grid-template-columns: 335px 335px;
    grid-template-rows: 350px 350px;
  }
  @media ${device.desktop} {
    padding: 0 32px;
    grid-gap: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: unset;
  }
`;

export const CountNumberWrapper = styled.div`
  width: 100%;
  padding-bottom: 30px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${MarginTop}
  background:${({ theme }) => theme.red} url(${mapBg}) no-repeat;
  @media ${device.toTablet} {
    margin-left: 4.2vw;
    margin-right: 4.2vw;
    padding-top: 20px;
    background-size: 100% 100%;
    h1 {
      font-size: 1.8rem;
      line-height: 1.2;
      margin-top: 32px;
    }
    .margin {
      margin: 0 6vw 0 4.2vw;
      p:first-of-type {
        margin-top: 10px;
      }
    }
  }
  @media ${device.mobile} {
    h1 {
      font-size: 1.25rem;
      line-height: 1.2;
      margin-top: 32px;
    }
  }
  @media ${device.desktop} {
    align-self: flex-start;
    height: 554px;
    padding-bottom: 100px;
    background: url(${mapBgM}) center;
    h1 {
      margin-top: 5.5rem;
    }
    .margin p {
      margin-top: 1.16875rem;
    }
  }
`;

export const PandaChannelWrapper = styled.div`
  ${MarginTop}
  ${WrapperWidth1240}
`;

export const PandaGuideWrapper = styled.div`
  ${MarginTop}
  ${WrapperWidth1240}
  @media ${device.toTablet} {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    background: linear-gradient(
        ${({ theme }) => theme.red} 0%,
        ${({ theme }) => theme.red} 100%
      )
      no-repeat;
    background-size: calc(100%) calc(50%);
    background-position: top;
  }
`;

export const PandaPartenerWrapper = styled.div`
  ${MarginTop}
  ${WrapperWidth1240}
`;

export const GlobalPandaWrapper = styled.div`
  ${MarginTop}
  width: 100%;
`;

export const PandaNewsWrapper = styled.div`
  ${WrapperWidth1240}
  background-color: #F8F8F8;
  @media ${device.desktop} {
    margin-top: 7.36875rem;
    background-color: #ffffff;
  }
`;

export const ContactUsWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.red};
  @media ${device.desktop} {
    padding-top: 4.74375rem;
    height: 792px;
  }
`;
