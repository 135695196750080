import React from 'react';
import styled from 'styled-components';
import ViewMoreButton from '../../components/buttons/ViewMoreButton';
import TextWithDivider from '../texts/TextWithDivider';

import { device } from '../../styles/mediaQueries';
import { URL } from '../../data/constants';

import weibo from '../../assets/main/weibo-background.png';
import taobao from '../../assets/main/taobao-background.png';
import alibaba from '../../assets/main/alibaba-background.png';
import channelBackground from '../../assets/main/channel-background.png';
import channelMobile from '../../assets/main/channel-background-mobile.png';

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  section:not(:first-child) {
    border: solid 1px #eaeaea;
  }
  section:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: ${({ theme }) => theme.red};
  }
  section:nth-child(2) {
    border-top: solid 1px #eaeaea;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  @media ${device.toTablet} {
    grid-auto-rows: 40vw;
    grid-gap: 1em;
    padding: 0 4.2vw;
    section {
      display: flex;
      align-items: center;
      padding: 0 20px;
      img {
        max-height: 26vw;
      }
    }
    section:nth-child(3) {
      background-size: 16vw;
      background-image: url(${weibo});
    }
    section:nth-child(4) {
      background-size: 23vw;
      background-image: url(${taobao});
    }
    section:nth-child(3),
    section:nth-child(4) {
      background-repeat: no-repeat;
      background-position: 96% 87%;
    }
  }

  @media ${device.desktop} {
    width: 1178px;
    height: 480px;
    grid-template-columns: 458px 1fr 1fr;
    grid-auto-rows: 240px;
    grid-column-gap: 0;
    section:not(:first-child) {
      border: unset;
      border-bottom: solid 1px #eaeaea;
      border-right: solid 1px #eaeaea;
    }
    section:first-child {
      padding-left: 40px;
      padding-top: 50px;
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
      background: url(${channelBackground});
      background-color: ${({ theme }) => theme.red};
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: contain;
    }
    section:nth-child(2) {
      border-top: solid 1px #eaeaea;
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  @media ${device.toTablet} {
    width: 100%;
    align-items: center;
    & > div {
      min-width: 189px;
    }
  }
  @media ${device.mobile} {
    & > div {
      min-width: 120px;
    }
  }
  @media ${device.desktop} {
    margin-left: 40px;
    margin-top: 20px;
    padding-right: 10px;
    div:first-child {
      align-self: flex-start;
      min-width: 140px;
    }
    img {
      max-height: 180px;
      object-fit: scale-down;
    }
    .weibo {
      padding-top: 50px;
    }
    .taobao {
      margin-top: 85px;
    }
    .alibaba {
      margin-right: 97px;
      margin-bottom: 20px;
    }
  }
`;

const PandaChannel = ({ isDesktop, currentLanguage }) => {
  return (
    <Wrapper>
      <section>
        {isDesktop ? (
          <>
            <TextWithDivider
              number='01'
              text={'Tmall Global'}
              subText={'Beauty Channel'}
              color='white'
              numberColor='white'
              dividerColor='white'
              noDivider
            />
            <ViewMoreButton white path={URL.TMALL_BEAUTY} />
          </>
        ) : (
          <ContentWrapper>
            <div>
              <TextWithDivider
                text={'Tmall Global'}
                subText={
                  currentLanguage === 'KR'
                    ? '티몰 글로벌 뷰티관'
                    : 'Beauty Channel'
                }
                color='white'
                numberColor='white'
                dividerColor='white'
                noDivider
              />
              <ViewMoreButton noAnimation white path={URL.TMALL_BEAUTY} />
            </div>

            <img src={channelMobile} alt='channel' />
          </ContentWrapper>
        )}
      </section>
      <section>
        <ContentWrapper>
          <div>
            <TextWithDivider
              bigger
              numberColor='red'
              dividerColor='black'
              number={isDesktop ? '02' : null}
              text={
                isDesktop
                  ? 'Panda'
                  : currentLanguage === 'KR'
                  ? 'Alibaba'
                  : 'Panda'
              }
              subText={
                isDesktop
                  ? 'Global Alibaba'
                  : currentLanguage === 'KR'
                  ? '글로벌 알리바바'
                  : 'Global Alibaba'
              }
              noDivider
            />
            <ViewMoreButton noAnimation={!isDesktop} path={URL.ALIBABA} />
          </div>
          <img className='alibaba' src={alibaba} alt='alibaba' />
        </ContentWrapper>
      </section>
      <section>
        <ContentWrapper>
          <div>
            <TextWithDivider
              bigger
              numberColor='red'
              dividerColor='black'
              number={isDesktop ? '03' : null}
              text={
                !isDesktop && currentLanguage === 'KR'
                  ? 'Weibo'
                  : 'Panda Official'
              }
              subText={
                !isDesktop && currentLanguage === 'KR' ? '웨이보' : 'Weibo'
              }
              noDivider={!isDesktop}
            />
            <ViewMoreButton path={URL.WEIBO} />
          </div>
          {isDesktop && <img className='weibo' src={weibo} alt='weibo' />}
        </ContentWrapper>
      </section>
      <section>
        <ContentWrapper>
          <div>
            <TextWithDivider
              bigger
              numberColor='red'
              dividerColor='black'
              number={isDesktop ? '04' : null}
              text={'Taobao'}
              subText={
                !isDesktop && currentLanguage === 'KR' ? '타오바오' : 'Channel'
              }
              noDivider={!isDesktop}
            />
            <ViewMoreButton path={URL.TAOBAO_KOREA_FOOD} />
          </div>
          {isDesktop && <img className='taobao' src={taobao} alt='taobao' />}
        </ContentWrapper>
      </section>
    </Wrapper>
  );
};

export default PandaChannel;
