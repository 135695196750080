import React from 'react';
import styled from 'styled-components';
import pandaCiBw from '../../assets/sub/panda-ci-big.png';
import { device } from '../../styles/mediaQueries';
import multiLanguage from '../../assets/multiLanguage.json';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  main {
    position: relative;
    width: max-content;
    min-height: 750px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    @media ${device.toTablet} {
      min-height: 400px;
      img {
        width: 250px;
      }
    }

    p {
      margin-top: 30px;
      font-size: 2.5rem;
      line-height: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      ::after {
        content: '';
        display: block;
        position: absolute;
        top: 15%;
        left: 0;
        background-image: url(${pandaCiBw});
        background-position: center;
        background-repeat: no-repeat;
        background-blend-mode: normal;
        background-size: contain;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      span {
        margin-top: 18px;
        font-size: 2.1875rem;
        font-weight: 100;
        color: ${({ theme }) => theme.textGray};
      }
      @media ${device.toTablet} {
        font-size: 1.5rem;
        span {
          font-size: 1rem;
        }
      }
    }
  }
`;

const OverlayWIP = ({ image, currentLanguage }) => {
  return (
    <Wrapper>
      <main>
        <img src={image} alt='working on progress' />
        <p>
          {multiLanguage['현재 컨텐츠 준비중입니다'][currentLanguage]}
          <span>This page is currently preparing contents.</span>
        </p>
      </main>
    </Wrapper>
  );
};

export default OverlayWIP;
