import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Inquiry from '../components/contact/Inquiry';
import Location from '../components/contact/Location';
import useWindowSize from '../hooks/useWindowSize';
import useWindowPosition from '../hooks/useWindowPosition';
const Contact = () => {
  const { isDesktop } = useWindowSize();
  const { path } = useRouteMatch();
  const { currentLanguage } = useSelector((state) => state.global);
  const { moveYScroll } = useWindowPosition();
  React.useEffect(() => {
    moveYScroll();
  }, [moveYScroll]);
  return (
    <Switch>
      <Route path={`${path}/inquiry`}>
        <Inquiry currentLanguage={currentLanguage} isDesktop={isDesktop} />
      </Route>
      <Route exact path={`${path}/location`}>
        <Location />
      </Route>
    </Switch>
  );
};

export default Contact;
