import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import mouse from '../../assets/main/mouse-icon.png';
import { device } from '../../styles/mediaQueries';

const BasicImage = styled.img`
  object-fit: contain;
`;

const MainSlideWrapper = styled.div`
  width: 100%;
  height: auto;
  .ant-carousel .slick-dots {
    li button {
      background-color: #747474;
      height: 12px;
      width: 12px;
      border-radius: 6px;
    }
    li.slick-active {
      width: 45px;
      button {
        margin-right: 10px;
        background-color: #0f0f0f;
        width: 36px;
      }
    }
  }
  @media ${device.desktop} {
    margin-bottom: 0;
  }
`;
const mover = keyframes`
  0% { transform: translateY(0px); }
  100% { transform: translateY(5px); }
`;
const Controller = styled.div`
  position: relative;
  @media ${device.toTablet} {
    display: none;
  }
  @media ${device.desktop} {
    .scroll-icon {
      position: absolute;
      margin: auto;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      line-height: 2em;
      z-index: 5;
      p {
        animation: ${mover} 1s infinite alternate;
      }
      img {
        width: 27px;
      }
    }

    .butonAbsolute {
      display: flex;
      position: absolute;
      width: 100%;
      left: 19vw;
      bottom: 5vh;
      z-index: 6;
    }
  }
`;
export const MainSlide = styled(Carousel)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`;

export const Slide = styled.div`
  width: 100%;
  height: auto;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const SlideButton = styled.button`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 0;
  background-color: ${({ theme }) => theme.black};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.red};
  }
  :first-child {
    margin-right: 10px;
  }
`;

const MainHeaderSlide = ({ isDesktop, currentLanguage, sliderList }) => {
  const carousel = useRef(null);
  return (
    <MainSlideWrapper>
      <MainSlide autoplay autoplaySpeed={4000} ref={carousel} dots={!isDesktop}>
        {sliderList[
          isDesktop ? `desktop${currentLanguage}` : `mobile${currentLanguage}`
        ].map((image) => (
          <Slide key={image.uid}>
            {image.linkUrl ? (
              <a href={image.linkUrl} alt='slide link'>
                <img src={image.url} alt='slide' />
              </a>
            ) : (
              <img src={image.url} alt='slide' />
            )}
          </Slide>
        ))}
      </MainSlide>
      <Controller>
        <div className='butonAbsolute'>
          <SlideButton onClick={() => carousel.current.prev()}>
            <LeftOutlined style={{ color: '#ffffff', fontSize: 20 }} />
          </SlideButton>
          <SlideButton onClick={() => carousel.current.next()}>
            <RightOutlined style={{ color: '#ffffff', fontSize: 20 }} />
          </SlideButton>
        </div>
        <div className='scroll-icon'>
          <BasicImage src={mouse} />
          <p>SCROLL DOWN</p>
        </div>
      </Controller>
    </MainSlideWrapper>
  );
};

export default MainHeaderSlide;
