import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import About from '../components/company/About';
import Ceo from '../components/company/Ceo';
import History from '../components/company/History';
import Ci from '../components/company/Ci';
import News from '../components/company/News';
import NewsDetail from '../components/company/NewsDetail';
import useWindowSize from '../hooks/useWindowSize';
import useWindowPosition from '../hooks/useWindowPosition';

const Company = () => {
  let { path } = useRouteMatch();
  const { currentLanguage } = useSelector((state) => state.global);
  const { isDesktop } = useWindowSize();
  const { moveYScroll } = useWindowPosition();
  React.useEffect(() => {
    moveYScroll();
  }, [moveYScroll]);
  return (
    <Switch>
      <Route path={`${path}/about`}>
        <About isDesktop={isDesktop} />
      </Route>
      <Route path={`${path}/ceo`}>
        <Ceo isDesktop={isDesktop} />
      </Route>
      <Route path={`${path}/history`}>
        <History />
      </Route>
      <Route path={`${path}/ci`}>
        <Ci isDesktop={isDesktop} />
      </Route>
      <Route exact path={`${path}/news`}>
        <News currentLanguage={currentLanguage} />
      </Route>
      <Route path={`${path}/news/:contentId`}>
        <NewsDetail isDesktop={isDesktop} currentLanguage={currentLanguage} />
      </Route>
    </Switch>
  );
};

export default Company;
