import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import BoardDetail from '../common/BoardDetail';
import multiLanguage from '../../assets/multiLanguage.json';

const AlibabaDetail = ({ currentLanguage }) => {
  const [content, setContent] = useState({
    current: null,
    next: null,
    prev: null,
  });
  const history = useHistory();
  const { contentId } = useParams();
  const { alibabaList } = useSelector((state) => state.global);
  useEffect(() => {
    if (!alibabaList.length) return;
    const itemIndex = alibabaList.findIndex(
      (item) => item.SK === Number(contentId)
    );
    if (alibabaList[itemIndex]) {
      return setContent({
        current: alibabaList[itemIndex],
        next: alibabaList[Number(itemIndex) + 1]
          ? alibabaList[Number(itemIndex) + 1]
          : null,
        prev: alibabaList[Number(itemIndex) - 1]
          ? alibabaList[Number(itemIndex) - 1]
          : null,
      });
    } else {
      return history.push('information/alibaba');
    }
  }, [contentId, alibabaList]);
  return (
    <div className='pd-flex-center'>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: multiLanguage['알리바바 입점 가이드'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      {content.current && (
        <BoardDetail data={content} path={'/information/alibaba'} />
      )}
    </div>
  );
};

export default AlibabaDetail;
