import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row, Divider } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import ImageWIthBox from '../common/ImageWIthBox';
import SubIconWithText from '../texts/SubIconWithText';
import VerticalDivider from '../common/VerticalDivider';
import ContentBox from '../business/components/ContentBox';
import ButtonWithBackground from '../buttons/ButtonWithBackground';

import { device } from '../../styles/mediaQueries';
import { WrapperWidth1240 } from '../../styles/commonStyle';

import ceo from '../../assets/company/ceo/ceo.png';
import ceo01 from '../../assets/company/ceo/ceo-01.png';
import signature from '../../assets/company/ceo/signature.png';
import rightWay from '../../assets/company/ceo/right-way.png';
import winWin from '../../assets/company/ceo/win-win.png';
import builingTrust from '../../assets/company/ceo/building-trust.png';
import circleArrow from '../../assets/company/ceo/circle-arrow-icon.png';

import multiLanguage from '../../assets/multiLanguage.json';
import { URL } from '../../data/constants';

const ImageWrapper = styled.div`
  width: 215px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #f3f3f2;
  @media ${device.toTablet} {
    margin-bottom: 25px;
    & > div {
      margin: 0;
    }
    img {
      height: 250px;
    }
  }
  @media ${device.desktop} {
    margin-right: 1.875rem;
    width: 427px;
    height: 563px;
  }
`;

const CeoName = styled.p`
  color: #ffffff;
  line-height: 1.5;
  font-size: 0.8125rem;
  b {
    font-size: 0.9rem;
    font-weight: ${({ theme }) => theme.bold};
  }
  @media ${device.desktop} {
    font-size: 1.0625rem;
    b {
      font-size: 1.125rem;
    }
  }
`;

const CeoGreeting = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  font-size: 0.9rem;

  p {
    margin-bottom: 20px;
    word-break: break-word;
  }

  .signature {
    display: none;
  }
  @media ${device.desktop} {
    font-size: 1rem;
    p {
      margin-bottom: 43px;
    }
    .signature {
      display: flex;
      line-height: 77px;
      p {
        font-size: 1.125rem;
        font-weight: ${({ theme }) => theme.bold};
        margin-right: 1.0625rem;
        margin-bottom: 0;
      }
    }
  }
`;
const GreetingWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media ${device.toTablet} {
    flex-direction: column;
    align-items: center;
  }
`;
const GreetingTextWrapper = styled.div`
  @media ${device.toTablet} {
    padding: 0 4.2vw;
    & div > span {
      margin: 50px 0;
      display: block;
      max-width: 100%;
      width: 100%;
      font-weight: 600;
      font-size: 1.40625rem;
      text-align: center;
      span {
        display: inline-block;
        width: 65vw;
      }
    }
  }
  @media ${device.desktop} {
    width: 730px;
  }
`;

const CeoHistory = styled.div`
  padding: 50px 4.2vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #f7f7f7;
  border-top: solid 1px #343434;
  ${WrapperWidth1240}
  @media ${device.toTablet} {
    text-align: center;
    border-top: solid 3px #343434;
    .border {
      padding: 50px 0;
      margin: 50px 0;
      border-top: 3px solid #0f0f0f;
      border-bottom: 3px solid #0f0f0f;
    }
  }
  @media ${device.desktop} {
    padding: 4.79375rem 0 3.375rem;
    flex-direction: row;

    article {
      display: flex;
      flex-direction: row;
    }
    article:last-child {
      margin-top: 100px;
    }
  }

  p {
    line-height: 2;
    @media ${device.desktop} {
      line-height: 3;
      &::before {
        content: url(${circleArrow});
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  h1 {
    font-size: 1.2rem;
    font-weight: ${({ theme }) => theme.bold};
    @media ${device.toTablet} {
      margin-bottom: 25px;
    }
    @media ${device.desktop} {
      padding-right: 10px;
      margin-right: 40px;
      font-size: 1.5rem;
    }
  }
`;
const DictionaryWrapper = styled.div`
  ${WrapperWidth1240}
`;
const IconWrapper = styled(Row)`
  justify-content: center;
  align-items: center;
  @media ${device.toTablet} {
    flex-direction: column;
    margin: 25px 0 25px;
    & > div {
      margin-bottom: 50px;
    }
  }
  @media ${device.desktop} {
    margin: 68px 0 136px;
  }
`;

const PcDivider = styled(Divider)`
  display: none;
  @media ${device.desktop} {
    display: block;
    width: 41vw;
    background-color: #f06c72;
    margin-left: -70px;
  }
`;

const Container = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: max-content;
  button:first-of-type {
    margin-bottom: 10px;
  }
`;
const Ceo = ({ isDesktop }) => {
  const { currentLanguage } = useSelector((state) => state.global);

  return (
    <Container>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: multiLanguage['CEO 인사말 페이지'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <GreetingWrapper>
        <ImageWrapper>
          <ImageWIthBox
            image={ceo}
            reverse
            boxStyle={{
              width: 168,
              height: 98,
              margin: '0 0 -20px -30px',
            }}
            boxText={
              <CeoName>
                <span
                  dangerouslySetInnerHTML={{
                    __html: multiLanguage['CEO 이름'][currentLanguage],
                  }}
                />
              </CeoName>
            }
          />
        </ImageWrapper>

        <GreetingTextWrapper>
          <ScrollAnimation animateIn='fadeIn' duration={1.5} animateOnce={true}>
            <SubHeading fontSize={'1.75rem'}>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    multiLanguage[
                      '판다코리아닷컴의 성공은 대한민국 중소기업의<br />성공이자 중화권 소비자들의 기쁨입니다.'
                    ][currentLanguage],
                }}
              />
            </SubHeading>
          </ScrollAnimation>
          <PcDivider />
          <ScrollAnimation
            animateIn='fadeInRight'
            duration={1}
            animateOnce={true}
          >
            <CeoGreeting>
              {multiLanguage['인사말'].map((item, index) => (
                <p
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item[currentLanguage],
                  }}
                />
              ))}
              <div className='signature pd-flex-row pd-align-center'>
                <p>
                  {multiLanguage['판다코리아닷컴 대표이사'][currentLanguage]}
                </p>
                <img src={signature} alt='signature' />
              </div>
            </CeoGreeting>
          </ScrollAnimation>
        </GreetingTextWrapper>
      </GreetingWrapper>
      {isDesktop && (
        <Row justify='center'>
          <Divider
            style={{
              marginTop: 97.3,
              minWidth: '61.4vw',
              width: '61.4vw',
              backgroundColor: '#d8d8d8',
            }}
          />
        </Row>
      )}
      <ScrollAnimation animateIn='fadeInUp' duration={1} animateOnce={true}>
        <IconWrapper>
          <SubIconWithText
            title={'Right Way'}
            text={
              multiLanguage['원칙에서 벗어나지 않는 정도의 길을 걷겠습니다.'][
                currentLanguage
              ]
            }
            icon={rightWay}
          />
          {isDesktop && (
            <VerticalDivider style={{ marginRight: 67, marginLeft: 67 }} />
          )}
          <SubIconWithText
            title={'Win-Win'}
            text={
              multiLanguage[
                '판다코리아의 이익은 중소기업 파트너사와 함께 나눕니다.'
              ][currentLanguage]
            }
            icon={winWin}
          />
          {isDesktop && (
            <VerticalDivider style={{ marginRight: 67, marginLeft: 67 }} />
          )}
          <SubIconWithText
            title={'Building Trust'}
            text={
              multiLanguage[
                '소비자와 파트너사의 신뢰를 위해 24시간 최선을 다하겠습니다.'
              ][currentLanguage]
            }
            icon={builingTrust}
          />
        </IconWrapper>
      </ScrollAnimation>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: multiLanguage['CEO 소개'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <Row justify='center'>
        <CeoHistory>
          <h1>{multiLanguage['약력'][currentLanguage]}</h1>
          <ScrollAnimation
            animateIn='fadeInRight'
            duration={1}
            animateOnce={true}
          >
            {multiLanguage['약력 목록'].map((item, index) => (
              <p key={index}>{item[currentLanguage]}</p>
            ))}
          </ScrollAnimation>
          {isDesktop && (
            <VerticalDivider
              style={{
                height: '38.1vh',
                marginRight: 80,
                marginLeft: 80,
                backgroundColor: '#c0c0c0',
              }}
            />
          )}
          <div>
            <article className='border'>
              <h1>{multiLanguage['수상'][currentLanguage]}</h1>
              <ScrollAnimation
                animateIn='fadeInRight'
                duration={1}
                animateOnce={true}
              >
                {multiLanguage['수상 목록'].map((item, index) => (
                  <p key={index}>{item[currentLanguage]}</p>
                ))}
              </ScrollAnimation>
            </article>
            <article>
              <h1>{multiLanguage['저서'][currentLanguage]}</h1>
              <ScrollAnimation
                animateIn='fadeInRight'
                duration={1}
                animateOnce={true}
              >
                {multiLanguage['저서 목록'].map((item, index) => (
                  <p key={index}>{item[currentLanguage]}</p>
                ))}
              </ScrollAnimation>
            </article>
          </div>
        </CeoHistory>
      </Row>
      <Row
        justify='center'
        align='middle'
        style={{ marginTop: isDesktop ? 164 : 80 }}
      >
        <DictionaryWrapper>
          <ContentBox
            margin={{
              marginBottom: 234,
            }}
            reverse
            text={
              multiLanguage[
                '판다코리아닷컴은 김수현이 소속된 기업 KEYEST의 투자를 통해 DESC'
              ][currentLanguage]
            }
            bigImg={ceo01}
            mobileCenter
            mobileTextCenter
            button={
              <ButtonContainer>
                <ButtonWithBackground
                  text={multiLanguage['해당 링크 바로가기'][currentLanguage]}
                  path={URL.CEO}
                />
              </ButtonContainer>
            }
          >
            {
              multiLanguage['중국 인명사전에 등재된 이종식 대표'][
                currentLanguage
              ]
            }
          </ContentBox>
        </DictionaryWrapper>
      </Row>
    </Container>
  );
};

export default Ceo;
