import React from 'react';
import styled from 'styled-components';

import eCommerce2 from '../../../../assets/business/ecommerce/ecommerce-img-02.png';
import eCommerce2M from '../../../../assets/business/ecommerce/ecommerce-img-m-02.png';
import { device } from '../../../../styles/mediaQueries';
import multiLanguage from '../../../../assets/multiLanguage.json';
import ProductIcon from './ProductIcon';

const TotalCommerceStyle = styled.div`
  position: relative;
  background: url(${eCommerce2M}) no-repeat;
  background-size: contain;
  width: 322px;
  height: 186.5px;
  & > * {
    font-size: 1.25rem;
    letter-spacing: -0.34px;
    line-height: 1.3;
    text-align: center;
  }
  article,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span,
  aside,
  article,
  img {
    position: absolute;
    color: #ffffff;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  article {
    width: 102px;
    height: 62px;
  }
  article:first-of-type {
    top: -1%;
    left: 6.5%;
  }
  article:nth-of-type(2) {
    top: 62.5%;
    left: 0;
  }
  article:nth-of-type(3) {
    top: 6%;
    left: 68.5%;
  }
  article:nth-of-type(4) {
    top: 67%;
    left: 65.5%;
  }
  @media ${device.toTablet} {
    article {
      font-size: 0.625rem;
    }
  }

  @media ${device.desktop} {
    width: 1347px;
    height: 788px;
    background: url(${eCommerce2}) no-repeat center;

    article {
      width: 268px;
      height: 156px;
    }
    span {
      width: 130px;
      height: 35px;
      color: black;
    }
    span:first-of-type {
      top: 12.8%;
      left: 14%;
    }
    span:nth-of-type(2) {
      top: 23.5%;
      left: 9%;
    }
    span:last-of-type {
      top: 9%;
      left: 26.2%;
    }

    article:first-of-type {
      top: 18.5%;
      left: 19.6%;
    }
    article:nth-of-type(2) {
      top: 57.2%;
      left: 15.8%;
    }
    article:nth-of-type(3) {
      top: 22.4%;
      left: 59.4%;
    }
    article:nth-of-type(4) {
      top: 59.7%;
      left: 57.4%;
    }
  }
`;

const TotalSolutionProcess = ({ isDesktop, currentLanguage }) => {
  return (
    <TotalCommerceStyle>
      {isDesktop && (
        <>
          <span>
            {multiLanguage['스마트'][currentLanguage]}
            {multiLanguage['스토어'][currentLanguage]}
          </span>
          <span>{multiLanguage['오픈마켓'][currentLanguage]}</span>
          <span>{multiLanguage['복지몰'][currentLanguage]}</span>
        </>
      )}

      <ProductIcon />

      <article>{multiLanguage['국내 채널'][currentLanguage]}</article>
      <article>{multiLanguage['B2B 채널'][currentLanguage]}</article>
      <article>{multiLanguage['중화권 채널'][currentLanguage]}</article>
      <article>{multiLanguage['동남아 채널'][currentLanguage]}</article>
    </TotalCommerceStyle>
  );
};

export default TotalSolutionProcess;
