import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../styles/mediaQueries';

const Wrapper = styled.div`
  ${(props) => props.style}
  display: grid;
  @media ${device.toTablet} {
    margin-left: 4.2vw;
    ${({ right }) =>
      right &&
      css`
        margin-left: 0;
        margin-right: 4.2vw;
      `}
  }
  @media ${device.desktop} {
    margin-left: -40px;
  }
`;

const BackgroundBox = styled.div`
  grid-area: 1/1;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.toTablet} {
    width: 16.4vw;
    height: 24.6vw;
    background-color: #f7f7f7;
    margin: -20px 0 0 -20px;
    ${({ right }) =>
      right &&
      css`
        align-self: flex-start;
        justify-self: flex-end;
        margin: -20px -20px 0 0;
      `}
  }
  @media ${device.desktop} {
    ${({ boxStyle }) =>
      css`
        ${boxStyle}
        width: ${boxStyle.width}px;
        height: ${boxStyle.height}px;
      `}
  }
`;

const Image = styled.img`
  object-fit: contain;
  grid-area: 1/1;
  @media ${device.toTablet} {
    width: 64vw;
    height: 60vw;
    object-fit: cover;
  }
`;
const ReverseBackgroundBox = styled.div`
  grid-area: 1/1;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  justify-self: flex-start;
  width: 120px;
  height: 75px;
  background-color: ${({ theme }) => theme.red};
  @media ${device.toTablet} {
    margin: 0 0 -25px -55px;
  }
  @media ${device.desktop} {
    ${({ boxStyle }) =>
      css`
        ${boxStyle}
        width: ${boxStyle.width}px;
        height: ${boxStyle.height}px;
      `}
  }
`;

const ReverseImage = styled.img`
  object-fit: contain;
  grid-area: 1/1;
`;

const ImageWIthBox = ({ image, boxStyle, style, reverse, boxText, right }) => {
  return (
    <Wrapper right={right} style={style}>
      {reverse ? (
        <>
          <ReverseImage src={image} />
          <ReverseBackgroundBox boxStyle={boxStyle}>
            {boxText}
          </ReverseBackgroundBox>
        </>
      ) : (
        <>
          <BackgroundBox right={right} boxStyle={boxStyle}>
            {boxText}
          </BackgroundBox>
          <Image src={image} />
        </>
      )}
    </Wrapper>
  );
};

export default ImageWIthBox;
