import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import BoardDetail from '../common/BoardDetail';

import multiLanguage from '../../assets/multiLanguage.json';

const NewsDetail = ({ currentLanguage }) => {
  const [content, setContent] = useState({
    current: null,
    next: null,
    prev: null,
  });
  const history = useHistory();
  const { contentId } = useParams();
  const { newsList } = useSelector((state) => state.global);

  useEffect(() => {
    if (!newsList.length) return history.push('/company/news');

    const itemIndex = newsList.findIndex(
      (item) => item.SK === Number(contentId)
    );
    if (newsList[itemIndex]) {
      return setContent({
        current: newsList[itemIndex],
        next: newsList[Number(itemIndex) + 1]
          ? newsList[Number(itemIndex) + 1]
          : null,
        prev: newsList[Number(itemIndex) - 1]
          ? newsList[Number(itemIndex) - 1]
          : null,
      });
    } else return history.push('/company/news');
  }, [contentId, currentLanguage, newsList]);

  return (
    <div className='pd-flex-center'>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <SubHeading span strong>
              {multiLanguage['판다'][currentLanguage] + ' '}
            </SubHeading>
            NEWS
          </SubHeading>
        </WithTopDivider>
      </Row>
      {content.current && (
        <BoardDetail
          currentLanguage={currentLanguage}
          data={content}
          path={'/company/news'}
        />
      )}
    </div>
  );
};

export default NewsDetail;
