import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import BoardDetail from '../common/BoardDetail';
import multiLanguage from '../../assets/multiLanguage.json';

const TmallDetail = ({ currentLanguage }) => {
  const [content, setContent] = useState({
    current: null,
    next: null,
    prev: null,
  });
  const history = useHistory();
  const { contentId } = useParams();
  const { tmallList } = useSelector((state) => state.global);
  useEffect(() => {
    if (!tmallList.length) return;
    const itemIndex = tmallList.findIndex(
      (item) => item.SK === Number(contentId)
    );
    if (tmallList[itemIndex]) {
      return setContent({
        current: tmallList[itemIndex],
        next: tmallList[Number(itemIndex) + 1]
          ? tmallList[Number(itemIndex) + 1]
          : null,
        prev: tmallList[Number(itemIndex) - 1]
          ? tmallList[Number(itemIndex) - 1]
          : null,
      });
    } else history.push('information/tmall');
  }, [contentId, tmallList]);
  return (
    <div className='pd-flex-center'>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: multiLanguage['티몰 입점 가이드'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      {content.current && (
        <BoardDetail data={content} path={'/information/tmall'} />
      )}
    </div>
  );
};

export default TmallDetail;
