import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { device } from '../../styles/mediaQueries';

import { BackgroundRed } from '../../styles/commonStyle';

const StyledButton = styled.button`
  width: ${({ small }) => (small ? 166 : 200)}px;
  height: 43px;
  border-radius: 21.5px;
  background-color: transparent;
  border: 1px solid #555555;
  z-index: 100;
  :hover {
    opacity: 0.9;
  }
  ${({ redButton }) =>
    redButton &&
    css`
      ${BackgroundRed}
      border:0;
    `};
  ${({ redBorder, theme }) =>
    redBorder &&
    css`
      border: 1px solid ${theme.red};
    `};

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 0.9375rem;
    background-color: transparent;
    color: ${({ theme }) => theme.black};
    :hover {
      opacity: 0.8;
    }
    ${({ redButton }) =>
      redButton &&
      css`
        color: #ffffff;
        :hover {
          color: #ffffff;
        }
      `};
    ${({ redBorder, theme }) =>
      redBorder &&
      css`
        color: ${theme.red};
      `};
  }
  @media ${device.toTablet} {
    width: 112.5px;
    height: 28px;
    a {
      font-size: 0.5625rem;
    }
  }
`;

const Button = ({ redButton = false, path, buttonText, small, redBorder }) => (
  <StyledButton redButton={redButton} small={small} redBorder={redBorder}>
    {path.includes('http') ? (
      <a href={path} target='_blank' rel='noopener noreferrer'>
        {buttonText}
      </a>
    ) : (
      <Link to={path}>{buttonText}</Link>
    )}
  </StyledButton>
);

export default Button;
