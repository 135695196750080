import React from 'react';
import styled, { css } from 'styled-components';
import circle from '../../assets/main/circle-icon.png';
import { device } from '../../styles/mediaQueries';

const PinWrapper = styled.section`
  position: relative;
  display: inline-block;
  z-index: 1;
  main {
    display: block;
    width: 100%;
    span {
      background: #ffffff;
      padding: 0 20px;
      line-height: 1.5;
      letter-spacing: -0.26px;
      color: #f73130;
      font-size: 13px;
      font-weight: 900;
      border-radius: 10px;
    }
    @media ${device.toTablet} {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        font-size: 10px;
        padding: 0 10px;
      }
    }
    div {
      @media ${device.toTablet} {
        section {
          display: none;
        }
        height: 20px;
        width: 1px;
        background-color: #ffffff;
        ::after {
          content: '';
          position: absolute;
          width: 3px;
          height: 3px;
          bottom: -2px;
          transform: translateX(-50%);
          background: #ffffff;
          border-radius: 1.5px;
        }
        ::before {
          content: '';
          background: url(${circle});
          background-size: contain;
          position: absolute;
          width: 18px;
          height: 18px;
          bottom: -10px;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
      @media ${device.desktop} {
        width: 300px;
        margin-left: ${({ left }) => left};
        section {
          align-self: flex-end;
          position: relative;
          color: #ffffff;
          font-size: 14px;
          line-height: 1.3;
          letter-spacing: 0.56px;
          border-left: 2px solid #ffffff;
          padding: 10px 0 15px 20px;
          ${({ noIcon }) =>
            !noIcon &&
            css`
              ::after {
                content: '';
                position: absolute;
                left: -5px;
                bottom: -5px;
                width: 8px;
                height: 8px;
                background: #ffffff;
                border-radius: 4px;
              }
              ::before {
                content: url(${circle});
                position: absolute;
                bottom: -27px;
                left: -23px;
                z-index: -1;
              }
            `}
        }
      }
    }
  }
`;

const Pin = ({ name, text, left, noIcon }) => {
  return (
    <PinWrapper left={left} noIcon={noIcon}>
      <main>
        <span>{name}</span>
        <div>
          <section>{text}</section>
        </div>
      </main>
    </PinWrapper>
  );
};

export default Pin;
