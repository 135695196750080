import React from 'react';
import styled, { css } from 'styled-components';
import { Row } from 'antd';
import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';

import multiLanguage from '../../assets/multiLanguage.json';
import ContentBox from './components/ContentBox';
import BackgroundBox from './components/BackgroundBox';
import AppButtonGroup from './components/AppButtonGroup';
import { URL } from '../../data/constants';

import bike1 from '../../assets/business/bike/bike-image-01-01.png';
import bike2 from '../../assets/business/bike/bike-image-01-02.png';
import bike3 from '../../assets/business/bike/bike-image-01-03.png';
import bike21 from '../../assets/business/bike/bike-image-02-01.png';
import bike22 from '../../assets/business/bike/bike-image-02-02.png';
import bike23 from '../../assets/business/bike/bike-image-02-03.png';
import bike31 from '../../assets/business/bike/bike-image-03.png';
import BikeCycle from './components/BikeCycle';
import KaKaoPanda from './components/KaKaoPanda';
import { FontWeightNormal, FontWeightThin } from '../../styles/commonStyle';
import { device } from '../../styles/mediaQueries';

const BikeFactoryContainer = styled.section`
  margin: 139px 0 328px;
  width: 1540px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 28px;
  @media ${device.toTablet} {
    margin: 45px 0 164px;
    width: 100vw;
    column-gap: 1.5%;
    img {
      width: 100%;
    }
  }
`;

const BikeContainer = styled.section`
  width: 1540px;
  display: grid;
  grid-template-columns: 749px 1fr;
  grid-template-rows: 744px;
  column-gap: 85px;
  article {
    width: 523px;
    h1,
    p {
      ${FontWeightThin}
      letter-spacing: -0.48px;
      word-break: ${({ currentLanguage }) =>
        currentLanguage === 'CN' ? 'break-all' : 'keep-all'};
    }
    h1 {
      margin: 20px 0 100px;
      font-size: 31px;
    }
    p {
      margin-top: 60px;
      font-size: 24px;
      line-height: 2;
    }
  }
  @media ${device.toTablet} {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    img {
      width: 286px;
    }
    article {
      margin-bottom: 96px;
      width: 262px;
      text-align: center;
      h1 {
        margin: 0 0 40px;
        font-size: 15px;
        line-height: 1.6;
        ${FontWeightNormal}
      }
      p {
        margin-top: 30px;
        font-size: 12px;
      }
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 812px;
  height: 646px;
  img {
    position: absolute;
  }
  img:first-of-type {
    top: -4%;
    left: -64%;
  }
  img:nth-of-type(2) {
    top: 26%;
    left: 73.5%;
  }
  img:nth-of-type(3) {
    top: 75%;
    right: 23%;
  }
  @media ${device.toTablet} {
    width: 285px;
    height: 189.5px;
    margin-bottom: 10px;
    img:first-of-type {
      max-width: 314px;
      width: 314px;
      top: -6%;
      left: -40%;
    }
    img:nth-of-type(2) {
      width: 60px;
      top: 14%;
      left: 60%;
    }
    img:nth-of-type(3) {
      width: 35px;
      top: 59%;
      left: 52%;
    }
  }
`;

const Images = (
  <ImageContainer>
    <img src={bike1} alt='kakao bike' />
    <img src={bike2} alt='kakao bike' />
    <img src={bike3} alt='kakao bike' />
  </ImageContainer>
);

const KaKaoBike = ({ isDesktop, currentLanguage }) => {
  const isMobile = isDesktop ? '' : ' Mobile';
  return (
    <div>
      <Row style={{ position: 'relative' }} justify='center'>
        <ContentBox
          mobileCenter
          mobileTextCenter
          noMobileBottomMargin
          margin={{
            marginTop: isDesktop ? 65 : 0,
            marginBottom: isDesktop ? 388 : 187,
          }}
          text={
            multiLanguage[
              `카카오 T 바이크도 판다코리아와 함께하고 있습니다.${isMobile}`
            ][currentLanguage]
          }
          images={Images}
          button={
            <AppButtonGroup
              MobileMarginTop={0}
              isDesktop={isDesktop}
              currentLanguage={currentLanguage}
              homeRedButton={!isDesktop}
              homeText={'카카오T 홈페이지'}
              homeUrl={URL.BIKE}
              appleUrl={URL.BIKE_APPLE}
              googleUrl={URL.BIKE_GOOGLE}
            />
          }
        >
          {
            multiLanguage[`공유 자전거의 새로운 혁신${isMobile}`][
              currentLanguage
            ]
          }
        </ContentBox>
        <BackgroundBox
          bottom={isDesktop ? 15 : null}
          width={isDesktop ? null : 81}
        />
      </Row>
      <Row justify='center'>
        <WithTopDivider marginBottom={'13px'}>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: `${
                  multiLanguage[
                    `(주) 판다코리아닷컴만의 노하우로 높은 DESC${isMobile}`
                  ][currentLanguage]
                }`,
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <Row justify='center'>
        <BikeCycle isDesktop={isDesktop} currentLanguage={currentLanguage} />
      </Row>
      <Row justify='center'>
        <WithTopDivider marginBottom={'13px'}>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: `${
                  multiLanguage[
                    `중국 업체와 소통, 협력, 통관 배송에 DESC${isMobile}`
                  ][currentLanguage]
                }`,
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <Row justify='center'>
        <KaKaoPanda isDesktop={isDesktop} currentLanguage={currentLanguage} />
      </Row>
      <Row justify='center'>
        <WithTopDivider marginBottom={'13px'}>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html: `${multiLanguage['카카오 T 바이크 이미지 판다코리아 용인 물류센터'][currentLanguage]}`,
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <Row justify='center'>
        <BikeFactoryContainer>
          <img src={bike21} />
          <img src={bike22} />
          <img src={bike23} />
        </BikeFactoryContainer>
      </Row>
      <Row justify='center'>
        <BikeContainer currentLanguage={currentLanguage}>
          <img src={bike31} />
          <article>
            <h1>
              {
                multiLanguage['스마트 시티 그리고 스마트 자전거 산업'][
                  currentLanguage
                ]
              }
            </h1>
            <p>
              {
                multiLanguage[
                  '공유 IoT 서비스 충전 돼지를 개발, 운영하고 있습니다. DESC'
                ][currentLanguage]
              }
            </p>
            <p>
              {
                multiLanguage[
                  '(주) 판다코리아닷컴은 전기 자전거 사업을 토대로 DESC'
                ][currentLanguage]
              }
            </p>
          </article>
        </BikeContainer>
      </Row>
    </div>
  );
};

export default KaKaoBike;
