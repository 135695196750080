import React from 'react';
import styled, { css } from 'styled-components';
import { Row } from 'antd';

import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import BoardCard from '../common/BoardCard';
import LoadItemButton from '../buttons/LoadItemButton';
import { WrapperWidth1240 } from '../../styles/commonStyle';

import multiLanguage from '../../assets/multiLanguage.json';
import { device } from '../../styles/mediaQueries';
import useRecruitData from '../../hooks/useRecruitData';

const BoardWrapper = styled.div`
  ${WrapperWidth1240}
  ${({ showLoadButton }) =>
    !showLoadButton &&
    css`
      @media ${device.toTablet} {
        margin-bottom: 50px;
      }
      margin-bottom: 185px;
    `};
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill, 386px);
    grid-gap: 1rem;
  }
  @media ${device.toTablet} {
    padding: 0 4.2vw;
    grid-template-rows: repeat(auto-fill, 300px);
    align-items: center;
    grid-gap: 2rem;
  }
`;

const Apply = ({ currentLanguage }) => {
  const { list, showLoadMore, showButton, handleShowMore } =
    useRecruitData(currentLanguage);

  return (
    <div className='pd-flex-center'>
      <Row justify='center'>
        <WithTopDivider>
          <SubHeading>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  multiLanguage['판다코리아닷컴 입사지원'][currentLanguage],
              }}
            />
          </SubHeading>
        </WithTopDivider>
      </Row>
      <BoardWrapper showLoadButton={showButton}>
        {list.length > 0 &&
          list
            .slice(0, showLoadMore)
            .map((item) => (
              <BoardCard
                key={item.key}
                data={item}
                currentLanguage={currentLanguage}
                path='/recruit/apply'
              />
            ))}
      </BoardWrapper>
      {showButton && <LoadItemButton onClick={handleShowMore} />}
    </div>
  );
};

export default Apply;
