import styled, { css } from 'styled-components';
import { device } from './mediaQueries';

export const BasicImage = styled.img`
  object-fit: contain;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  @media ${device.toTablet} {
    height: 100%;
  }
  @media ${device.desktop} {
    height: ${({ $isMain }) => ($isMain ? '100%' : '548px')};
  }
`;
export const HeaderWrapper = styled.div`
  position: relative;
  height: 100%;
  background: transparent;
  z-index: 21;
`;

export const HeaderMenuWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  .header-top {
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  display: flex;
  justify-content: center;
  @media ${device.toTablet} {
    padding: 0 4.2vw;
    height: 60px;
    background-color: #eaeaea;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 60;
    ${({ $isMain, isScrollTop }) =>
      isScrollTop &&
      $isMain &&
      css`
        background-color: transparent;
      `}
  }
  @media ${device.desktop} {
    position: absolute;
    width: 100%;
    z-index: 10;
    cursor: pointer;
    background-color: ${({ $showSubMenu }) =>
      $showSubMenu ? '#ffffff' : 'inherit'};
    border-bottom: #fff solid 1px;
    .header-top {
      height: 88px;
      justify-content: flex-end;
    }
  }
`;

export const HeaderLogo = styled.img`
  width: 128px;
  @media ${device.mobile} {
    width: 69px;
  }
`;

export const HeaderRightIcons = styled.div`
  display: flex;
  height: 60px;
  font-size: ${(props) => props.theme.fontSmall};
  color: ${(props) => props.theme.white};
  @media ${device.toTablet} {
    align-items: center;
    justify-content: flex-end;
  }
  @media ${device.desktop} {
    height: 88px;
  }
`;

export const MenuIcon = styled(BasicImage)`
  @media ${device.desktop} {
    margin-left: 3.3125rem;
    width: 23px;
  }
`;
