import React from 'react';
import styled, { css } from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import {
  ColorTextGray,
  FontWeightDemiLite,
  FontWeightThin,
  FontWeightBold,
  FontWeightNormal,
} from '../../../styles/commonStyle';
import { device } from '../../../styles/mediaQueries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 20;
  ${(props) => props.margin}
  h1 {
    padding-top: 20px;
    margin-bottom: 19px;
    position: relative;
    font-size: 0.9375rem;
    ${({ titleBold }) => (titleBold ? FontWeightBold : FontWeightNormal)}
    line-height: 1.5;
    ::before {
      position: absolute;
      content: '';
      top: 0;
      display: block;
      width: 27px;
      margin-top: 0;
      border-top: solid 2px ${({ theme }) => theme.red};
    }
    abbr {
      display: block;
    }
    span,
    abbr,
    p {
      ${({ titleBold }) => (titleBold ? FontWeightNormal : FontWeightThin)}
    }
  }
  p {
    max-width: 100%;
    font-size: 0.75rem;
    line-height: 2;
    letter-spacing: -0.6px;
    text-align: 'left';
    color: #747474;
  }
  br {
    content: '';
    display: block;
    margin: 20px 0;
  }
  @media ${device.toTablet} {
    flex-direction: column-reverse;
    & > div {
      width: 100%;
      padding: 0 10vw;
    }
    h1 {
      ::before {
        border-width: 1px;
      }
    }

    p {
      text-align: ${({ mobileTextCenter }) =>
        mobileTextCenter ? 'center' : 'left'};
      ${({ titleBold }) => (titleBold ? FontWeightNormal : FontWeightDemiLite)}
    }
    aside {
      margin: 20px 0 0;
    }

    ${({ mobileCenter }) =>
      mobileCenter &&
      css`
        h1 {
          text-align: center;
          ::before {
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        aside {
          display: flex;
          justify-content: center;
        }
      `}
  }
  @media ${device.desktop} {
    max-width: 1450px;
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: ${({ alignItems }) => alignItems ?? 'center'};
    & > div {
      z-index: 15;
    }
    h1 {
      font-size: 1.5625rem;
      margin-bottom: 27px;
      line-height: 1.2;
    }
    h6,
    p {
      font-size: 1.125rem;
    }
    h6 {
      ${ColorTextGray}
      margin-bottom: 38px;
    }
    p {
      margin-bottom: 33.4px;
      max-width: 482px;
      word-break: keep-all;
    }
  }
`;

const Images = styled.figure`
  display: grid;
  z-index: 1;
  img {
    grid-area: 1/1;
    height: auto;
  }
  @media ${device.toTablet} {
    img {
      max-width: 240px;
    }
    margin-top: ${({ noMobileTopMargin }) => (noMobileTopMargin ? 0 : 30)}px;
    margin-bottom: ${({ noMobileBottomMargin }) =>
      noMobileBottomMargin ? 0 : 40}px;
  }
`;

const ContentBox = ({
  children,
  isDesktop,
  subtitle,
  text,
  margin,
  bigImg,
  images,
  reverse,
  button,
  noTitle,
  titleBold,
  alignItems,
  mobileCenter,
  mobileTextCenter,
  noMobileBottomMargin,
  noMobileTopMargin,
}) => {
  return (
    <Wrapper
      titleBold={titleBold}
      margin={margin}
      reverse={reverse}
      alignItems={alignItems}
      mobileTextCenter={mobileTextCenter}
      mobileCenter={mobileCenter}
    >
      <ScrollAnimation
        animateIn={reverse ? 'fadeInRight' : 'fadeInLeft'}
        duration={1}
        animateOnce
      >
        <div>
          {!noTitle && (
            <h1
              dangerouslySetInnerHTML={{
                __html: children,
              }}
            />
          )}
          {isDesktop && !!subtitle && <h6>{subtitle}</h6>}
          <p
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          {!!button && <aside>{button}</aside>}
        </div>
      </ScrollAnimation>
      <Images
        reverse={reverse}
        noMobileTopMargin={noMobileTopMargin}
        noMobileBottomMargin={noMobileBottomMargin}
      >
        {images ? images : <img src={bigImg} alt='main' />}
      </Images>
    </Wrapper>
  );
};

export default ContentBox;
