import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Row } from 'antd';

import WithTopDivider from '../common/WithTopDivider';
import SubHeading from '../texts/SubHeading';
import BatteryPiggy from './BatteryPiggy';
import KaKaoBike from './KaKaoBike';

import multiLanguage from '../../assets/multiLanguage.json';
import { device } from '../../styles/mediaQueries';
import {
  FontWeightDemiLite,
  FontWeightMedium,
  LeftDivider,
} from '../../styles/commonStyle';

const TabContainer = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 156px;
  @media ${device.toTablet} {
    margin-bottom: 40px;
    margin-top: 30px;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
  }
`;
const ListItem = styled.li`
  cursor: pointer;
  float: left;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${FontWeightDemiLite}
  span {
    position: relative;
    display: inline-block;
    padding: 0.6rem 10px;
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      ${FontWeightMedium}
      span {
        color: ${theme.black};
        ::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -5px;
          margin: auto;
          height: 1px;
          width: 140px;
          border-bottom: 1px solid ${theme.red};
          @media ${device.toTablet} {
            width: 69px;
          }
        }
      }
    `};
  @media ${device.toTablet} {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    span {
      width: 114.5px;
      text-align: center;
      line-height: 1.63;
    }
    &:nth-child(even) {
      ${LeftDivider}
    }
  }
  @media ${device.desktop} {
    font-size: 1.1875rem;
    span {
      text-align: center;
      padding: 14px;
      color: ${({ theme }) => theme.black};
      margin: 0 10px;
      line-height: 1;
    }
    ${LeftDivider}

    &:first-child {
      background: transparent;
    }
  }
`;

const tabNames = [
  {
    name: '공유 보조배터리 충전돼지',
    label: 'battery',
    path: '/business/iot/battery',
  },
  {
    name: '공유 전기 자전거 카카오 바이크',
    label: 'bike',
    path: '/business/iot/bike',
  },
];

const IoT = ({ pathname, currentLanguage, isDesktop, isMobile }) => {
  const [currentTab, setCurrentTab] = useState(tabNames[0]);
  const history = useHistory();

  const getTabItem = useCallback(
    (pathname) => {
      const item = tabNames.find((item) => item.path === pathname);
      if (item) return item;

      history.push(tabNames[0].path);
      return tabNames[0];
    },
    [pathname]
  );

  useEffect(() => {
    setCurrentTab(getTabItem(pathname));
  }, [getTabItem, pathname]);

  const renderComponent = (label) => {
    switch (label) {
      case 'battery':
        return (
          <BatteryPiggy
            multiLanguage={multiLanguage}
            currentLanguage={currentLanguage}
            isDesktop={isDesktop}
          />
        );
      case 'bike':
        return (
          <KaKaoBike
            multiLanguage={multiLanguage}
            currentLanguage={currentLanguage}
            isDesktop={isDesktop}
          />
        );
      default:
        return (
          <BatteryPiggy
            multiLanguage={multiLanguage}
            currentLanguage={currentLanguage}
            isDesktop={isDesktop}
          />
        );
    }
  };
  return (
    <div>
      {isDesktop && (
        <Row justify='center'>
          <WithTopDivider>
            <SubHeading span strong>
              {multiLanguage['공유 IOT 서비스'][currentLanguage]}
            </SubHeading>
          </WithTopDivider>
        </Row>
      )}
      <TabContainer>
        {tabNames.map((item) => (
          <ListItem key={item.name} isActive={currentTab.label === item.label}>
            <Link to={`${item.path}#tab`}>
              <span>{multiLanguage[item.name][currentLanguage]}</span>
            </Link>
          </ListItem>
        ))}
      </TabContainer>
      {renderComponent(currentTab.label)}
    </div>
  );
};

export default IoT;
