export const breakpoints = {
  mobile: 320,
  tablet: 768,
  desktop: 1250,
};

export const size = {
  mobile: `${breakpoints.mobile}px`,
  tablet: `${breakpoints.tablet}px`,
  desktop: `${breakpoints.desktop}px`,
};

export const device = {
  mobile: `(min-width: ${size.mobile}) and (max-width:${
    breakpoints.tablet - 1
  }px)`,
  tablet: `(min-width: ${size.tablet}) and (max-width:${
    breakpoints.desktop - 1
  }px)`,
  toTablet: `(max-width:${breakpoints.desktop - 1}px)`,
  desktop: `(min-width: ${size.desktop})`,
};
