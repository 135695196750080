import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../styles/mediaQueries';

const StyledButton = styled.button`
  cursor: pointer;
  font-size: 0.7rem;
  border-radius: ${({ square }) => (square ? '0' : '7px')};
  color: ${({ color, theme }) => (color ? theme[color] : theme.black)};
  background-color: transparent;
  border: solid 1px
    ${({ color, theme }) => (color ? theme[color] : theme.black)};
  :hover {
    &,
    * {
      color: ${({ theme }) => theme.white};
    }
    background-color: ${({ theme }) => theme.red};
    border-color: #ffffff;
  }
  & > a {
    display: block;
    width: 100%;
    height: 100%;
  }
  @media ${device.toTablet} {
    padding: 0 20px;
    line-height: 2.5;
    ${({ color, theme }) =>
      color &&
      css`
        background-color: ${theme[color]};
        a {
          color: ${({ theme }) => theme.white};
        }
      `};
    ${({ color }) =>
      color === 'red' &&
      css`
        border: 0;
      `};
  }

  @media ${device.desktop} {
    width: 11.0625rem;
    height: 2.64375rem;
    font-size: 13px;
    border: solid 1px
      ${({ color, theme }) => (color !== 'red' ? theme[color] : theme.black)};
    border-radius: ${({ square }) => (square ? '0' : '0.625rem')};
    & > a {
      line-height: 2.64375rem;
    }
  }
`;

const CommonButton = ({ children, color, square, onClick, htmlType }) => {
  return (
    <StyledButton
      color={color}
      square={square}
      onClick={onClick}
      type={htmlType}
    >
      {children ? children : 'View More'}
    </StyledButton>
  );
};

export default CommonButton;
